import { React, useState, useEffect, useContext } from "react";
import NavBar from "../../Common/Navbar/NavBar";
import Sidebar from "../../Common/Sidebar/SideBar";
import logoImage from "../../images/logo1.png";
import proImage from "../../images/profile.png";
import "./usersDashboard.css";
import CustomSpinner from "../../Common/Spinner/spinner";
import ModalSpinner from "../../Common/ModalSpinner/ModalSpinner";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Pagination,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { endpoints } from "../../defaults";
import useApi from "../../hooks/useApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faMagnifyingGlass,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/authContext";

const UsersDashboard = () => {
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const [loading, setLoading] = useState(false);
  const [loadingSmall, setLoadingSmall] = useState(false);
  const [userdataLoaded, setUserdataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [udata, setuData] = useState([]);
  const [cdata, setcData] = useState([]);
  const [sdata, setsData] = useState([]);
  const [pdata, setpData] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const { request: getUserTypeList } = useApi("get");
  const { request: getUserDetail } = useApi("get");
  const { request: getStudentBatches } = useApi("get");
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [page, setPage] = useState("1");
  const [pageInput, setPageInput] = useState(page);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredData =  user.user_type === "faculty" ? data.filter(user => user.name === 'Student') : data;


  const handlePageClick = (i) => {
    onUserList(selectedUser, i);
    setPageInput(i);
  };
  const handlePageInput = (i) => {
    if (i > pdata.total_pages) {
      setPageInput(pdata.total_pages);
      handlePageClick(pdata.total_pages);
    } else if (i < 1) {
      setPageInput(1);
      handlePageClick(1);
    } else {
      handlePageClick(i);
    }
  };
  const renderPagination = () => {
    let n = 1;
    if (pdata.total_pages) {
      console.log(pdata);
      const buttons = [];
      if (pdata.current_page > 1) {
        // first and prev
        buttons.push(
          <Pagination.First key={"first"} onClick={() => handlePageClick(1)} />,
          <Pagination.Prev
            key={"prev"}
            onClick={() => handlePageClick(pdata.current_page - 1)}
          />
        );
      } else {
        buttons.push(
          <Pagination.First key={"first"} disabled />,
          <Pagination.Prev key={"prev"} disabled />
        );
      }
      if (pdata.current_page > 1 + n) {
        buttons.push(<Pagination.Ellipsis />);
      }
      // numbers
      for (let i = 1; i <= pdata.total_pages; i++) {
        if (pdata.current_page - i <= n && i - pdata.current_page <= n) {
          buttons.push(
            <Pagination.Item
              key={i}
              onClick={() => handlePageClick(i)}
              disabled={i === pdata.current_page}
            >
              {i}
            </Pagination.Item>
          );
        }
      }
      if (pdata.current_page < pdata.total_pages - n) {
        buttons.push(<Pagination.Ellipsis />);
      }
      // next and last
      if (pdata.current_page < pdata.total_pages) {
        buttons.push(
          <Pagination.Next
            key={"next"}
            onClick={() => handlePageClick(pdata.current_page + 1)}
          />,
          <Pagination.Last
            key={"last"}
            onClick={() => handlePageClick(pdata.total_pages)}
          />
        );
      } else {
        buttons.push(
          <Pagination.Next key={"next"} disabled />,
          <Pagination.Last key={"last"} disabled />
        );
      }

      return (
        <>
          <div className="text-center">
            <Pagination className="mb-0">
              {/* You can add additional styling or wrapper divs if needed */}
              {buttons}
            </Pagination>
            <br />
            <label>
              Page
              <input
                type="number"
                name="page"
                id="pageNo"
                value={pageInput}
                onChange={(e) => setPageInput(e.target.value)}
                onBlur={() => handlePageInput(pageInput)}
                className="text-center form-control d-inline mx-1 p-0 mb-3 mb-lg-4"
                style={{ width: "60px" }}
              />
              of {pdata.total_pages}
            </label>
          </div>
        </>
      );
    }

    return null; // Render nothing if no pages
  };

  const heading = "Luminar Technolab";
  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "" },
      };
      return <Sidebar navObj={navObj} />;
    } else {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "", icon: "chart-line" },
        users: { title: "Users", link: "users", icon: "users" },
        addCourse: { title: "Add Course", link: "addcourse", icon: "plus" },
        addCarousel: {
          title: "Add Carousel",
          link: "addCarousel",
          icon: "image",
        },
        lead: { title: "Leads", link: "leads", icon: "user-tie" },
      };
      return <Sidebar navObj={navObj} />;
    }
  };

  useEffect(() => {
    fetchUsers();
    setSelectedUser("Student");
    fetchUsersList("student", page);
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    const apiResponse = await getUserTypeList(endpoints.FECH_USER_TYPE_LIST);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchStudentBatches = async (StudentId) => {
    const url = `${endpoints.STUDENT_BATCHES}${StudentId}/`;
    const apiResponse = await getStudentBatches(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setsData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchUserDetails = async (id) => {
    setLoadingSmall(true);
    const url = `${endpoints.FECH_USER_DETAILS}${id}/`;
    const apiResponse = await getUserDetail(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setuData(apiData);
      setDataLoaded(true);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoadingSmall(false);
  };

  const fetchUsersList = async (userType, page) => {
    const url = `${endpoints.FECH_USER_LIST}/${userType}/?page=${page}&search=${searchQuery}`;
    const apiResponse = await getUserTypeList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setcData(apiData.data);
      setpData(apiData.pagination);

      setUserdataLoaded(true);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const onUserList = (item, page) => {
    const userType = item.split(" ").join("").toLowerCase();

    fetchUsersList(userType, page);
  };

  const onSearchClick = () => {
    const userType = selectedUser.split(" ").join("").toLowerCase();

    fetchUsersList(userType, page);
  };

  const checkStatus = (item) => {
    if (item.status === "active")
      return (
        <span className="bg-success p-1 text-white rounded-2">Active</span>
      );
    else if (item.status === "inactive") {
      return (
        <span className="bg-danger p-1 text-white rounded-2">Inactive</span>
      );
    } else if (item.status === "waiting") {
      return (
        <span className="bg-warning p-1 text-white rounded-2">waiting</span>
      );
    }
  };

  const checkDeleted = (item) => {
    if (item.user.is_deleted === true) {
      return (
        <span className="bg-danger ms-2 p-1 text-white rounded-2">Deleted</span>
      );
    }
  };

  const onUserRegistration = () => {
    navigate("/users/registration/");
  };

  const renderUserCard = (user) => {
    const isSelected = selectedUser === user.name;
    const cardStyle = isSelected ? "selected-card" : "clickable-card_";
    return (
      <Col
        lg={4}
        md={4}
        sm={4}
        key={user.id}
        className={` d-flex justify-content-center ${cardStyle}`}
        onClick={() => {
          onUserList(user.name, page);
          setSelectedUser(user.name);
        }}
      >
        <div className=" ps-5 pe-5">
          <h5 className="fw-bold m-0">
            {" "}
            {user.name} ({user.count})
          </h5>
          {/* <p className="m-0 fw-bold">
            <span className="fw-normal">Total Count : </span>
            <span className="fs-5">{user.count}</span>
          </p> */}
        </div>
      </Col>
    );
  };

  const batchCard = (batch) => {
    return (
      <Col md={6}>
        <div className="border p-2 border-3">{batch.batch_name}</div>
      </Col>
    );
  };

  const renderUserList = (item, index) => {
    let userData = null;

    if (selectedUser === "Student" && item.student) {
      userData = item.student;
    } else if (selectedUser === "Faculty" && item.faculty) {
      userData = item.faculty;
    } else if (selectedUser === "Admin" && item.admin) {
      userData = item.admin;
    }

    const handleEditclick = (id) => {
      navigate(`/users/registration/?id=${id}`);
    };

    const renderEye = () => {
      if (selectedUser === "Student") {
        return (
          <td>
            <FontAwesomeIcon
              className="icons px-2"
              icon={faEye}
              onClick={() => {
                fetchUserDetails(userData.user.id);
                setModalShow(true);
                if (item.student.user.user_type === "student") {
                  fetchStudentBatches(userData.id);
                }
              }}
            />
            <FontAwesomeIcon
              className="icon px-2"
              icon={faUserPen}
              onClick={() => {
                handleEditclick(userData.user.id);
              }}
            />
          </td>
        );
      } else
        return (
          <td>
            <FontAwesomeIcon
              className="icons px-2"
              icon={faEye}
              onClick={() => {
                fetchUserDetails(userData.user.id);
                setModalShow(true);
              }}
            />
            <FontAwesomeIcon
              className="icon px-2"
              icon={faUserPen}
              onClick={() => {
                handleEditclick(userData.user.id);
              }}
            />
          </td>
        );
    };

    const renderDate = () => {
      const originalDate = new Date(userData.created_date);
      const formattedDate = originalDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      return <td>{formattedDate}</td>;
    };

    if (userData) {
      return (
        <tr className="content" key={userData.user.id}>
          <th scope="row">{index + 1}</th>
          {renderDate()}

          <td className="fw-bold">{userData.user.full_name}</td>
          <td>{userData.user.phone}</td>
          <td>
            {checkStatus(userData)}
            {checkDeleted(userData)}
          </td>
          {renderEye()}
          {/* <td>
            <FontAwesomeIcon
              className="icons px-2"
              icon={faEye}
              onClick={() => {
                fetchUserDetails(userData.id);
                setModalShow(true);
              }}
            />
          </td> */}
        </tr>
      );
    }

    return null; // Return null if userData is undefined
  };

  const checkDataloaded = () => {
    if (userdataLoaded) {
      return <>{cdata.map((user, index) => renderUserList(user, index))}</>;
    }
  };

  return (
    <Container fluid className="p-0">
      <div className="m-0 main-grid">
        <div className="p-0 m-0 d-none  d-md-block">
          {fetchNavObj()}
          {/*For larger screen (>=md) */}
        </div>

        <div className="m-0 p-0">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>

          <div className="user-dashboard w-100">
            {loading && (
              <div className="loader-container">
                <CustomSpinner className="inner-round" />
              </div>
            )}

            <div className=" me-5">
              <Row>
                <Col md={9}>
                <div className="search-bar-main d-flex m-3 ms-5 ">
                <InputGroup className="search-bar-child ">
                  <FormControl
                    className="search-bar-child"
                    placeholder="Search By User Name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && onSearchClick()}
                  />

                  <Button
                    className="btn "
                    variant="common"
                    onClick={onSearchClick}
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </Button>
                </InputGroup>
              </div>
            
                </Col>
                <Col md={3}>
                <Button
                variant="primary"
                onClick={onUserRegistration}
                className="button m-3"
              >
                <FontAwesomeIcon className="px-2" icon={faUserPlus} />
                <> Add User &nbsp;</>
              </Button>
                </Col>
              </Row>
            </div>
            <Row className="w-100">
              <Col className="text-center">
                <Row className="text-center ms-5 me-5 ">
                {filteredData.map((user) => renderUserCard(user))}
                </Row>
              </Col>
              {/* <Col lg={2} md={12} sm={12} >
               
              </Col> */}
            </Row>
            <Row className="mt-3 w-100">
              <div className="table-responsive table-wrapper border-start border-end">
                <table className="table mt-3 w-100">
                  <thead>
                    <tr>
                      <th scope="col">S No.</th>
                      <th scope="col">Joined At</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{checkDataloaded()}</tbody>
                </table>
              </div>
            </Row>
            <div className="d-flex justify-content-center mt-3">
              {renderPagination()}
            </div>
          </div>
        </div>
      </div>

      {dataLoaded ? (
        <Modal
          show={modalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header
            closeButton
            onHide={() => {
              setModalShow(false);
            }}
          >
            <Modal.Title
              className="fw-bold head"
              id="contained-modal-title-vcenter"
            >
              Profile
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            {loadingSmall ? (
              <ModalSpinner className="fs-2" />
            ) : (
              <>
                <Row className="m-3  ">
                  <Col lg={5} className="name-img mb-5 mt-5 ">
                    <div className="mt-3 d-flex align-items-center justify-content-center">
                      <div className="propic">
                        <img
                          src={udata.profile_pic ? udata.profile_pic : proImage}
                          className="w-100 propic"
                          alt="proPic"
                        ></img>
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <h2 className="fw-bold ">{udata.user.full_name}</h2>
                    </div>
                  </Col>

                  <Col className="ms-3">
                    <div className="my-3">
                      <label className="">
                        <b>Phone Number </b>{" "}
                      </label>
                      <input
                        type="text"
                        className="font-monospace w-100   clr p-1 ps-1"
                        disabled={true}
                        placeholder={udata.user.phone}
                      />
                    </div>

                    <div className="my-3">
                      <label className="">
                        <b>Email </b>
                      </label>
                      <input
                        type="text"
                        className="font-monospace w-100  clr p-1 ps-1"
                        disabled={true}
                        placeholder={udata.user.email}
                      />
                    </div>

                    <div className="my-3">
                      <label className="">
                        <b>Gender </b>
                      </label>
                      <input
                        type="text"
                        className="font-monospace w-100  clr p-1 ps-1"
                        disabled={true}
                        placeholder={udata.user.gender}
                      />
                    </div>

                    <div className="my-3">
                      <label className="">
                        <b>User Status </b>
                      </label>
                      <input
                        type="text"
                        className="font-monospace w-100  clr p-1 ps-1"
                        disabled={true}
                        placeholder={udata.status}
                      />
                    </div>

                    {udata.user.user_type === "student" && (
                      <>
                        <>
                          <div className="my-3">
                            <label className=" ">
                              <b>Parent Phone Number</b>{" "}
                            </label>
                            <input
                              className="font-monospace w-100  clr p-1 ps-1"
                              disabled={true}
                              placeholder={udata.parent_no}
                            ></input>
                          </div>

                          <div className="my-3">
                            <h5 className="fw-bold">Batches</h5>
                            <Row>{sdata.map((item) => batchCard(item))}</Row>
                          </div>
                        </>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="border-0 button"
              onClick={() => {
                setModalShow(false);
                setDataLoaded(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <div> </div>
      )}
    </Container>
  );
};

export default UsersDashboard;
