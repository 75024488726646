import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Form,
  Pagination,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCalendarAlt,
  faSort,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Sidebar from "../../../Common/Sidebar/SideBar";
import NavBar from "../../../Common/Navbar/NavBar";
import useApi from "../../../hooks/useApi";
import AuthContext from "../../../contexts/authContext";

import { endpoints } from "../../../defaults";
import logoImage from "../../../images/logo1.png";
import CustomSpinner from "../../../Common/Spinner/spinner";
import "./StudentAttendance.css";

import "./StudentAttendance.css"; // Import your custom styles
import Trail from "../../../Common/Breadcrumb/Trail";

const StudentsAttendance = () => {
  const [Bdata, setBData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const batchId = queryParams.get("id");
  const { request: getStudentsList } = useApi("get");
  const { request: addstudents } = useApi("post");
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [adata, setAData] = useState([]);
  const [aTdata, SetAtdata] = useState([]);
  const [selDivShow, setSelDivShow] = useState(false);
  const [userdataLoaded, setUserdataLoaded] = useState(null);
  const [page, setPage] = useState("1");
  const [checkedIds, setCheckedIds] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [pageInput, setPageInput] = useState(page);
  const [sortOrder, setSortOrder] = useState("asc");

  const heading = `${Bdata.batch_name} - Attendance `;

  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        student: {
          title: "Students",
          link: "course/batches/students/",
          icon: "users",
        },
        Overview: {
          title: "Overview",
          link: "course/batches/overview/",
          icon: "eye",
        },
        video: {
          title: "Videos",
          link: "course/batches/videos/",
          icon: "video",
        },
        liveclasses: {
          title: "Live Classes",
          link: "course/batches/liveclasses/",
          icon: "chalkboard-teacher",
        },
        markAttendance: {
          title: "Mark Attendance",
          link: "course/batches/studentAttendance/",
          icon: "user-check",
        },
        viewAttendance: {
          title: "View Attendance",
          link: "course/batches/studentViewAttendance/",
          icon: "list-alt",
        },
        batchShift: {
          title: "Batch Changed Students",
          link: "batchShiftedList",
          icon: "exchange-alt",
        },
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={batchId} />;
    } else {
      const navObj = {
        student: {
          title: "Students",
          link: "course/batches/students/",
          icon: "users",
        },
        Overview: {
          title: "Overview",
          link: "course/batches/overview/",
          icon: "eye",
        },
        video: {
          title: "Videos",
          link: "course/batches/videos/",
          icon: "video",
        },
        liveclasses: {
          title: "Live Classes",
          link: "course/batches/liveclasses/",
          icon: "chalkboard-teacher",
        },
        markAttendance: {
          title: "Mark Attendance",
          link: "course/batches/studentAttendance/",
          icon: "user-check",
        },
        viewAttendance: {
          title: "View Attendance",
          link: "course/batches/studentViewAttendance/",
          icon: "list-alt",
        },
        bulkAdd: {
          title: "Bulk Add Students",
          link: "bulkAddStudentsToBatch",
          icon: "fa fa-users",
        },
        batchShift: {
          title: "Batch Changed Students",
          link: "batchShiftedList",
          icon: "exchange-alt",
        },
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={batchId} />;
    }
  };

  const fetchBreadcrumbs = () => {
    const itemObj = {
      dashBoard: { title: "Courses", link: "" },
      batches: { title: "Batches", link: `course/batches/?id=${Bdata.course}` },
      student: {
        title: "Mark Attendance",
        link: `course/batches/studentAttendance/?id=${batchId}`,
      },
    };
    return <Trail itemObj={itemObj} />;
  };

  useEffect(() => {
    fetchBatches();
  }, []);

  const initializeCheckedIds = (users, attData) => {
    console.log("initialising");
    console.log("attData", attData);
    console.log("users", users);
    let tempCheckedIds = checkedIds;
    let ids = users.map((i) => i.student.id);
    if (attData.length > 0) {
      for (let user of attData) {
        if (ids.includes(user.student)) {
          tempCheckedIds[user.student] = user.status;
        }
      }
    } else {
      for (let user of users) {
        if (user.recorded_student) {
          tempCheckedIds[user.student.id] = "recorded";
        } else {
          tempCheckedIds[user.student.id] = "absent";
        }
      }
    }
    setCheckedIds(tempCheckedIds);
  };

  const fetchBatches = async () => {
    setLoading(true);
    const URI = `${endpoints.FETCH_BATCH}${batchId}/`;
    try {
      const apiResponse = await getStudentsList(URI);
      const { response, error } = apiResponse;
      if (!error && response.data) {
        const { data: apiData } = response;
        setBData(apiData.data);
      } else {
        const { response: errRes } = error;
        toast.error(
          errRes?.data?.message || "Error Occurred. Please contact Admin !!"
        );
      }
    } catch (error) {
      console.error("Error occurred during fetchBatches:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
    setLoading(false);
  };

  const fetchStudentsList = async () => {
    setLoading(true);
    const url = `${endpoints.VIEW_STUDENTS}/${batchId}/active/up?search=${searchQuery}`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setAData(apiData.data);
      console.log("12345654321234565432", adata);
      initializeCheckedIds(apiData.data, aTdata);
      setUserdataLoaded(true);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setSelDivShow(true);
    setLoading(false);
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const renderSortedUserList = (users) => {
    return users
      .sort((a, b) => {
        const nameA = a.student.user.full_name.toUpperCase();
        const nameB = b.student.user.full_name.toUpperCase();
        if (sortOrder === "asc") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      })
      .map((user, index) => renderUserList(user, index));
  };

  const fetchStudentsAttendace = async (fDate) => {
    // SetAtdata([])
    const url = `${endpoints.VIEW_ATTENDANCE}${batchId}/?date=${fDate}`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      SetAtdata(apiData.data);
      if (apiData.status === 1) {
        SetAtdata(apiData.data);
      }
      console.log("attendance", aTdata);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
  };

  const checkDataloaded = () => {
    if (userdataLoaded) {
      return <>{adata.map((user, index) => renderUserList(user, index))}</>;
    }
  };

  const handleCheckboxChange = (userId, option) => {
    const updatedCheckedIds = { ...checkedIds, [userId]: option };
    setCheckedIds(updatedCheckedIds);
  };

  const renderCheckBox = (userId, recorded) => {
    return (
      <>
        <Form.Group className="mb-0">
          <div className="d-flex flex-row gap-md-2 gap-1 position-relative">
            <Form.Check
              type="checkbox"
              id={`presentCheckbox-${userId}`}
              checked={checkedIds[userId] === "present"}
              onChange={() => handleCheckboxChange(userId, "present")}
              className="checkbox-green position-relative"
              label={
                <span
                  style={{
                    fontWeight: "inherit",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  P
                </span>
              }
            />

            <Form.Check
              type="checkbox"
              id={`onlineCheckbox-${userId}`}
              checked={checkedIds[userId] === "online"}
              onChange={() => handleCheckboxChange(userId, "online")}
              className="checkbox-blue position-relative"
              label={
                <span
                  style={{
                    fontWeight: "inherit",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  O
                </span>
              }
            />
            <Form.Check
              type="checkbox"
              id={`absentCheckbox-${userId}`}
              checked={checkedIds[userId] === "absent"}
              onChange={() => handleCheckboxChange(userId, "absent")}
              className="checkbox-red position-relative"
              label={
                <span
                  style={{
                    fontWeight: "inherit",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  A
                </span>
              }
            />
            {(recorded ||
              aTdata?.find((i) => i.student === userId)?.status ===
                "recorded") && (
              <Form.Check
                type="checkbox"
                id={`recordedCheckbox-${userId}`}
                checked={checkedIds[userId] === "recorded"}
                onChange={() => handleCheckboxChange(userId, "recorded")}
                className="checkbox-yellow position-relative"
                label={
                  <span
                    style={{
                      fontWeight: "inherit",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    R
                  </span>
                }
              />
            )}
          </div>
        </Form.Group>
      </>
    );
  };

  const renderUserList = (item, index) => {
    let userData = null;
    userData = item.student;

    const renderDetails = () => {
      return (
        <>
          <td className="fw-bold">{userData.user.full_name}</td>
          <td>{userData.user.phone}</td>
        </>
      );
    };

    return (
      <tr className="content" key={userData.id}>
        <th scope="row">{index + 1}</th>
        {renderDetails()}
        <td>{renderCheckBox(userData.id, item.recorded_student)}</td>
      </tr>
    );
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  const handleButtonClick = async () => {
    const attendanceData = {
      date: formatDate(selectedDate),
      attendance_list: Object.entries(checkedIds).map(([stId, status]) => ({
        stId: parseInt(stId),
        status: status || "absent",
      })),
    };
    try {
      const url = `${endpoints.CREATE_ATTENDANCE}${batchId}/`;
      const apiResponse = await addstudents(url, attendanceData);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Students Attendance added successfully");
        setSelectedDate(null);
        setCheckedIds([]);
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened. Please contact admin.");
        }
      }
    } catch (error) {
      console.error("Error occurred during students addition:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
  };

  const renderButton = () => {
    const isAnyCheckboxSelected = Object.values(checkedIds).some(
      (value) => value !== null
    );

    if (isAnyCheckboxSelected) {
      return (
        <Button onClick={handleButtonClick} className="button me-md-4">
          Add Attendance
        </Button>
      );
    } else {
      return null;
    }
  };
  // const renderDatePicker = () => (
  //   <div className="d-flex align-items-center ms-4 mt-2">

  // <div className="info-bar ms-3">
  //   <span className="info-item" style={{ backgroundColor: 'green' }}>Present </span>
  //   <span className="info-gap"></span>
  //   <span className="info-item" style={{ backgroundColor: 'blue' }}>Online</span>
  //   <span className="info-gap"></span>
  //   <span className="info-item" style={{ backgroundColor: 'red' }}>Absent</span>
  // </div>
  //     <div className="ms-5">
  //       <DatePicker
  //         selected={selectedDate}
  //         onChange={(date) => setSelectedDate(date)}
  //         dateFormat="yyyy-MM-dd"
  //       />
  //     </div>
  //   </div>
  // );

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setUserdataLoaded(false);
    const formattedDate = formatDate(date);
    setIsDatePickerOpen(!isDatePickerOpen);
    await fetchStudentsAttendace(formattedDate);
  };

  const handlettt = () => {
    fetchStudentsList();
  };

  const renderDatePicker = () => (
    <div className="">
      {/* <div className="info-bar ms-3">
        <span className="info-item" style={{ backgroundColor: "#198754" }}>
          Present{" "}
        </span>
        <span className="info-gap"></span>
        <span className="info-item" style={{ backgroundColor: "#0d6efd" }}>
          Online
        </span>
        <span className="info-gap"></span>
        <span className="info-item" style={{ backgroundColor: "#dc3545" }}>
          Absent
        </span>
      </div> */}
      <div className="">
        <Button
          className="btn button"
          variant="common"
          onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
          {selectedDate ? selectedDate.toDateString() : "Select date"}
        </Button>
        <div
          className={`calendar-container2 ${
            isDatePickerOpen ? "visible" : "hidden"
          }`}
        >
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            maxDate={new Date()}
            dateFormat="yyyy-MM-dd"
            showPopperArrow={false}
            inline
          />
        </div>
        <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />{" "}
        <Button
          className="btn rounded-pill ms-3"
          variant="primary"
          onClick={() => handlettt()}
        >
          Submit
        </Button>
      </div>
      {/* <Button className="btn ms-3" variant="common" onClick={exportToExcel}>
        <FontAwesomeIcon icon={faFileExcel} className="me-2" />
        export
      </Button> */}
    </div>
  );

  const searchClick = () => {
    fetchStudentsList(page);
  };

  return (
    <Container fluid className="p-0">
      <div className="m-0 main-grid">
        <div className="p-0  d-none  d-md-block">{fetchNavObj()}</div>{" "}
        {/*For larger screen (>=md) */}
        <div className=" m-0 p-0 ">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>
          <div className="container batch-list">
            <div className="mt-2">{fetchBreadcrumbs()}</div>
            <div className="row mt-4">
              <Col md={12}>
                <div>
                  <Row>
                    <Col md={12}>
                      <div className="search-bar-main  mx-auto">
                        <InputGroup className="search-bar-child">
                          <FormControl
                            className="search-bar-child"
                            placeholder="Search By Student Name / Phone Number"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyDown={(e) =>
                              e.key === "Enter" && searchClick()
                            }
                          />
                          <Button className="btn" variant="common">
                            <FontAwesomeIcon
                              icon={faSearch}
                              title="Search"
                              onClick={searchClick}
                            />
                          </Button>
                        </InputGroup>
                      </div>
                    </Col>
                    <Col md={4} className="p-3 d-flex justify-content-center">
                      {renderDatePicker()}
                    </Col>
                    <Col md={8} className="p-lg-4 px-5 py-4">
                      <Row>
                        <Col lg={3} xs={6} className="">
                          <Form.Check
                            type="checkbox"
                            checked
                            readOnly
                            inline
                            className="checkbox-green demo"
                          />{" "}
                          Present
                        </Col>
                        <Col lg={3} xs={6} className="">
                          <Form.Check
                            type="checkbox"
                            checked
                            inline
                            readOnly
                            className="checkbox-blue demo"
                          />{" "}
                          Online
                        </Col>
                        <Col lg={3} xs={6} className="">
                          <Form.Check
                            type="checkbox"
                            checked
                            inline
                            readOnly
                            className="checkbox-red demo"
                          />{" "}
                          Absent
                        </Col>
                        <Col lg={3} xs={6} className="">
                          <Form.Check
                            type="checkbox"
                            checked
                            inline
                            readOnly
                            className="checkbox-yellow demo"
                          />{" "}
                          Recorded
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </div>
          </div>
          <Container className=" p-3">
            {loading && (
              <div className="loader-container">
                <CustomSpinner className="inner-round" />
              </div>
            )}
            <Row></Row>
            {selDivShow ? (
              <Row className="mt-3">
                {userdataLoaded && (
                  <>
                    <div className="table-responsive table-wrapper border-start border-end">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">S No.</th>
                            <th
                              scope="col"
                              className="hovered"
                              onClick={toggleSortOrder}
                            >
                              <FontAwesomeIcon icon={faSort} /> Name{" "}
                            </th>
                            <th scope="col">Phone</th>
                            <th scope="col">Mark Attendance</th>
                          </tr>
                        </thead>
                        {/* <tbody>{checkDataloaded()}</tbody> */}
                        <tbody>{renderSortedUserList(adata)}</tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      {renderButton()}
                    </div>
                  </>
                )}
              </Row>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center w-100">
                {" "}
                <h3 className="head">Please select a date</h3>
              </div>
            )}
            {/* <div className="d-flex justify-content-center mt-3">
{renderPagination()}
</div> */}
          </Container>
        </div>
      </div>
    </Container>
  );
};
export default StudentsAttendance;
