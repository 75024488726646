import React, { useState, useEffect, useContext } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Sidebar from "../../Common/Sidebar/SideBar";
import NavBar from "../../Common/Navbar/NavBar";
import useApi from "../../hooks/useApi";
import logoImage from "../../images/logo1.png";
import { endpoints } from "../../defaults";
import CustomSpinner from "../../Common/Spinner/spinner";
import { useLocation, useNavigate } from "react-router-dom";
import "./BatchOverview.css";
import AuthContext from "../../contexts/authContext";
import { faEdit, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Trail from "../../Common/Breadcrumb/Trail";


const BatchOverview = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const queryParams = new URLSearchParams(search);
  const BatchId = queryParams.get("id");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fdata, setfData] = useState([]);
  const [coundata, setcounData] = useState([]);
  const { request: getBatch } = useApi("get");
  const { request: getBatchFacultys } = useApi("get");
  const { request: getBatchCounsellors } = useApi("get");

  const heading = "Luminar Technolab";

  useEffect(() => {
    fetchFacultys();
    fetchCounsellors();
    fetchBatch();
  }, [BatchId]);

  const fetchBatch = async () => {
    setLoading(true);
    const url = `${endpoints.FETCH_BATCH}${BatchId}/`;
    const apiResponse = await getBatch(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        student: { title: "Students", link: "course/batches/students/", icon: "users" },
        Overview: { title: "Overview", link: "course/batches/overview/", icon: "eye" },
        video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
        liveclasses: { title: "Live Classes", link: "course/batches/liveclasses/", icon: "chalkboard-teacher" },
        markAttendance: { title: "Mark Attendance", link: "course/batches/studentAttendance/", icon: "user-check" },
        viewAttendance: { title: "View Attendance", link: "course/batches/studentViewAttendance/", icon: "list-alt" },
        batchShift: {title: "Batch Changed Students", link:"batchShiftedList", icon: "exchange-alt"},
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={BatchId} />;
    } else {
      const navObj = {
        student: {
          title: "Students",
          link: "course/batches/students/",
          icon: "users",
        },
        Overview: {
          title: "Overview",
          link: "course/batches/overview/",
          icon: "eye",
        },
        video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
        liveclasses: {
          title: "Live Classes",
          link: "course/batches/liveclasses/",
          icon: "chalkboard-teacher",
        },
        markAttendance: {
          title: "Mark Attendance",
          link: "course/batches/studentAttendance/",
          icon: "user-check",
        },
        viewAttendance: {
          title: "View Attendance",
          link: "course/batches/studentViewAttendance/",
          icon: "list-alt",
        },
        bulkAdd:{
          title: "Bulk Add Students",
          link:"bulkAddStudentsToBatch",
          icon:"fa fa-users",
        },
        batchShift: {
          title: "Batch Changed Students",
          link: "batchShiftedList",
          icon: "exchange-alt",
        },
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={BatchId} />;
    }
  };

  const fetchBreadcrumbs=()=>{
    const itemObj={
      dashBoard: { title: "Course", link: "" },
      batches: { title: "Batches", link: `course/batches/?id=${data.course}` }, 
      student: {title: "Overview",link: `course/batches/overview/?id=${data.id}`,
        
      },
    }
    return <Trail itemObj={itemObj} />
    }

  const dateConveter = (date) => {
    const originalDate = new Date(date);

    const formattedDate = originalDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  const fetchFacultys = async () => {
    setLoading(true);
    const url = `${endpoints.FETCH_BATCH_FACULTYS}${BatchId}/`;
    const apiResponse = await getBatchFacultys(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setfData(apiData);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchCounsellors = async () => {
    setLoading(true);
    const url = `${endpoints.FETCH_BATCH_COUNSELLORS}${BatchId}/`;
    const apiResponse = await getBatchCounsellors(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setcounData(apiData);
      console.log(coundata);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const facultyList = (faculty) => {
    return (
      <li className="list-group-item">
        <h6 className="">
          <span className=" text-dark">
            <b>{faculty.user.full_name}</b>
          </span>
        </h6>
        <p className="">
          <FontAwesomeIcon icon={faPhone} title="Add Module" /> {"  "}
          <span className=" text-dark">{faculty.user.phone}</span>
        </p>
      </li>
    );
  };
  const counsellorList = (counsellor) => {
    return (
      <li className="list-group-item">
        <h6 className="">
          <span className="text-dark">
            <b>{counsellor.user.full_name}</b>
          </span>
        </h6>
        <p className="">
          <FontAwesomeIcon icon={faPhone} title="Add Module" /> {"  "}
          <span className=" text-dark">{counsellor.user.phone}</span>
        </p>
      </li>
    );
  };

  const onEditBatchClick = (bId) => {
    navigate(`editbatch?id=${BatchId}`);
  };

  const renderEditModuleBtn = () => {
    if (user && user.user_type === "faculty") {
      return null;
    } else {
      return (
        <Button
          className="fw-bold button border-white "
          onClick={onEditBatchClick}
        >
          <FontAwesomeIcon icon={faEdit} title="Add Module" />
          {"  "}Edit Batch
        </Button>
      );
    }
  };

  const BatchCard = () => {
    return (
      <div >
        <Row className="overview-head">
          <Col lg={10} md={9} sm={12}>
            {/* <p className="m-0 fw-bold text-secondary">Batch Name :</p> */}
            <h2 className="m-0 fw-bold ">{data.batch_name}</h2>
          </Col>
          <Col lg={2} md={3} sm={12} className="text-end ">
            {renderEditModuleBtn()}
          </Col>
        </Row>
        <Row className="pe-4 ps-4 mt-4 text-center">
          <Col lg={3} md={6} sm={6} xs={12}>
            <p className="m-0 fw-bold mt-3 boarder-left shadow p-3">
              Start Date :<span className="clr"> {data.startdate}</span>
            </p>
          </Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <p className="m-0 fw-bold mt-3 boarder-left shadow p-3">
              Batch Time :<span className="clr"> {data.time}</span>
            </p>
          </Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <p className="m-0 fw-bold mt-3 boarder-left shadow p-3">
              Active Status :
              <span className="clr">
                {" "}
                {data.is_active ? "Active" : "InActive"}
              </span>
            </p>
          </Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <p className="m-0 fw-bold mt-3 boarder-left shadow p-3">
              Location :<span className="clr"> {data.location} </span>
            </p>
          </Col>
        </Row>
        <Row className="p-5">
          <Col lg={6} md={12} className="mt-3">
            <ul className="list-group">
              <h5 className="fw-bold list-group-item">Faculties</h5>
              {fdata.map((faculty) => facultyList(faculty))}
            </ul>
          </Col>
          <Col lg={6} md={12} className="mt-3">
            <ul className="list-group">
              <h5 className="fw-bold list-group-item">Counsellors</h5>
              {coundata.map((counsellor) => counsellorList(counsellor))}
            </ul>
          </Col>
        </Row>
<hr />
        <Row className="d-flex  p-1">
          <Col lg={6} md={6} sm={6} xs={12} className=" custom-borde text-center">
            <Row>
              <Col lg={6}>
                <p className="">
                  Created Date : 
                 <b> {dateConveter(data.created_date)}</b>
                </p>
              </Col>
              <Col lg={6}>
                <p className="">
                  Created : 
                  <b>{data.created_by}</b>
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} sm={6} xs={12} className=" custom-borde text-center ">
            <Row>
              <Col lg={6} >
                <p className="">
                  Update Date : <b> {dateConveter(data.updated_date)}</b>
                </p>
              </Col>
              <Col lg={6} >
                <p className="">
                  Update :<b> {data.updated_by}</b>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="">
      <div className=" main-grid">
        <div className="p-0  d-none  d-md-block">{fetchNavObj()}</div>{" "}
        {/*For larger screen (>=md) */}
        <div className=" m-0 p-0 ">

          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>
          <div className="content-margin m-3">
          <div className="">
          {fetchBreadcrumbs()}
        </div> 
            <Col md={12} className="batch-card">
              {loading ? <CustomSpinner /> : <BatchCard />}
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchOverview;
