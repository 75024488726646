import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  InputGroup,
  FormControl,
  Modal,
  Container,
  Row,
  Col,
  Pagination,
  Form,
  DropdownButton,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faExchangeAlt,
  faMagnifyingGlass,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faPersonArrowDownToLine } from "@fortawesome/free-solid-svg-icons";
import { faPersonArrowUpFromLine } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from "../../Common/Sidebar/SideBar";
import NavBar from "../../Common/Navbar/NavBar";
import logoImage from "../../images/logo1.png";
import CustomSpinner from "../../Common/Spinner/spinner";
import ModalSpinner from "../../Common/ModalSpinner/ModalSpinner";
import "./StudentsPage.css";
import { endpoints } from "../../defaults";
import { toast } from "react-toastify";
import useApi from "../../hooks/useApi";
import { useLocation } from "react-router-dom";
import proImage from "../../images/profile.png";
import AuthContext from "../../contexts/authContext";
import Trail from "../../Common/Breadcrumb/Trail";

const StudentsPage = () => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingSmall, setLoadingSmall] = useState(false);
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();

  const [data, setData] = useState([]);
  const [adata, setAData] = useState([]);
  const [idata, setIData] = useState([]);
  const [wdata, setWData] = useState([]);
  const [sdata, setSData] = useState([]);
  const [cData, setCData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [Bdata, setBData] = useState([]);
  const [udata, setuData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userdataLoaded, setUserdataLoaded] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [page, setPage] = useState("1");
  const [modalShow, setModalShow] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);

  const [padata, setPadata] = useState([]);
  const [pidata, setPidata] = useState([]);
  const [pwadata, setpPwdata] = useState([]);
  const [psdata, setPsdata] = useState([]);
  const [tData, setTData] = useState([]);

  const [batches, setBatches] = useState([]);

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selId, SetSelId] = useState([]);

  const [showBatchModal, setShowBatchModal] = useState(false);
  const [selUId, SetSelUId] = useState([]);
  const [courseSelected, setCourseSelected] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pageInput, setPageInput] = useState(page);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [sformData, setsFormData] = useState({
    course: "",
    batch: "",
  });

  const handleIconClick = (user) => {
    SetSelId(user);
    setShowConfirmationPopup(true);
  };

  const handleBatchShift = (user) => {
    SetSelUId(user);
    setShowBatchModal(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // You can perform additional actions with the selected date if needed
  };

  const renderPopup = () => {
    console.log("selId", selId);
    if (selId.student) {
      if (selectedUser === "active") {
        return (
          <Modal
            show={showConfirmationPopup}
            onHide={() => setShowConfirmationPopup(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add to Inactive</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <div className="my-3">{`Convert Student "${selId.student.user.full_name}" Into Inactive?`}</div>
              <Button className="mx-3" onClick={handleYesClick}>
                Yes
              </Button>
              <Button onClick={handleNoClick}>No</Button>
            </Modal.Body>
          </Modal>
        );
      } else if (selectedUser === "inactive") {
        return (
          <Modal
            show={showConfirmationPopup}
            onHide={() => setShowConfirmationPopup(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add to Active</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <div className="my-3">{`Convert Student "${selId.student.user.full_name}" Into Active?`}</div>
              <Button className="mx-3" onClick={handleYes1Click}>
                Yes
              </Button>
              <Button onClick={handleNoClick}>No</Button>
            </Modal.Body>
          </Modal>
        );
      }
    }
  };

  const handleCourseChange = (e) => {
    setsFormData({
      ...sformData,
      course: e.target.value,
    });
    setCourseSelected(true);
    fetchBatch(e.target.value);
  };

  const handleBatchChange = (e) => {
    setsFormData({
      ...sformData,
      batch: e.target.value,
    });
  };
  const renderCourseDropDown = (course) => {
    return (
      <option key={course.id} value={course.id}>
        {course.title}
      </option>
    );
  };

  const renderBatchDropDown = (batch) => {
    return (
      <option key={batch.id} value={batch.id}>
        {batch.batch_name}
      </option>
    );
  };
  const renderBatchShift = () => {
    return (
      <Modal
        show={showBatchModal}
        onHide={() => {
          setShowBatchModal(false);
          setsFormData({
            course: "",
            batch: "",
          });
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Batch Shift - {selUId.student?.user.full_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Form>
            <Form.Group className="mb-4" controlId="genderSelect">
              <Form.Label className="fw-bold">Course</Form.Label>
              <Form.Select
                aria-label=""
                required
                value={sformData.course}
                onChange={handleCourseChange}
              >
                <option value="" disabled>
                  {" "}
                  Select a course{" "}
                </option>
                {cData.map((course) => renderCourseDropDown(course))}
              </Form.Select>
            </Form.Group>

            {courseSelected ? (
              <Form.Group className=" mb-4" controlId="genderSelect">
                <Form.Label className="fw-bold">Batch</Form.Label>
                <Form.Select
                  aria-label=""
                  required
                  value={sformData.batch}
                  onChange={handleBatchChange}
                >
                  <option value="" disabled>
                    {" "}
                    Select a batch{" "}
                  </option>
                  {batches.map((batch) => renderBatchDropDown(batch))}
                </Form.Select>
              </Form.Group>
            ) : (
              <div> </div>
            )}

            <Button
              onClick={handleCancelClick}
              variant="outline-secondary"
              className="button-clr"
            >
              Cancel
            </Button>
            <Button
              className="mx-3 button"
              onClick={handleBatchChangeConfirmation}
            >
              Change Batch
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const handleCancelClick = () => {
    setsFormData({
      course: "",
      batch: "",
    });
    setShowBatchModal(false);
  };

  const handleBatchChangeConfirmation = () => {
    if (sformData.course === "") {
      toast.warning("Please select a course");
    } else if (sformData.batch == "") {
      toast.warning("Please select a batch");
    } else {
      // Show confirmation modal
      setShowConfirmationModal(true);
    }
  };

  const handleYesClick = () => {
    setActiveStudent(selId.student.id);
    setShowConfirmationPopup(false);
  };

  const handleYes1Click = () => {
    setInActiveStudent(selId.student.id);
    setShowConfirmationPopup(false);
  };

  const handleNoClick = () => {
    // Close the popup without performing any action
    setShowConfirmationPopup(false);
  };
  // const [nextWUri, SetNextWUri] = useState("");
  // const [prevWUri, setPrevWUri] = useState("");
  // const [nextIUri, SetNextIUri] = useState("");
  // const [prevIUri, setPrevIUri] = useState("");

  const { request: getStudentsList } = useApi("get");
  const { request: getCourses } = useApi("get");

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const batchId = queryParams.get("id");
  const { request: addstudents } = useApi("post");
  const { request: convertBatch } = useApi("post");

  useEffect(() => {
    fetchBatches();
    fetchStudentsList(page);
    setSelectedUser("active");
  }, []);

  useEffect(() => {
    fetchCourses();
  }, []);

  const heading = `${Bdata.batch_name}`;
  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        student: {
          title: "Students",
          link: "course/batches/students/",
          icon: "users",
        },
        Overview: {
          title: "Overview",
          link: "course/batches/overview/",
          icon: "eye",
        },
        video: {
          title: "Videos",
          link: "course/batches/videos/",
          icon: "video",
        },
        liveclasses: {
          title: "Live Classes",
          link: "course/batches/liveclasses/",
          icon: "chalkboard-teacher",
        },
        markAttendance: {
          title: "Mark Attendance",
          link: "course/batches/studentAttendance/",
          icon: "user-check",
        },
        viewAttendance: {
          title: "View Attendance",
          link: "course/batches/studentViewAttendance/",
          icon: "list-alt",
        },
        batchShift: {
          title: "Batch Changed Students",
          link: "batchShiftedList",
          icon: "exchange-alt",
        },
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={batchId} />;
    } else {
      const navObj = {
        student: {
          title: "Students",
          link: "course/batches/students/",
          icon: "users",
        },
        Overview: {
          title: "Overview",
          link: "course/batches/overview/",
          icon: "eye",
        },
        video: {
          title: "Videos",
          link: "course/batches/videos/",
          icon: "video",
        },
        liveclasses: {
          title: "Live Classes",
          link: "course/batches/liveclasses/",
          icon: "chalkboard-teacher",
        },
        markAttendance: {
          title: "Mark Attendance",
          link: "course/batches/studentAttendance/",
          icon: "user-check",
        },
        viewAttendance: {
          title: "View Attendance",
          link: "course/batches/studentViewAttendance/",
          icon: "list-alt",
        },
        bulkAdd: {
          title: "Bulk Add Students",
          link: "bulkAddStudentsToBatch",
          icon: "fa fa-users",
        },
        batchShift: {
          title: "Batch Changed Students",
          link: "batchShiftedList",
          icon: "exchange-alt",
        },
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={batchId} />;
    }
  };

  const fetchBreadcrumbs = () => {
    const itemObj = {
      dashBoard: { title: "Courses", link: "" },
      batches: { title: "Batches", link: `course/batches/?id=${Bdata.course}` },
      student: {
        title: "Students",
        link: `course/batches/students/?id=${Bdata.id}`,
      },
    };
    return <Trail itemObj={itemObj} />;
  };

  const fetchCourses = async () => {
    setLoading2(true);
    const apiResponse = await getCourses(endpoints.VIEW_COURSE);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setCData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading2(false);
  };

  const fetchBatches = async () => {
    setLoading(true);
    const URI = `${endpoints.FETCH_BATCH}${batchId}/`;
    try {
      const apiResponse = await getStudentsList(URI);
      const { response, error } = apiResponse;
      if (!error && response.data) {
        const { data: apiData } = response;
        setBData(apiData.data);
      } else {
        const { response: errRes } = error;
        toast.error(
          errRes?.data?.message || "Error Occurred. Please contact Admin !!"
        );
      }
    } catch (error) {
      console.error("Error occurred during fetchBatches:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
    setLoading(false);
  };

  const fetchBatch = async (cId) => {
    setLoading(true);
    const URI = `${endpoints.VIEW_BATCH}${cId}/`;
    const apiResponse = await getCourses(URI);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setBatches(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const handleToChangeBatch = async () => {
    try {
      const url = `${endpoints.SHIFT_BATCH}${batchId}/`;
      const newBatchId = parseInt(sformData.batch);
      const payload = {
        new_batch_id: newBatchId,
        student_id: selUId.student.id,
      };
      const apiResponse = await convertBatch(url, payload);
      const { response, error } = apiResponse;

      if (!error && response.data.message) {
        toast.success(response.data.message);
        setShowConfirmationModal(false);
        setShowBatchModal(false);
        fetchStudentsIList("1");
        fetchStudentsList("1");
        setCheckedIds([]);
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened. Please contact admin.");
        }
      }
    } catch (error) {
      console.error("Error occurred during student changing:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
  };

  const fetchStudentsList = async (page) => {
    setLoading(true);
    const url = `${endpoints.VIEW_STUDENTS}/${batchId}/active/?page=${page}&search=${searchQuery}`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setAData(apiData.data);
      setUserdataLoaded(true);
      setPadata(apiData.pagination);
      setPageInput(page);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchStudentsIList = async (page) => {
    setLoading(true);
    const url = `${endpoints.VIEW_STUDENTS}/${batchId}/inactive/?page=${page}&search=${searchQuery}`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setIData(apiData.data);
      setUserdataLoaded(true);
      setPidata(apiData.pagination);
      setPageInput(page);
      setLoading(false); // Move setLoading(false) inside the if block
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
      setLoading(false); // Move setLoading(false) inside the else block
    }
  };

  const setActiveStudent = async (id) => {
    setLoading(true);
    const url = `${endpoints.STUDENT}/${id}/add_inactive/${batchId}/`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      fetchStudentsList("1");
      console.log(apiData);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const setInActiveStudent = async (id) => {
    setLoading(true);
    const url = `${endpoints.STUDENT}/${id}/add_active/${batchId}/`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      fetchStudentsIList("1");
      console.log(apiData);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const onwaitingListClick = async (page) => {
    setLoading(true);
    const courseId = Bdata.course;

    const url = `${endpoints.W_LIST_COURSE}${courseId}/?page=${page}&search=${searchQuery}`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setWData(apiData.data);
      setUserdataLoaded(true);

      // SetNextWUri("");
      // if (apiData.pagination.next) {
      //   SetNextWUri(apiData.pagination.next);
      // }

      setpPwdata(apiData.pagination);
      setPageInput(page);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchStudentBatches = async (StudentId) => {
    const url = `${endpoints.STUDENT_BATCHES}${StudentId}/`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setTData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchUsersList = async (page) => {
    setLoading(true);
    const url = `${
      endpoints.GET_ALL_STUD_LIST
    }/${"student"}/?page=${page}&search=${searchQuery}`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setSData(apiData.data);
      setUserdataLoaded(true);
      setPsdata(apiData.pagination);
      setPageInput(page);

      // setPrevUri("");
      // if (apiData.pagination.previous) {
      //   setPrevUri(apiData.pagination.previous);
      //   console.log(prevUri);
      // }
      // SetNextUri("");
      // if (apiData.pagination.next) {
      //   SetNextUri(apiData.pagination.next);
      // }
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const handleAddStudents = async (payload) => {
    try {
      const url = `${endpoints.FETCH_BATCH}${batchId}/add_students/`;
      const apiResponse = await addstudents(url, payload);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Students added successfully");
        fetchStudentsIList("1");
        fetchStudentsList("1");
        if (selectedUser === "allStudents") {
          fetchUsersList("1");
        }
        setCheckedIds([]);
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened. Please contact admin.");
        }
      }
    } catch (error) {
      console.error("Error occurred during students addition:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
  };

  // const handlePageChange = () => {
  //   const userType = selectedUser.split(" ").join("").toLowerCase();
  //   setData([]);
  //   switch (userType) {
  //     case "waitinglist":
  //       onwaitingListClick();
  //       break;
  //     case "allstudents":
  //       fetchUsersList();
  //       break;
  //     case "active":
  //       fetchStudentsList();
  //       break;
  //     case "inactive":
  //     default:
  //       fetchStudentsIList();
  //       break;
  //   }
  // };

  // const renderPaginationControls = () => {
  //   if (nextUri) {
  //     return (
  //       <div className="pagination-container">
  //         <button
  //           className="pagination-button next"
  //           onClick={() => fetchpaginatednextList()}
  //         >
  //           Next
  //         </button>
  //       </div>
  //     );
  //   }
  //   if (prevUri) {
  //     return (
  //       <div className="pagination-container">
  //         <button
  //           className="pagination-button prev"
  //           onClick={() => handlePageChange()}
  //         >
  //           Previous
  //         </button>
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  const handleCheckboxChange = (userId) => {
    const isChecked = checkedIds.includes(userId);
    if (isChecked) {
      setCheckedIds(checkedIds.filter((id) => id !== userId));
    } else {
      setCheckedIds([...checkedIds, userId]);
    }
  };
  const handleSelectButtonClick = () => {
    console.log("Checked IDs:", checkedIds);
    const payload = {
      student_ids: checkedIds,
    };
    handleAddStudents(payload);
  };

  const onUserList = (item) => {
    const userType = item.split(" ").join("").toLowerCase();

    if (userType === "active") {
      fetchStudentsList(page);
    } else if (userType === "inactive") {
      fetchStudentsIList(page);
    }
  };

  const fetchUserDetails = async (id) => {
    setLoadingSmall(true);
    const url = `${endpoints.FECH_USER_DETAILS}${id}/`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setuData(apiData);
      setDataLoaded(true);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoadingSmall(false);
  };

  const renderStatus = (item) => {
    if (selectedUser === "waitingList") {
      // const userType = selectedUser.split(" ").join("").toLowerCase();
      if (item.status === "active")
        return (
          <span className="bg-success p-1 text-white rounded-2">Active</span>
        );
      else if (item.status === "inactive") {
        return (
          <span className="bg-danger p-1 text-white rounded-2">Inactive</span>
        );
      } else if (item.status === "waiting") {
        return (
          <span className="bg-warning p-1 text-white rounded-2">waiting</span>
        );
      }
    }
    if (selectedUser === "allStudents") {
      if (item.student.status === "active")
        return (
          <span className="bg-success p-1 text-white rounded-2">Active</span>
        );
      else if (item.student.status === "inactive") {
        return (
          <span className="bg-danger p-1 text-white rounded-2">Inactive</span>
        );
      } else if (item.student.status === "waiting") {
        return (
          <span className="bg-warning p-1 text-white rounded-2">waiting</span>
        );
      }
    } else {
      if (item.status === "active")
        return (
          <span className="bg-success p-1 text-white rounded-2">Active</span>
        );
      else if (item.status === "inactive") {
        return (
          <span className="bg-danger p-1 text-white rounded-2">Inactive</span>
        );
      } else if (item.status === "waiting") {
        return (
          <span className="bg-warning p-1 text-white rounded-2">waiting</span>
        );
      }
    }
  };

  const renderCheckBox = (item) => {
    if (item.status === "active") {
      return null;
    } else if (item.status === "inactive") {
      return null;
    } else if (selectedUser === "allStudents") {
      return (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(item.student.id)}
          checked={checkedIds.includes(item.student.id)}
        />
      );
    } else {
      return (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(item.id)}
          checked={checkedIds.includes(item.id)}
        />
      );
    }
  };

  const renderUserList = (item, index) => {
    let userData = null;
    let originalDate = null;
    if (selectedUser === "waitingList" && item.user) {
      userData = item.user;
      originalDate = new Date(item.created_date);
    } else if (selectedUser === "inactive") {
      if (item.batch_changed === true) {
        return null;
      }
      userData = item.student;
      originalDate = new Date(item.student.created_date);
    } else if (selectedUser === "active") {
      if (item.student.user.is_deleted === true) {
        return null;
      }
      userData = item.student;
      originalDate = new Date(item.student.created_date);
    } else {
      if (item.student.user.is_deleted === true) {
        return null;
      }
      userData = item.student;
      originalDate = new Date(item.student.created_date);
    }

    const formattedDate = originalDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const renderToInactiveBtn = (user) => {
      if (selectedUser === "active") {
        return (
          <>
            <Dropdown.Item onClick={() => handleIconClick(user)}>
              <FontAwesomeIcon
                className="icons2 px-2 drop-down-menu"
                icon={faPersonArrowDownToLine}
                title="Set student to Inactive"
              />
              Set to Inactive
            </Dropdown.Item>
            <Dropdown.Item
              className="editIcon drop-down-menu"
              onClick={() => handleBatchShift(user)}
            >
              <FontAwesomeIcon
                className=" px-2"
                icon={faExchangeAlt}
                title="Batch shift"
              />
              Batch Shift
            </Dropdown.Item>
          </>
        );
      } else if (selectedUser === "inactive") {
        return (
          <Dropdown.Item onClick={() => handleIconClick(user)}>
            <FontAwesomeIcon
              className="icons px-2"
              icon={faPersonArrowUpFromLine}
              title="Set student to Active"
            />
            Set to Active
          </Dropdown.Item>
        );
      }
    };

    const renderDetails = () => {
      if (selectedUser === "waitingList") {
        return (
          <>
            <td className="fw-bold">{userData.full_name}</td>
            <td>{userData.phone}</td>
            <td>{renderStatus(item)}</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant={"link"} className="head">
                  <FontAwesomeIcon icon={faPen} title="Actions" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      fetchUserDetails(userData.id);
                      setModalShow(true);
                      fetchStudentBatches(item.id);
                    }}
                  >
                    <FontAwesomeIcon
                      className="icons px-2"
                      icon={faEye}
                      title="Show Details"
                    />
                    View details
                  </Dropdown.Item>
                  {renderToInactiveBtn(item)}
                </Dropdown.Menu>
              </Dropdown>

              {renderToInactiveBtn(item)}
            </td>
          </>
        );
      } else
        return (
          <>
            <td className="fw-bold">{userData.user.full_name}</td>
            <td>{userData.user.phone}</td>
            <td>{renderStatus(item)}</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant={"link"} className="head">
                  <FontAwesomeIcon icon={faPen} title="Actions" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      fetchUserDetails(userData.user.id);
                      setModalShow(true);
                      setModalShow(true);
                      fetchStudentBatches(userData.id);
                    }}
                  >
                    <FontAwesomeIcon
                      className="icons px-2"
                      icon={faEye}
                      title="Show Details"
                    />{" "}
                    View details
                  </Dropdown.Item>
                  {renderToInactiveBtn(item)}
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </>
        );
    };

    return (
      <tr className="content" key={userData.id}>
        <th scope="row">{index + 1}</th>
        <td>{formattedDate}</td>
        {renderDetails()}

        <td>{renderCheckBox(item)}</td>
      </tr>
    );
  };

  const checkDataloaded = () => {
    if (userdataLoaded) {
      if (selectedUser === "active") {
        return <>{adata.map((user, index) => renderUserList(user, index))}</>;
      } else if (selectedUser === "inactive") {
        return <>{idata.map((user, index) => renderUserList(user, index))}</>;
      } else if (selectedUser === "waitingList") {
        return <>{wdata.map((user, index) => renderUserList(user, index))}</>;
      } else if (selectedUser === "allStudents") {
        return <>{sdata.map((user, index) => renderUserList(user, index))}</>;
      }
    }
  };

  const handleSearch = () => {
    // SetNextUri("");
    // SetNextSUri("");
    // SetNextWUri("");
    // SetNextIUri("");
    // setPrevSUri("");
    // setPrevUri("");
    // setPrevWUri("");
    switch (selectedUser) {
      case "active":
        onUserList("active");
        break;
      case "inactive":
        onUserList("inactive");
        break;
      case "waitingList":
        onwaitingListClick(page);
        break;
      case "allStudents":
        fetchUsersList(page);
        break;
      default:
        break;
    }
  };

  const renderTypeCard = () => {
    const handleCardClick = (userType) => {
      setData([]);

      setCheckedIds([]);
      switch (userType) {
        case "active":
          fetchStudentsList(page);
          break;
        case "inactive":
          // console.log("123456779")
          fetchStudentsIList(page);
          break;
        case "waitingList":
          onwaitingListClick(page);
          break;
        case "allStudents":
          fetchUsersList(page);
          break;
        default:
          break;
      }
      setSelectedUser(userType);
    };
    const renderAllStudents = () => {
      if (user && user.user_type === "faculty") {
        return null;
      } else {
        return (
          <Col
            lg={3}
            key={"allStudents"}
            className={` d-flex justify-content-center  ${
              selectedUser === "allStudents"
                ? "selected-card"
                : "clickable-card_"
            }`}
            onClick={() => handleCardClick("allStudents")}
          >
            <div>
              <h5 className="fw-bold m-0">{"All Students"}</h5>
            </div>
          </Col>
        );
      }
    };

    return (
      <Row className="mx-2">
        <Col
          lg={3}
          key={"active"}
          className={` d-flex justify-content-center   ${
            selectedUser === "active" ? "selected-card" : "clickable-card_"
          }`}
          onClick={() => handleCardClick("active")}
        >
          <div>
            <h5 className="fw-bold m-0">{"Active"}</h5>
          </div>
        </Col>
        <Col
          lg={3}
          key={"inactive"}
          className={` d-flex justify-content-center  ${
            selectedUser === "inactive" ? "selected-card" : "clickable-card_"
          }`}
          onClick={() => handleCardClick("inactive")}
        >
          <div>
            <h5 className="fw-bold m-0">{"Inactive"}</h5>
          </div>
        </Col>
        <Col
          lg={3}
          key={"waitingList"}
          className={` d-flex justify-content-center  ${
            selectedUser === "waitingList" ? "selected-card" : "clickable-card_"
          }`}
          onClick={() => handleCardClick("waitingList")}
        >
          <div>
            <h5 className="fw-bold m-0">{"Waiting List"}</h5>
          </div>
        </Col>
        {renderAllStudents()}
      </Row>
    );
  };

  const renderButton = () => {
    if (selectedUser === "allStudents" || selectedUser === "waitingList") {
      if (checkedIds && checkedIds.length > 0) {
        return (
          <Button onClick={handleSelectButtonClick}>
            Add Students to Active
          </Button>
        );
      }
    }
  };

  const handlePageClick = (i) => {
    console.log(i);
    if (selectedUser === "active") {
      fetchStudentsList(i);
    } else if (selectedUser === "inactive") {
      fetchStudentsIList(i);
    } else if (selectedUser === "waitingList") {
      onwaitingListClick(i);
    } else if (selectedUser === "allStudents") {
      fetchUsersList(i);
    } else return null;
    // onUserList(selectedUser, i);
  };
  const handlePageInput = (i, pdata) => {
    if (i > pdata.total_pages) {
      setPageInput(pdata.total_pages);
      handlePageClick(pdata.total_pages);
    } else if (i < 1) {
      setPageInput(1);
      handlePageClick(1);
    } else {
      handlePageClick(i);
    }
  };
  const renderPaginationDiv = (pdata, n) => {
    if (pdata.total_pages) {
      const buttons = [];
      if (pdata.current_page > 1) {
        // first and prev
        buttons.push(
          <Pagination.First key={"first"} onClick={() => handlePageClick(1)} />,
          <Pagination.Prev
            key={"prev"}
            onClick={() => handlePageClick(pdata.current_page - 1)}
          />
        );
      } else {
        buttons.push(
          <Pagination.First key={"first"} disabled />,
          <Pagination.Prev key={"prev"} disabled />
        );
      }
      // first ellipsis
      if (pdata.current_page > 1 + n) {
        buttons.push(<Pagination.Ellipsis />);
      }
      // numbers
      for (let i = 1; i <= pdata.total_pages; i++) {
        if (pdata.current_page - i <= n && i - pdata.current_page <= n) {
          buttons.push(
            <Pagination.Item
              key={i}
              onClick={() => handlePageClick(i)}
              disabled={i === pdata.current_page}
            >
              {i}
            </Pagination.Item>
          );
        }
      }
      // second ellipsis
      if (pdata.current_page < pdata.total_pages - n) {
        buttons.push(<Pagination.Ellipsis />);
      }
      // next and last
      if (pdata.current_page < pdata.total_pages) {
        buttons.push(
          <Pagination.Next
            key={"next"}
            onClick={() => handlePageClick(pdata.current_page + 1)}
          />,
          <Pagination.Last
            key={"last"}
            onClick={() => handlePageClick(pdata.total_pages)}
          />
        );
      } else {
        buttons.push(
          <Pagination.Next key={"next"} disabled />,
          <Pagination.Last key={"last"} disabled />
        );
      }
      return (
        <>
          <div className="pagination-container">
            <Pagination>{buttons}</Pagination>
            <label>
              Page
              <input
                type="number"
                name="page"
                id="pageNo"
                value={pageInput}
                onChange={(e) => setPageInput(e.target.value)}
                onBlur={() => handlePageInput(pageInput, pdata)}
                className="text-center form-control d-inline mx-1 p-0 mb-3 mb-lg-4"
                style={{ width: "60px" }}
              />
              of {pdata.total_pages}
            </label>
          </div>
        </>
      );
    }

    return null;
  };
  const renderPagination = () => {
    let n = 1; // number of page numbers to be shown - max 2n+1
    if (selectedUser === "active") {
      return renderPaginationDiv(padata, n);
    } else if (selectedUser === "inactive") {
      return renderPaginationDiv(pidata, n);
    } else if (selectedUser === "waitingList") {
      return renderPaginationDiv(pwadata, n);
    } else if (selectedUser === "allStudents") {
      return renderPaginationDiv(psdata, n);
    } else {
      return null;
    }
  };

  const batchCard = (batch) => {
    return (
      <div>
        <div className="border-bottom m-2 ps-2">{batch.batch_name}</div>
      </div>
    );
  };

  const ConfirmationModal = ({ show, onHide, onConfirm, onCancel }) => {
    return (
      <Modal
        show={show}
        onHide={onHide}
        className="mt-5"
        size="sm"
        dialogClassName="custom-dialog"
        data-bs-theme="dark"
      >
        <Modal.Header closeButton className="pb-0 mb-0"></Modal.Header>
        <Modal.Body className="py-0 my-0">
          Are you sure you want to change the batch?
        </Modal.Body>
        <Modal.Footer className="pt-0 mt-0">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleConfirmBatchChange = () => {
    handleToChangeBatch();
  };

  const handleCancelBatchChange = () => {
    // Close confirmation modal
    setShowConfirmationModal(false);
  };
  return (
    <Container fluid className="p-0">
      <div className="m-0 main-grid">
        <div className="p-0  d-none  d-md-block">{fetchNavObj()}</div>{" "}
        {/*For larger screen (>=md) */}
        <div className=" m-0 p-0 ">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>
          <div className="container batch-list">
            <div className="">{fetchBreadcrumbs()}</div>
            <div className="row mt-4">
              <div className="col-9">
                <div className="d-flex justify-content-end">
                  <div className="search-bar-main d-flex">
                    <InputGroup className=" search-bar-child ">
                      <FormControl
                        className="search-bar-child"
                        placeholder="Search By Student Name / Phone Number"
                        value={searchQuery}
                        onChange={(e) => {
                          // handleSearch();
                          setSearchQuery(e.target.value);
                        }}
                        onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                      />
                      <Button
                        className="btn"
                        variant="common"
                        onClick={handleSearch}
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          title="Search"
                        />
                      </Button>
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Container className="mt-1 p-3 ">
            {/* {loading && (
              <div className="loader-container">
                <CustomSpinner className="inner-round" />
              </div>
            )} */}

            {loading ? (
              <CustomSpinner />
            ) : (
              <div>
                {renderTypeCard()}
                <Row className="mt-4">
                  <div className="table-responsive table-wrapper border-start border-end">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">S No.</th>
                          <th scope="col">Joined At</th>
                          <th scope="col">Name</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                          {/* {renderSelectBtn()} */}
                          {selectedUser !== "active" &&
                            selectedUser !== "inactive" && (
                              <th scope="col">Select</th>
                            )}
                        </tr>
                      </thead>
                      <tbody>{checkDataloaded()}</tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    {renderButton()}
                  </div>
                </Row>
                <div className="d-flex justify-content-center mt-3">
                  {renderPagination()}
                </div>
              </div>
            )}
          </Container>
        </div>
      </div>

      {dataLoaded ? (
        <Modal
          show={modalShow}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header
            closeButton
            onHide={() => {
              setModalShow(false);
            }}
          >
            <Modal.Title className="fw-bold" id="contained-modal-title-vcenter">
              Profile
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {loadingSmall ? (
              <ModalSpinner className="fs-2" />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="">
                    <img
                      src={udata.profile_pic ? udata.profile_pic : proImage}
                      alt="Profile"
                      className="w-100 profile-pic"
                    ></img>
                  </div>
                </div>
                <div className="text-center mt-4">
                  {" "}
                  <h3 className="fw-bold ms-3">{udata.user.full_name}</h3>
                </div>

                <>
                  <>
                    <div className="my-3">
                      <label className="fw-bold w-100">
                        Phone Number{" "}
                        <input
                          disabled={true}
                          className="font-monospace clr w-100"
                          placeholder={udata.user.phone}
                        />
                      </label>
                    </div>
                  </>
                  <>
                    <div className="my-3">
                      <label className="fw-bold w-100">
                        Email
                        <input
                          disabled={true}
                          className="font-monospace clr w-100"
                          placeholder={udata.user.email}
                        />
                      </label>
                    </div>
                  </>
                </>
                <>
                  <>
                    <div className="my-3">
                      <label className="fw-bold w-100">
                        Gender
                        <input
                          disabled={true}
                          className="font-monospace clr w-100"
                          placeholder={udata.user.gender}
                        />
                      </label>
                    </div>
                  </>
                  <>
                    <div className="my-3">
                      <label className="fw-bold w-100">
                        User Status
                        <input
                          disabled={true}
                          className="font-monospace clr w-100"
                          placeholder={udata.status}
                        ></input>
                      </label>
                    </div>
                  </>
                </>
                {udata.user.user_type === "student" && (
                  <>
                    <>
                      <div className="my-3">
                        <label className="fw-bold w-100">
                          Parent Phone Number
                          <input
                            disabled={true}
                            className="font-monospace clr w-100"
                            placeholder={udata.parent_no}
                          />
                        </label>
                      </div>
                    </>
                    <Col md={6}>
                      <div className="my-3">
                        <h5 className="fw-bold">Batches</h5>
                        <Row>{tData.map((item) => batchCard(item))}</Row>
                      </div>
                    </Col>
                  </>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="button"
              onClick={() => {
                setModalShow(false);
                setDataLoaded(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <div> </div>
      )}

      {renderPopup()}
      {renderBatchShift()}
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        onConfirm={handleConfirmBatchChange}
        onCancel={handleCancelBatchChange}
      />
    </Container>
  );
};

export default StudentsPage;
