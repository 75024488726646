import React, { useState, useContext, useEffect } from "react";
import {
  Navbar,
  Nav,
  Button,
  Container,
  Row,
  Col,
  Card,
  Dropdown,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./coursePage.css";
import logoImage from "../../images/logo1.png";
import AuthContext from "../../contexts/authContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../../defaults";
import NavBar from "../../Common/Navbar/NavBar";
import Sidebar from "../../Common/Sidebar/SideBar";
import imgURL from "../../images/imgLink";
import CustomSpinner from "../../Common/Spinner/spinner";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Trail from "../../Common/Breadcrumb/Trail";


const CoursePage = () => {
  const [loading, setLoading] = useState(false);
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const { request: getCourses } = useApi("get");
  const navigate = useNavigate();
  const { request: deleteCourse } = useApi("delete");
  const heading = "Luminar Technolab";
  const [isAdmin, setIsadmin] = useState(true);

  const handleDeleteclick = (course) => {
    setCourseToDelete(course);
    setShowDeleteConfirmation(true);
  };

  const handleEditClick = (course) => {
    const id = course.id;
    navigate(`/addcourse?id=${id}`);
  };

  const handleConfirmation = async (confirmed) => {
    setShowDeleteConfirmation(false);

    if (confirmed && courseToDelete) {
      await handleDelete(courseToDelete.id);
    }

    setCourseToDelete(null);
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/");
    }

    if (user && user.user_type === "faculty") {
      setIsadmin(false);
    }

    if (user && !dataLoaded) {
      fetchCourses();
    }
  }, []);

  const fetchCourses = async () => {
    setLoading(true);
    
    const apiResponse = await getCourses(endpoints.VIEW_COURSE);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setDataLoaded(true);
    setLoading(false);
  };

  const deleteConfirm = (course) => {
    const CustomConfirmationToast = () => (
      <div className="text-center">
        <div className="mb-3">
          Are you sure you want to delete {course.title}
        </div>
        <Button
          variant="outline-dark logout-btn me-2"
          onClick={() => handleConfirmation(true)}
        >
          Yes
        </Button>
        <Button
          variant="outline-dark logout-btn"
          onClick={() => handleConfirmation(false)}
        >
          No
        </Button>
      </div>
    );

    const existingToast = toast.isActive("custom-confirmation-toast");

    if (!existingToast) {
      toast.warning(<CustomConfirmationToast />, {
        toastId: "custom-confirmation-toast",
        position: "top-center",
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleDelete = async (courseId) => {
    try {
      const uri = `${endpoints.DELETE_COURSE}/${courseId}/`;
      const apiResponse = await deleteCourse(uri);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Course successfully deleted!");
        fetchCourses();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    }
  };
  const handleOverviewClick = (courseObj) => {
    navigate(`/course/overview/?id=${courseObj.id}`, {
      state: { courseObj: courseObj },
    });
  };

  const handleCardClick = (courseObj) => {
    navigate(`/course/batches/?id=${courseObj.id}`, {
      state: { courseObj: courseObj },
    });
  };

  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "" },
        users: { title: "Users", link: "users", icon: "users" }
      };
      return <Sidebar navObj={navObj} />;
    } else {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "", icon: "chart-line" },
        users: { title: "Users", link: "users", icon: "users" },
        addCourse: { title: "Add Course", link: "addcourse", icon: "plus" },
        addCarousel: { title: "Add Carousel", link: "addCarousel", icon: "image" },
        lead: { title: "Leads", link: "leads", icon: "user-tie" },
      };
      return <Sidebar navObj={navObj} />;
    }
  };

 


  const renderCourseCard = (course) => {
    return (
      <Col lg={4} md={6} sm={6} xs={12} key={course.id} className="course-card mt-3">
        <div className="clickable-card ">
          <Card className="custom-card mb-4 shadow">
            <div>
              <Dropdown className="card-menu bg-info d-flex justify-content-end">
                <Dropdown.Toggle
                  variant="link"
                  id={`dropdown-menu-${course.id}`}
                  className="card-3dot fs-5 text-secondary custom-dropdown-toggle"
                >
                  <FontAwesomeIcon icon={faEllipsisV} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="custom-dropdown-item"
                    key={1}
                    onClick={() =>
                      handleOverviewClick(course)}
                  >
                    Overview
                  </Dropdown.Item>

                  {isAdmin ? <Dropdown.Item
                    className="custom-dropdown-item"
                    key={2}
                    onClick={() => handleEditClick(course)}
                  >
                    Edit
                  </Dropdown.Item> : " "}
                  {isAdmin ? <Dropdown.Item
                    className="custom-dropdown-item"
                    key={3}
                    onClick={() => handleDeleteclick(course)}
                  >
                    Delete
                  </Dropdown.Item> : " "}

                </Dropdown.Menu>
              </Dropdown>

              <div className="hovered" onClick={() => handleCardClick(course)}>

                <Row className="mt-3">
                  <Col lg={4}>
                    <div className="justify-content-center align-item-ceneter d-flex">
                      <Card.Img
                        className="crd-img "
                        src={`${course.thumbnail}`}

                        alt=""
                      />
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className="col-12 col-md-9 ms-0 crd-head ">
                      <Card.Title className="fw-bold mt-4 ms-0 clr">
                        {course.title}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>

                <Card.Header className="fw-bold crd-header  mt-4">Next Batch</Card.Header>
                <Card.Body className="mt-3 mb-4">
                  <p>
                    <span className="fw-bold text-dark">Cochin: </span>
                    {course.cochin_date}
                  </p>
                  <p>
                    <span className="fw-bold text-dark">Calicut: </span>
                    {course.calicut_date}
                  </p>
                </Card.Body>
              </div>
            </div>
          </Card>
        </div>
      </Col>


    );
  };

  return (
    <Container fluid className="p-0">

      <div className="m-0 main-grid" >
        <div className="p-0 m-0 d-none  d-md-block">
          {fetchNavObj()}
        </div>  {/*For larger screen (>=md) */}

       

        <div className=" m-0 p-0">
          
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none" >
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>

          <Container className="course-list p-3">
         
            {loading && (
              <div className="loader-container">
                <CustomSpinner className="inner-round" />
              </div>
            )}
            <Row>
              <Col>
                <Row>{data.map((course) => renderCourseCard(course))}</Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {showDeleteConfirmation && (
        <div className="custom-confirmation-modal">
          {deleteConfirm(courseToDelete)}
        </div>
      )}
    </Container>
  );
};
export default CoursePage;
