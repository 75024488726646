import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import NavBar from "../../Common/Navbar/NavBar";
import Sidebar from "../../Common/Sidebar/SideBar";
import logoImage from "../../images/logo1.png";
import CustomSpinner from "../../Common/Spinner/spinner";
import AuthContext from "../../contexts/authContext";
import { toast } from "react-toastify";
import { endpoints } from "../../defaults";
import useApi from "../../hooks/useApi";
import { useNavigate, useLocation } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "./UserRegistration.css";

const UserRegistration = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const UserId = queryParams.get("id");
  const [edit, setIsEdit] = useState(false);
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const navigate = useNavigate();
  const [udata, setuData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState("");
  const { request: getCourses } = useApi("get");
  const { request: getUserDetail } = useApi("get");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [ActiveStatus, setActiveStatus] = useState(false);
  const [employee, setemployee] = useState(false);
  const { request: createUser } = useApi("post");
  const { request: updateUser } = useApi("patch");
  const [employeeStatus, setemployeeStatus] = useState(true);

  //change later
  const [changedFullName, setChangedFullName] = useState(false);
  const [changedPhone, setChangedPhone] = useState(false);
  const [countryChanged, setCountryChanged] = useState(false);
  const [changedDOB, setChangedDOB] = useState(false);
  const [changedEmail, setChangedEmail] = useState(false);
  const [changedGender, setChangedGender] = useState(false);
  const [changedActive, setchangedActive] = useState(false);
  const [changedStaff, setChangedStaff] = useState(false);
  const [changedParentNo, setchangedParentNo] = useState(false);
  const [changedCourse, setchangedCourse] = useState(false);

  useEffect(() => {
    if (!dataLoaded) {
      fetchCourses();
    }
    if (UserId) {
      setIsEdit(true);
      fetchUserDetails(UserId);
    }
  }, []);

  const heading = () => {
    if (UserId) {
      return "Edit User";
    } else return "Add User";
  };

  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "" },
      };
      return <Sidebar navObj={navObj} />;
    } else {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "", icon: "chart-line" },
        users: { title: "Users", link: "users", icon: "users" },
        addCourse: { title: "Add Course", link: "addcourse", icon: "plus" },
        addCarousel: {
          title: "Add Carousel",
          link: "addCarousel",
          icon: "image",
        },
        lead: { title: "Leads", link: "leads", icon: "user-tie" },
      };
      return <Sidebar navObj={navObj} />;
    }
  };

  const [cformData, setcFormData] = useState({
    user_type: selectedUserType,
    user: {
      full_name: "",
      phone: "",
      country_code: "",
      dob: "",
      email: "",
      gender: "",
      password: "",
      user_type: selectedUserType,
      is_active: ActiveStatus,
      is_staff: employee,
    },
  });

  const [aformData, setaFormData] = useState({
    status: "inactive",
  });

  const [sformData, setsFormData] = useState({
    parent_no: "",
    course: "",
  });

  const CheckEmployeeStatus = () => {
    if (employeeStatus === true) {
      setaFormData({
        ...aformData,
        status: "active",
      });
    } else {
      setaFormData({
        ...aformData,
        status: "inactive",
      });
    }
  };

  const fetchUserDetails = async (id) => {
    setLoading(true);
    const url = `${endpoints.FECH_USER_DETAILS}${id}/`;
    const apiResponse = await getUserDetail(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setuData(apiData);
      setSelectedUserType(apiData.user.user_type);
      const { parent_no, course, user } = apiData;
      setsFormData({
        parent_no,
        course,
      });
      setcFormData({
        user,
      });
      setActiveStatus(user.is_staff);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchCourses = async () => {
    setLoading(true);
    const apiResponse = await getCourses(endpoints.VIEW_COURSE);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setDataLoaded(true);
    setLoading(false);
  };

  const renderCourseDropDown = (course) => {
    return (
      <option key={course.id} value={course.id}>
        {course.title}
      </option>
    );
  };

  const handleFullNameChange = (e) => {
    setChangedFullName(true);
    setcFormData({
      ...cformData,
      user: {
        ...cformData.user,
        full_name: e.target.value,
      },
    });
  };

  const handlePhoneChange = (e) => {
    setChangedPhone(true);
    const numericOnly = e.target.value.replace(/[^0-9]/g, "");
    setcFormData({
      ...cformData,
      user: {
        ...cformData.user,
        phone: numericOnly.substring(0, 10),
      },
    });
  };

  const handleCountryChange = (countryCode) => {
    setCountryChanged(true);
    setcFormData({
      ...cformData,
      user: {
        ...cformData.user,
        country_code: countryCode,
      },
    });
  };

  const handledobChange = (e) => {
    setChangedDOB(true);
    const inputDate = e.target.value;
    const [year, month, day] = inputDate.split("-"); // Split the input date
    const formattedDate = `${day}-${month}-${year}`; // Format the date as dd-mm-yyyy
  
    setcFormData({
      ...cformData,
      user: {
        ...cformData.user,
        dob: formattedDate, // Store the formatted date in dd-mm-yyyy format
      },
    });
  };
  

  const handleEmailChange = (e) => {
    setChangedEmail(true);
    setcFormData({
      ...cformData,
      user: {
        ...cformData.user,
        email: e.target.value,
      },
    });
  };

  const handleGenderChange = (e) => {
    setChangedGender(true);
    setcFormData({
      ...cformData,
      user: {
        ...cformData.user,
        gender: e.target.value,
      },
    });
  };

  const handleActiveChange = (e) => {
    setActiveStatus(!ActiveStatus);
    setcFormData({
      ...cformData,
      user: {
        ...cformData.user,
        is_active: ActiveStatus,
      },
    });
    setchangedActive(true);
  };

  const handleEmpChange = (e) => {
    setChangedStaff(true);
    setemployee(!employee);
    setcFormData({
      ...cformData,
      user: {
        ...cformData.user,
        is_staff: ActiveStatus,
      },
    });
  };

  const handleParentNoChange = (e) => {
    setchangedParentNo(true);
    setsFormData({
      ...sformData,
      parent_no: e.target.value,
    });
  };

  const handleCourseChange = (e) => {
    setchangedCourse(true);
    setsFormData({
      ...sformData,
      course: e.target.value,
    });
  };

  const handleSave = async () => {
    // if (selectedUserType === "admin" || selectedUserType === "faculty") {
    //   if (!aformData.status) {
    //     toast.error("Please fill out all the fields", {});
    //     return;
    //   }
    // }
    // if (selectedUserType === "student") {
    //   if (!cformData.user.full_name||!cformData.user.phone||cformData.user.password||!sformData.course) {
    //     toast.error("Please fill out all the fields", {});
    //     return;
    //   }
    // }

    try {
      let apiResponse;
      if (selectedUserType === "admin" || selectedUserType === "faculty") {
        const payload = {
          user_type: selectedUserType,
          status: "active",
          user: {
            full_name: cformData.user.full_name,
            dob: cformData.user.dob,
            email: cformData.user.email,
            gender: cformData.user.gender,
            is_active: true,
            is_staff: true,
            password: cformData.user.password,
            user_type: selectedUserType,
            phone: cformData.user.phone,
            country_code: cformData.user.country_code,
          },
        };
        const Editpayload = {
          user: {
            ...(changedFullName ? { full_name: cformData.user.full_name } : {}),
            ...(changedDOB ? { dob: cformData.user.dob } : {}),
            ...(changedEmail ? { email: cformData.user.email } : {}),
            ...(changedGender ? { gender: cformData.user.gender } : {}),
            ...(changedPhone ? { phone: cformData.user.phone } : {}),
            ...(countryChanged
              ? { country_code: cformData.user.country_code }
              : {}),
            ...(changedActive ? { is_active: cformData.user.is_staff } : {}),
          },
        };

        if (UserId) {
          const uri = `${endpoints.UPDATE_USER}${UserId}/`;
          apiResponse = await updateUser(uri, Editpayload);
        } else {
          const uri = `${endpoints.CREATE_USER}`;
          apiResponse = await createUser(uri, payload);
        }
      }
      if (selectedUserType === "student") {
        const payload = {
          user_type: selectedUserType,
          course: sformData.course,
          parent_no: sformData.parent_no,
          user: {
            full_name: cformData.user.full_name,
            dob: cformData.user.dob,
            email: cformData.user.email,
            gender: cformData.user.gender,
            is_active: true,
            is_staff: false,
            password: cformData.user.password,
            phone: cformData.user.phone,
            country_code: cformData.user.country_code,
          },
        };
        const Editpayload = {
          user: {
            ...(changedFullName ? { full_name: cformData.user.full_name } : {}),
            ...(changedDOB ? { dob: cformData.user.dob } : {}),
            ...(changedEmail ? { email: cformData.user.email } : {}),
            ...(changedGender ? { gender: cformData.user.gender } : {}),
            ...(changedPhone ? { phone: cformData.user.phone } : {}),
            ...(countryChanged
              ? { country_code: cformData.user.country_code }
              : {}),
          },
          ...(changedParentNo ? { parent_no: sformData.parent_no } : {}),
          ...(changedCourse ? { course: sformData.course } : {}),
        };

        if (UserId) {
          const uri = `${endpoints.UPDATE_USER}${UserId}/`;
          apiResponse = await updateUser(uri, Editpayload);
        } else {
          const uri = `${endpoints.CREATE_USER}`;
          apiResponse = await createUser(uri, payload);
        }
      }

      const { response, error } = apiResponse;
      if (!error && response.data) {
        toast.success("User successfully created!");
        <div className="loader-container">
          <CustomSpinner className="inner-round" />
        </div>;
        navigate("/users");
      }
      if (error.response && error.response.data) {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else if (error.response.data.message) {
          toast.error(error.response.data.message[0]);
        } else {
          const responseData = error.response.data;
          Object.keys(responseData).forEach((key) => {
            const errors = responseData[key];
            errors.forEach((error) => {
              console.error(`${key}: ${error}`);
            });
          });
        }
      } else {
        toast.error("Something happened. Please contact admin.");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        Object.keys(responseData).forEach((key) => {
          const errors = responseData[key];
          errors.forEach((error) => {
            console.error(`${key}: ${error}`);
          });
        });
      } else {
        toast.error(
          "Something went wrong with the network request please check if all the fields are filled."
        );
      }
    }
  };

  const handleCreateUser = () => {
    cformData.user.is_active = ActiveStatus;
    cformData.user.is_staff = employee;
    handleSave();
  };

  const handleCancel = () => {
    navigate("/users");
  };

  const renderButtons = () => {
    const rtnBtnName = UserId ? "Update" : "Create";

    if (selectedUserType) {
      return (
        <div className="d-flex justify-content-end mt-3">
          <Button
            variant="outline-secondary"
            onClick={handleCancel}
            className="me-2 button-clr"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleCreateUser}
            className="button"
          >
            {rtnBtnName}
          </Button>
        </div>
      );
    }

    return null;
  };

  const checkUserType = () => {
    return (
      <Form.Select
        className="usertype p-2 fw-bold"
        aria-label="Default select example"
        onChange={(e) => setSelectedUserType(e.target.value)}
        value={selectedUserType}
      >
        {selectedUserType ? null : <option>Select User Type</option>}

        {user.user_type === "superadmin" ? (
          <>
            <option value="admin">Admin</option>
          </>
        ) : null}

        {user.user_type === "superadmin" || user.user_type === "admin" ? (
          <>
            <option value="faculty">Faculty / Counselor</option>
          </>
        ) : null}

        {user.user_type === "superadmin" ||
        user.user_type === "admin" ||
        user.user_type === "faculty" ? (
          <>
            <option value="student">Student</option>
          </>
        ) : null}
      </Form.Select>
    );
  };

  const formatDate = (inputDate) => {
    const dateParts = inputDate.split("/");
    if (dateParts.length === 3) {
      const formattedDate = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
      return formattedDate;
    }
    return inputDate;
  };

  const formatDateToISO = (dateString) => {
    if (!dateString) return ''; // Return empty string if no date provided
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const commonForm = () => {
    const renderPswd = () => {
      if (UserId) {
        return null;
      } else
        return (
          <Form.Group controlId="Password">
            <Form.Label className="fw-bold">Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Password"
              autoComplete="new-password"
              required
              onChange={(e) => {
                setcFormData((prevData) => ({
                  ...prevData,
                  user: {
                    ...prevData.user,
                    password: e.target.value,
                  },
                }));
              }}
            />
          </Form.Group>
        );
    };
    return (
      <Form>
        <Row className="m-2 my-3">
          <Col lg={6}>
            <Form.Group controlId="fullName">
              <Form.Label className="fw-bold">Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your full name"
                autoComplete="off"
                value={cformData.user.full_name}
                onChange={handleFullNameChange}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group controlId="phoneNumber">
              <Form.Label className="fw-bold">Phone Number</Form.Label>
              <Row>
                <Col lg={3}>
                  <PhoneInput
                    defaultCountry="in"
                    value={cformData.user.country_code}
                    onChange={(countryCode) => handleCountryChange(countryCode)} // Pass countryCode directly
                    inputStyle={{ width: "60px" }}
                    inputProps={{ readOnly: true }}
                  />
                </Col>
                <Col lg={9}>
                  <Form.Control
                    type="tel"
                    pattern="[0-9]{1,15}"
                    placeholder="Enter your phone number"
                    autoComplete="off"
                    value={cformData.user.phone}
                    onChange={handlePhoneChange}
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>

        <Row className="m-2 my-3">
          <Col lg={6}>
            <Form.Group controlId="dateOfBirth">
              <Form.Label className="fw-bold">Date Of Birth</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter your date of birth"
                value={
                  cformData.user.dob ? formatDateToISO(cformData.user.dob) : ""
                }
                onChange={handledobChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group controlId="email655">
              <Form.Label className="fw-bold">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                autoComplete="off"
                value={cformData.user.email}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                onChange={handleEmailChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="m-2 my-3">
          <Col lg={6}>
            <Form.Group className="" controlId="genderSelect">
              <Form.Label className="fw-bold">Gender</Form.Label>
              <Form.Select
                aria-label="Gender selection"
                required
                value={cformData.user.gender}
                onChange={handleGenderChange}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>{renderPswd()}</Col>
        </Row>
      </Form>
    );
  };

  const studentForm = () => {
    return (
      <Row className="m-2 my-3">
        <Col md={6}>
          <Form.Group controlId="parentnumber">
            <Form.Label className="fw-bold">Parent Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Parent Number"
              autoComplete="off"
              value={sformData.parent_no}
              onChange={handleParentNoChange}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="" controlId="genderSelect">
            <Form.Label className="fw-bold">Course</Form.Label>
            <Form.Select
              aria-label=""
              required
              value={sformData.course}
              onChange={handleCourseChange}
            >
              <option value="">Select Course</option>
              {data.map((course) => renderCourseDropDown(course))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
    );
  };

  const employeeForm = () => {
    return edit ? (
      <Row className="m-2 my-3">
        <Col lg={6} className="d-flex">
          {edit ? (
            <>
              <Form.Group className="ms-md-2 ms-1 mt-3" controlId="">
                <Form.Label className="fw-bold">Staff&nbsp;Status</Form.Label>
                <Form.Check
                  className="fs-3"
                  type="switch"
                  id="stat"
                  required
                  checked={ActiveStatus}
                  onChange={handleActiveChange}
                />
              </Form.Group>
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>
    ) : (
      ""
    );
  };

  const renderUserType = () => {
    if (UserId) {
      return null;
    } else
      return (
        <div className="d-flex justify-content-center">
          <Col md={6}>
            <h5 className="text-center fw-bold">User Type</h5>
            {checkUserType()}
          </Col>
        </div>
      );
  };

  return (
    <Container fluid className="p-0">
      <div className="m-0 main-grid">
        <div className="p-0 m-0 d-none  d-md-block">
          {fetchNavObj()}
          {/*For larger screen (>=md) */}
        </div>

        <div className="m-0 p-0">
          <div className="sticky-top">
            <NavBar heading={heading()} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>
          <div className="user-dashboard p-md-3 p-1 w-100">
            {loading && (
              <div className="loader-container">
                <CustomSpinner className="inner-round" />
              </div>
            )}
            <Row>{renderUserType()}</Row>
            <Row className="w-100 px-2">
              <div className="d-flex justify-content-center p-0 m-2">
                <Col
                  lg={10}
                  className={`mt-3 p-md-3 p-1 ${
                    selectedUserType ? "border border-2 rounded-2" : ""
                  }`}
                >
                  {selectedUserType === "student" && (
                    <>
                      {commonForm()}
                      {studentForm()}
                    </>
                  )}
                  {selectedUserType === "admin" && (
                    <>
                      {commonForm()}
                      {employeeForm()}
                    </>
                  )}
                  {selectedUserType === "faculty" && (
                    <>
                      {commonForm()}
                      {employeeForm()}
                    </>
                  )}
                  {renderButtons()}
                </Col>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default UserRegistration;
