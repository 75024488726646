import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import AuthContext from "../../contexts/authContext";
import NavBar from "../../Common/Navbar/NavBar";
import Sidebar from "../../Common/Sidebar/SideBar";
import { endpoints } from "../../defaults";
import useApi from "../../hooks/useApi";
import { toast } from "react-toastify";
import logoImage from "../../images/logo1.png";
import imgURL from "../../images/imgLink";
import CustomSpinner from "../../Common/Spinner/spinner";
import './mobileAdditions.css'

const AddCarousel = () => {
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const { request: getImages } = useApi("get");
  const { request: deleteImage } = useApi("delete")
  const { request: addImage } = useApi("mPost");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState({ add: false, delete: false })
  console.log(btnDisabled);
  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    setLoading(true);
    const url = `${endpoints.VIEW_BANNERS}`;
    const apiResponse = await getImages(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const handleDelete = (id) => {
    setImageToDelete(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    setBtnDisabled({ ...btnDisabled, delete: true })
    try {
      const url = `${endpoints.DELETE_BANNERS}${imageToDelete}/`;
      const apiResponse = await deleteImage(url);
      const { error } = apiResponse;
      if (!error) {
        toast.success("Image deleted successfully");
        handleCloseModal();
        fetchImages(); // Refresh images after deletion
      } else {
        const { response: errRes } = error;
        toast.error(errRes?.data?.message || "Error deleting image");
      }
    } catch (error) {
      toast.error("Error deleting image");
    } finally {
      setShowDeleteConfirmation(false); // Close the confirmation modal
      setBtnDisabled({ ...btnDisabled, delete: false })
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setImageToDelete(null);
    setNewImage(null);
    setBtnDisabled({ ...btnDisabled, add: false })
  };

  const handleImageChange = (e) => {
    setBtnDisabled({ ...btnDisabled, add: false })
    setNewImage(e.target.files[0]);
  };

  const handleAddImage = async () => {
    setBtnDisabled({ ...btnDisabled, add: true })
    try {
      const url = `${endpoints.CREATE_BANNERS}`;
      const payload = {
        img: newImage
      }
      const apiResponse = await addImage(url, payload);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Video added successfully");
        fetchImages();
        setShowModal(false);
        handleCloseModal();

      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened. Please contact admin.");
        }
      }
    } catch (error) {
      console.error("Error occurred during image addition", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
  };

  const renderImages = () => {
    return data.map((image) => (
      <Col key={image.id} lg={4} md={6} className="mb-4">
        <Card className="shadow">
          <Card.Body>
            <div className="image-container">
              <img src={`${image.img}`} style={{maxWidth:'100%',maxHeight:'100%'}} />
              </div>

            <Button onClick={() => handleDelete(image.id)} className="mt-2 button">
              Delete
            </Button>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "" },
      };
      return <Sidebar navObj={navObj} />;
    } else {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "", icon: "chart-line" },
        users: { title: "Users", link: "users", icon: "users" },
        addCourse: { title: "Add Course", link: "addcourse", icon: "plus" },
        addCarousel: { title: "Add Carousel", link: "addCarousel", icon: "image" },
        lead: { title: "Leads", link: "leads", icon: "user-tie" },
      };
      return <Sidebar navObj={navObj} />;
    }
  };

  const heading = "Luminar Technolab";

  return (
    <Container fluid className="p-0">

      <div className="m-0 main-grid">
        <div className="p-0 m-0 d-none  d-md-block">
          {fetchNavObj()}
        </div>  {/*For larger screen (>=md) */}
        {" "}

        <div className="p-0 m-0">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none" >
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>
          {loading ? (
            <CustomSpinner />
          ) : (
            <>
              <Row className="m-md-3 m-1 mt-md-2 mt-5">
                <Col lg={10}>
                  <h1 className="m-0 head fw-bold fs-2">
                    Carousel for mobile application
                  </h1>
                </Col>
                <Col lg={2} className="text-end">
                  <Button onClick={handleShowModal} className="button">Add Image</Button>
                  <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add New Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form.Group controlId="newImage">
                        <Form.Control type="file" onChange={handleImageChange} />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="outline-secondary" onClick={handleCloseModal} className="button-clr">
                        Close
                      </Button>
                      <Button variant="primary" disabled={btnDisabled.add} onClick={handleAddImage} className="button">
                        Add Image
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure you want to delete this image?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="outline-secondary" onClick={() => setShowDeleteConfirmation(false)} className="btn btn-outline button-clr">
                        Cancel
                      </Button>
                      <Button variant="primary" disabled={btnDisabled.delete} onClick={handleConfirmDelete} className="button">
                        Yes, Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>

                </Col>
              </Row>
              <Row className="m-md-3 m-1">{renderImages()}</Row>
            </>
          )}
        </div>

      </div>
    </Container>
  );
};

export default AddCarousel;
