import React, { useEffect, useState,useContext } from "react";
import {
  Button,
  Container,
  Form,
  FormControl,
  InputGroup,
  Pagination,
  Row,
} from "react-bootstrap";

import logoImage from "./../../images/logo1.png";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../Common/Sidebar/SideBar";
import NavBar from "../../Common/Navbar/NavBar";
import useApi from "../../hooks/useApi";
import { endpoints } from "../../defaults";
import AuthContext from "../../contexts/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const RecordedStudent = () => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { search } = useLocation();
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const queryParams = new URLSearchParams(search);
  const batchId = queryParams.get("id");
  const [data, setData] = useState([]);
  const { request: getStudentsList } = useApi("get");
  const [pdata, setPdata] = useState([]);
  const [page, setPage] = useState("1");
  const [pageInput, setPageInput] = useState(page);
  const { request: recordedChange } = useApi("get");

  const heading = `Recorded Student List`;
  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        student: { title: "Students", link: "course/batches/students/", icon: "users" },
        Overview: { title: "Overview", link: "course/batches/overview/", icon: "eye" },
        video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
        liveclasses: { title: "Live Classes", link: "course/batches/liveclasses/", icon: "chalkboard-teacher" },
        markAttendance: { title: "Mark Attendance", link: "course/batches/studentAttendance/", icon: "user-check" },
        viewAttendance: { title: "View Attendance", link: "course/batches/studentViewAttendance/", icon: "list-alt" },
        batchShift: {title: "Batch Changed Students", link:"batchShiftedList", icon: "exchange-alt"},
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={batchId} />;
    } else {
      const navObj = {
        student: {
          title: "Students",
          link: "course/batches/students/",
          icon: "users",
        },
        Overview: {
          title: "Overview",
          link: "course/batches/overview/",
          icon: "eye",
        },
        video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
        liveclasses: {
          title: "Live Classes",
          link: "course/batches/liveclasses/",
          icon: "chalkboard-teacher",
        },
        markAttendance: {
          title: "Mark Attendance",
          link: "course/batches/studentAttendance/",
          icon: "user-check",
        },
        viewAttendance: {
          title: "View Attendance",
          link: "course/batches/studentViewAttendance/",
          icon: "list-alt",
        },
        bulkAdd:{
          title: "Bulk Add Students",
          link:"bulkAddStudentsToBatch",
          icon:"fa fa-users",
        },
        batchShift: {
          title: "Batch Changed Students",
          link: "batchShiftedList",
          icon: "exchange-alt",
        },
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={batchId} />;
    }
  };

  useEffect(() => {
    fetchStudentList(page);
  }, []);
  const fetchStudentList = async (page) => {
    setLoading(true);
    const url = `${endpoints.VIEW_STUDENTS}/${batchId}/active/?page=${page}&search=${searchQuery}`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
      setPdata(apiData.pagination);
      setPage(page);
      setPageInput(page);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };
  const handleRecordedChange = async (stuID) => {
    setLoading(true);
    try {
      const idd = stuID.student.id;
      const url = stuID.recorded_student
        ? `${endpoints.DENY_RECORDED}${idd}/${batchId}/`
        : `${endpoints.ALLOW_RECORDED}${idd}/${batchId}/`;
      const apiResponse = await recordedChange(url);
      const { response, error } = apiResponse;
      if (!error && response.data) {
        fetchStudentList(page);
      } else {
        const { response: errRes } = error;
        toast.error(
          errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
        );
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred. Please try again later.");
    }
    setLoading(false);
  };
  const handlePageInput = (i, pdata) => {
    if (i > pdata.total_pages) {
      setPageInput(pdata.total_pages);
      fetchStudentList(pdata.total_pages);
    } else if (i < 1) {
      setPageInput(1);
      fetchStudentList(1);
    } else {
      fetchStudentList(Math.floor(i));
    }
  };
  const renderPaginationDiv = (pdata, n) => {
    if (pdata.total_pages) {
      const buttons = [];
      if (pdata.current_page > 1) {
        // first and prev
        buttons.push(
          <Pagination.First
            key={"first"}
            onClick={() => fetchStudentList(1)}
          />,
          <Pagination.Prev
            key={"prev"}
            onClick={() => fetchStudentList(pdata.current_page - 1)}
          />
        );
      } else {
        buttons.push(
          <Pagination.First key={"first"} disabled />,
          <Pagination.Prev key={"prev"} disabled />
        );
      }
      // first ellipsis
      if (pdata.current_page > 1 + n) {
        buttons.push(<Pagination.Ellipsis key={"ell1"} />);
      }
      // numbers
      for (let i = 1; i <= pdata.total_pages; i++) {
        if (pdata.current_page - i <= n && i - pdata.current_page <= n) {
          buttons.push(
            <Pagination.Item
              key={i}
              onClick={() => fetchStudentList(i)}
              disabled={i === pdata.current_page}
            >
              {i}
            </Pagination.Item>
          );
        }
      }
      // second ellipsis
      if (pdata.current_page < pdata.total_pages - n) {
        buttons.push(<Pagination.Ellipsis key={"ell2"} />);
      }
      // next and last
      if (pdata.current_page < pdata.total_pages) {
        buttons.push(
          <Pagination.Next
            key={"next"}
            onClick={() => fetchStudentList(pdata.current_page + 1)}
          />,
          <Pagination.Last
            key={"last"}
            onClick={() => fetchStudentList(pdata.total_pages)}
          />
        );
      } else {
        buttons.push(
          <Pagination.Next key={"next"} disabled />,
          <Pagination.Last key={"last"} disabled />
        );
      }
      return (
        <>
          <div className="pagination-container ">
            <Pagination>{buttons}</Pagination>
            <label>
              Page
              <input
                type="number"
                name="page"
                id="pageNo"
                value={pageInput}
                onChange={(e) => setPageInput(e.target.value)}
                onBlur={() => handlePageInput(pageInput, pdata)}
                onKeyDown={(e) =>
                  e.key === "Enter" && handlePageInput(pageInput, pdata)
                }
                className="text-center form-control d-inline mx-1 p-0 mb-3 mb-lg-4"
                style={{ width: "60px" }}
              />
              of {pdata.total_pages}
            </label>
          </div>
        </>
      );
    }

    return null;
  };
  const checkDataloaded = () => {
    if (data) {
      return data.map((user, index) => renderList(user, index));
    } else {
      return <div>Error Occurred...</div>;
    }
  };

  const renderList = (data, index) => {
    const { student } = data;
    return (
      <tr key={index}>
        <th scope="row">{(page - 1) * 10 + index + 1}</th>
        <td className="fw-bold">{student.user.full_name}</td>
        <td>{student.user.phone}</td>
        <td>
          <Form.Check
            className="fs-4"
            type="switch"
            id={`stat-${index}`}
            required
            checked={data.recorded_student}
            onChange={() => handleRecordedChange(data)}
          />
        </td>
      </tr>
    );
  };

  return (
    <Container fluid className="p-0">
      <div className="m-0 main-grid">
      <div className="p-0  d-none  d-md-block">{fetchNavObj()}</div>{" "}
        {/*For larger screen (>=md) */}
        <div className=" m-0 p-0 ">

          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>

          <Container className="  ">
            <div className="search-bar-main d-flex pt-3 px-5 justify-content-center w-100">
              <InputGroup className="search-bar-child ">
                <FormControl
                  className="search-bar-child"
                  placeholder="Search By Student Name / Phone Number"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  onKeyDown={(e) => e.key === "Enter" && fetchStudentList(page)}
                />
                <Button
                  className="btn"
                  variant="common"
                  onClick={() => fetchStudentList(page)}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} title="Search" />
                </Button>
              </InputGroup>
            </div>
            <Row className="">
              <div className="table-responsive table-wrapper border-start border-end">
                <table className="text-center table  striped  hover">
                  <thead>
                    <tr>
                      <th scope="col">S No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Recorded</th>
                    </tr>
                  </thead>
                  <tbody>{checkDataloaded()}</tbody>
                </table>
              </div>
            </Row>
            <div className="d-flex justify-content-center mt-3">
              {renderPaginationDiv(pdata, 1)}
            </div>
          </Container>
        </div>
      </div>
    </Container>
  );
};

export default RecordedStudent;
