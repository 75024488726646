import React from "react";
import "./spinner.css"; // Import the custom styles

const CustomSpinner = () => {
  return (
    <div className="custom-spinner-container">
      <div className="custom-spinner"></div>
    </div>
  );
};







export default CustomSpinner;



