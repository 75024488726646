import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import logoImage from "../../images/logo1.png";
import NavBar from "../../Common/Navbar/NavBar";
import Sidebar from "../../Common/Sidebar/SideBar";
import "./BatchesOnBoarding.css";
import { endpoints } from "../../defaults";
import useApi from "../../hooks/useApi";
import CustomSpinner from "../../Common/Spinner/spinner";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const BatchesEdit = () => {
  const navigate = useNavigate();
  const [activeStatus, setActiveStatus] = useState(true);
  const [onlineStatus, setOnlineStatus] = useState(true);
  const [offlineStatus, setOfflineStatus] = useState(true);
  const [showFModal, setShowFModal] = useState(false);
  const [showCModal, setShowCModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [adata, setAData] = useState([]);
  const [bdata, setBData] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCounselors, setSelectedCounselors] = useState([]);
  const { request: updateCourses } = useApi("patch");
  // const { request: updateCourses } = useApi("put");
  const { request: getFaculty } = useApi("get");

  const { request: getBatch } = useApi("get");
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const Courseid = queryParams.get("id");
  const [Cid, setCid] = useState("");
  const [newBatchId, SetNewBatchId] = useState("");

  useEffect(() => {
    //  fetchFacultys();
    //  fetchCounsellors();
    fetchUsersList();
    fetchBatch();
  }, [Courseid]);

  const fetchBatch = async () => {
    setLoading(true);
    const url = `${endpoints.FETCH_BATCH}${Courseid}/`;
    const apiResponse = await getBatch(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setAData(apiData.data);
      setCid(apiData.data.course);
      setSelectedUsers(apiData.data.facultys);
      setSelectedCounselors(apiData.data.counsellors);
      console.log(apiData.data);
      const { batch_name, startdate, time, is_active, location } = apiData.data;
      setFormData({
        batch_name,
        startdate,
        time,
        is_active,
        location,
      });
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const handleShowFModal = () => {
    console.log(data);
    setShowFModal(true);
  };

  const handleShowCModal = () => {
    console.log(data);
    setShowCModal(true);
  };

  const handleCloseModal = () => {
    setShowCModal(false);
    setShowFModal(false);
  };

  const handleSelectUsers = () => {
    // Handle logic for selected users
    // You can update state or perform any other actions here
    console.log("Selected Users:", selectedUsers);
    handleCloseModal();
  };

  const [formData, setFormData] = useState({
    batch_name: "",
    course: Courseid,
    startdate: "",
    time: "",
    is_active: true,
    location: "",
  });

  const courseClear = () => {
    setFormData({
      batch_name: "",
      course: Courseid,
      startdate: "",
      time: "",
      is_active: true,
      location: "",
    });

    setActiveStatus(true);
    setOnlineStatus(true);
    setOfflineStatus(true);

    document.getElementById("batch_name").value = "";
    document.getElementById("startdate").value = "";
    document.getElementById("is_active").checked = false;
    document.getElementById("time").value = "";
    document.getElementById("location").value = "";
  };

  const fetchUsersList = async () => {
    const url = `${endpoints.FECH_USER_LIST}/faculty/`;
    const apiResponse = await getFaculty(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
      //  setUserdataLoaded(true);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const handleSave = async () => {
    SetNewBatchId("");
    // if (
    //   !formData.batch_name ||
    //   !formData.time ||
    //   !formData.location
    // ) {
    //   toast.error("Please fill out all the fields", {});
    //   return;
    // }
    try {
      let apiResponse;

      const payload = {
        batch_name: formData.batch_name,
        course: formData.course,
        startdate: formData.startdate,
        time: formData.time,
        is_active: formData.is_active,
        location: formData.location,
        facultys: selectedUsers,
        counsellors: selectedCounselors,
      };
      const url = `${endpoints.UPDATE_BATCH}${Courseid}/`;
      apiResponse = await updateCourses(url, payload);
      const { response, error } = apiResponse;
      if (!error && response.data) {
        console.log(response.data.data);
        const { data: apiData } = response;
        setBData(apiData.data);
        // SetNewBatchId(response.data.data.id);
        console.log("resp", setData);

        // if (bdata) {
        //   handleFacultyAdd();
        // }
        toast.success("Batch successfully updated!");
        navigate(`/course/batches/overview/?id=${Courseid}`);

        // courseClear();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    }
  };

  const handleFacultyAdd = async () => {
    // if (
    //   !formData.batch_name ||
    //   !formData.time ||
    //   !formData.location
    // ) {
    //   toast.error("Please fill out all the fields", {});
    //   return;
    // }
    try {
      let apiResponse;
      const payload = {
        faculty_ids: selectedUsers,
      };
      console.log("bdatata", bdata);
      const bId = bdata.data.id;
      const url = `${endpoints.FETCH_BATCH}${bId}/add_facultys/`;
      apiResponse = await updateCourses(url, payload);
      const { response, error } = apiResponse;
      if (!error && response.data) {
        console.log(response.data);
        // SetNewBatchId(response.data.data.id);
        // console.log("resp", newBatchId);
        toast.success("Batch successfully updated!");

        courseClear();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    }
  };

  const navObj = {
    batches: {
      title: "Batches",
      link: "course/batches/",
      icon: "graduation-cap",
    },
    overview: { title: "Overview", link: "course/overview/", icon: "eye" },
    demoVideo: { title: "DemoVideo", link: "course/demovideo/", icon: "video" },
    addBatch: { title: "Add Batch", link: "course/addBatch/", icon: "plus" },
  };

  const oncancelClick = () => {
    navigate(`/course/batches/overview/?id=${Courseid}`);
  };

  const heading = "Luminar Technolab";
  return (
    <Container fluid className="p-0">
      <div className="m-0 main-grid">
        <div className="p-0 m-0 side-bar">
          <Sidebar navObj={navObj} itemObj={Cid} />
        </div>

        <div className="m-0 p-0">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <Container className="course-list p-3">
            {loading && (
              <div className="loader-container">
                <CustomSpinner className="inner-round" />
              </div>
            )}
            <Row>
              <Col>
                <div className="col-12 form-card-parent mt-3">
                  <div className="row justify-content-center">
                    <Form className="col-11 mt-2" encType="multipart/form-data">
                      <div className="text-center">
                        {" "}
                        <h3 className="fw-bold m-4 head">Update Batch</h3>
                      </div>
                      <Row className="my-2">
                        <Col lg={9} md={12} className="mt-3">
                          <Form.Group controlId="batchName">
                            <Form.Label className="fw-bold">
                              Batch Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Batch name"
                              value={formData.batch_name}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  batch_name: e.target.value,
                                })
                              }
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={3} md={12} className="text-center mt-3">
                          <Form.Group className="" controlId="courseStatus">
                            <Form.Label className="fw-bold">
                              Batch Status
                            </Form.Label>
                            <Form.Check
                              className="fs-3"
                              type="switch"
                              id="batchStatus"
                              //   value={adata.status}
                              onChange={() => {
                                setActiveStatus(!activeStatus);
                                setFormData({
                                  ...formData,
                                  is_active: activeStatus,
                                });
                              }}
                              checked={formData.is_active}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className="row ">
                        <div className="col-lg-4 col-md-12 col-sm-12 mt-3 ">
                          <Form.Group controlId="startdate">
                            <Form.Label className="fw-bold">
                              Start Date
                            </Form.Label>
                            <Form.Control
                              type="date"
                              value={formData.startdate}
                              placeholder="Enter starting date "
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  startdate: e.target.value,
                                })
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 mt-3">
                          <Form.Group controlId="time">
                            <Form.Label className="fw-bold">Time</Form.Label>
                            <Form.Control
                              type="time"
                              value={formData.time}
                              placeholder="Time"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  time: e.target.value,
                                });
                              }}
                              required
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 mt-3">
                          <Form.Group controlId="Location">
                            <Form.Label className="fw-bold">
                              Location
                            </Form.Label>
                            <Form.Control
                              as="select"
                              value={formData.location}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  location: e.currentTarget.value,
                                })
                              }
                            >
                              <option>Select Location</option>
                              <option value="cochin">cochin</option>
                              <option value="calicut">calicut</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                      </div>
                      <Row className="mt-3">
                       
                        <Col lg={6}>
                          <div>{""}</div>
                          <div
                            className="form-control mt-3 "
                            onClick={handleShowFModal}
                          >
                            {" "}
                            Select Faculty
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div
                            className="form-control  mt-3 "
                            onClick={handleShowCModal}
                          >
                            {" "}
                            Select counsellor
                          </div>
                        </Col>
                      </Row>
                      <div className="row mt-3">
                        <div className="col-12 d-flex justify-content-end">
                          <div>
                            <Button
                              variant="outline-secondary"
                              className="me-3 button-clr"
                              onClick={oncancelClick}
                            >
                              Cancel
                            </Button>
                            <Button className="button" onClick={handleSave}>
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Modal show={showFModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Faculty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data.map((user) => (
            <Form.Check
              key={user.faculty.user.id}
              type="checkbox"
              label={user.faculty.user.full_name}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedUsers([...selectedUsers, user.faculty.id]);
                } else {
                  setSelectedUsers(
                    selectedUsers.filter((id) => id !== user.faculty.id)
                  );
                }
              }}
              checked={selectedUsers.includes(user.faculty.id)}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {/* Button to select users */}
          <Button variant="primary" onClick={handleSelectUsers}>
            Select Faculty
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Counsellor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data.map(
            (user) =>
              !selectedUsers.includes(user.id) && (
                <Form.Check
                  key={user.faculty.user.id}
                  type="checkbox"
                  label={user.faculty.user.full_name}
                  onChange={(e) => {
                    const facultyId = user.faculty.id;
                    if (e.target.checked) {
                      setSelectedCounselors([...selectedCounselors, facultyId]);
                    } else {
                      setSelectedCounselors(
                        selectedCounselors.filter((id) => id !== facultyId)
                      );
                    }
                  }}
                  checked={selectedCounselors.includes(user.faculty.id)}
                />
              )
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSelectUsers}>
            Select Counsellor
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default BatchesEdit;
