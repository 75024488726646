import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./SideBar.css";
import logoImage from "../../images/logo1.png";

import {
  CDBSidebar,
  CDBSidebarHeader,
  CDBSidebarMenuItem,
  // CDBSidebarContent,
  // CDBSidebarMenu,
} from 'cdbreact';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
const Sidebar = ({ navObj, itemObj }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="primary" className="d-md-none mt-2  t-button" onClick={handleShow}>
        <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
      </Button>
      <Offcanvas show={show} onHide={handleClose} responsive="md" className="h-100 side-bar-bg">

        <div className="side-bar-bg side-bar h-md-100 sticky-top overflow-y-hidden w-100"> {/* class h-md-100 is important */}
          <Offcanvas.Header closeButton className="justify-content-end" />
          <CDBSidebar textColor="#333" backgroundColor="#f0f0f0" className="h100 " breakpoint={900} >
          <Link to={'/'} className="text-normal">

            <div className="p-2 pt-3 container w-100 d-flex align-items-center justify-content-center">
              <img
                src={logoImage}
                alt="Luminar"
                style={{ width: '45px' }}
                className="py-0 my-0"
              />
              <h2 className="text-center my-0 py-0 d-block d-md-none">Luminar</h2>
            </div>
          </Link>
          <CDBSidebarHeader prefix={<i className="fa fa-bars mt-3" />} className="d-none d-md-block">
            <Link to={'/'} className="text-normal">
              <div className="container w-100" style={{ display: 'flex', alignItems: 'center' }}>
                <h1 className="text-center">Luminar</h1>
              </div>
            </Link>
          </CDBSidebarHeader>
          
          <Offcanvas.Body className="d-block">

              {Object.keys(navObj).map((key) => (
                <Link to={`/${navObj[key].link}${itemObj ? `?id=${itemObj}` : ''}`} key={key} className="text-normal">
                  <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`tooltip-${navObj[key].title}`}>
                        {navObj[key].title}
                      </Tooltip>
                    }
                  >
                    <CDBSidebarMenuItem icon={navObj[key].icon ? navObj[key].icon : "th-large"}>{navObj[key].title}</CDBSidebarMenuItem>
                  </OverlayTrigger>
                </Link>))}
          </Offcanvas.Body>
          </CDBSidebar>

        </div>
      </Offcanvas>

      {/* <Offcanvas show={show} onHide={handleClose} responsive="md" className="sticky-top">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Responsive offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="mb-0">
            This is content within an <code>.offcanvas-lg</code>.
          </p>
        </Offcanvas.Body>
      </Offcanvas> */}

      {/* CDB react */}

      {/* <div className="sticky-top h100">
        <CDBSidebar textColor="#333" backgroundColor="#f0f0f0" className="h100 "  breakpoint={900}>
          <Link to={'/'} className="text-normal">
            <div className="container text-center pt-3">
              <img
                src={logoImage}
                alt=""
                style={{ width: '40px' }}
              />
            </div>
          </Link>
          <CDBSidebarHeader prefix={<i className="fa fa-bars mt-3" />}>
            <Link to={'/'} className="text-normal">
              <div className="container w-100" style={{ display: 'flex', alignItems: 'center' }}>
                <h1 className="text-center">Luminar</h1>
              </div>
            </Link>
          </CDBSidebarHeader>
          <CDBSidebarContent>
            <CDBSidebarMenu>
              {Object.keys(navObj).map((key) => (
                <Link to={`/${navObj[key].link}${itemObj ? `?id=${itemObj}` : ''}`} key={key}>
                  <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`tooltip-${navObj[key].title}`}>
                        {navObj[key].title}
                      </Tooltip>
                    }
                  >
                  <CDBSidebarMenuItem icon={navObj[key].icon ? navObj[key].icon : "th-large"}>{navObj[key].title}</CDBSidebarMenuItem>
                  </OverlayTrigger>
                </Link>))}
            </CDBSidebarMenu>
          </CDBSidebarContent>
        </CDBSidebar>
      </div>
       */}
    </>
  );
};

export default Sidebar;
