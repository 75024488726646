import React, { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./App.css";
import AuthContext from "./contexts/authContext";
import LoginForm from "./Components/LoginForm/loginForm";
import CoursePage from "./Components/CoursePage/coursePage";
import BatchPage from "./Components/BatchPage/batchPage";
import CoursesOnBoarding from "./Components/CoursesOnBoarding/CoursesOnBoarding";
import UsersDashboard from "./Components/UsersPage/usersDashboard";
import UserRegistration from "./Components/UserRegistration/UserRegistration";
import CourseOverview from "./Components/CourseDetails/CourseOverview";
import DemoVideo from "./Components/CourseDetails/DemoVideo";
import StudentsPage from "./Components/BatchDetails/StudentsPage";
import BatchesOnBoarding from "./Components/BatchesOnBoarding/BatchesOnBoarding";
import BatchOverview from "./Components/BatchDetails/BatchOverview";
import BatchVideos from "./Components/BatchDetails/BatchVideos";
import BatchLiveClasses from "./Components/BatchDetails/BatchLiveClasses";
import UserProfile from "./Components/UsersPage/userProfile";
import BatchesEdit from "./Components/BatchesOnBoarding/BatchesEdit";
import BatchVideoFolder from "./Components/BatchDetails/BatchVideoFolder";
import AddCarousel from "./Components/MobileAppAdittions/AddCarousel";
import StudentsAttendance from "./Components/BatchDetails/Attendance/StudentsAttendance";
import StudentsAttendanceView from "./Components/BatchDetails/Attendance/StudentsAttendanceView";
import EnquiryLeads from "./Components/EnquiryLeads/EnquiryLeads";
import Batchshift from "./Components/BatchDetails/BatchShift/Batchshift";
import RecordedAccess from "./Components/RecordedStudent/RecodedStudent";
import RecordedStudent from "./Components/RecordedStudent/RecodedStudent";
import BulkAddToBatch from "./Components/BatchDetails/BulkAdd/BulkAddtoBatch";


function App() {
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <Routes>
        <Route path="/" element={user ? <CoursePage /> : <LoginForm />}></Route>
        {/* <Route path="/dashboard" element={<CoursePage />}></Route> */}
        <Route path="/addCourse" element={<CoursesOnBoarding />}></Route>
        <Route path="/addCarousel" element={<AddCarousel />}></Route>
        <Route path="/editCourse" element={<CoursesOnBoarding />}></Route>
        <Route path="/course/batches" element={<BatchPage />}></Route>
        <Route path="/course/overview" element={<CourseOverview />}></Route>
        <Route path="/course/demovideo/" element={<DemoVideo />}></Route>
        <Route
          path="course/batches/overview/"
          element={<BatchOverview />}
        ></Route>
        <Route
          path="course/batches/videos/videosList/"
          element={<BatchVideos />}
        ></Route>
        <Route
          path="course/batches/videos/"
          element={<BatchVideoFolder />}
        ></Route>
        <Route
          path="course/batches/liveclasses/"
          element={<BatchLiveClasses />}
        ></Route>

        <Route path="/users" element={<UsersDashboard />}></Route>
        <Route path="/user/profile/" element={<UserProfile />}></Route>
        <Route path="course/addBatch/" element={<BatchesOnBoarding />}></Route>
        <Route
          path="/course/batches/overview/editbatch"
          element={<BatchesEdit />}
        ></Route>
        <Route
          path="/users/registration/"
          element={<UserRegistration />}
        ></Route>
        <Route
          path="/course/batches/students"
          element={<StudentsPage />}
        ></Route>
        <Route
          path="/course/batches/studentAttendance"
          element={<StudentsAttendance />}
        ></Route>
        <Route
          path="/course/batches/studentViewAttendance"
          element={<StudentsAttendanceView />}
        ></Route>
        <Route path="/leads" element={<EnquiryLeads />}></Route>
        <Route path="/batchShiftedList" element={<Batchshift />}></Route>
        <Route path="/recordedStudent" element={<RecordedStudent />}></Route>
        <Route path="/bulkAddStudentsToBatch" element={<BulkAddToBatch />}></Route>
      </Routes>
    </>
  );
}

export default App;
