import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  InputGroup,
  FormControl,
  Card,
  Container,
  Dropdown,
  Row,
  Modal,
  Col,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faFolder } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../contexts/authContext";

import Sidebar from "../../Common/Sidebar/SideBar";
import { toast } from "react-toastify";
import NavBar from "../../Common/Navbar/NavBar";
import useApi from "../../hooks/useApi";
import { endpoints } from "../../defaults";
import logoImage from "../../images/logo1.png";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import './BatchVideoFolder.css'
import Trail from "../../Common/Breadcrumb/Trail";


const BatchVideoFolder = () => {
  const { request: getStudentsList } = useApi("get");
  const [Bdata, setBData] = useState([]);
  const navigate = useNavigate();
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const BatchId = queryParams.get("id");
  const { request: getVideos } = useApi("get");
  const { request: deleteVideo } = useApi("delete");
  const { request: addVideo } = useApi("post");
  const { request: editVideo } = useApi("put");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [VideoToDelete, setVideoToDelete] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [show, setShow] = useState(false);
  const [editedVideoId, setEditedVideoId] = useState(null);
  const [editedVideo, setEditedVideo] = useState({
    name: ""
  });
  const [newVideo, setNewVideo] = useState({
    name: "",
  });
  const [btnDisabled,setBtnDisabled]=useState({add:false,delete:false})
  const heading = "Luminar Technolab";
  
  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        student: { title: "Students", link: "course/batches/students/", icon: "users" },
        Overview: { title: "Overview", link: "course/batches/overview/", icon: "eye" },
        video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
        liveclasses: { title: "Live Classes", link: "course/batches/liveclasses/", icon: "chalkboard-teacher" },
        markAttendance: { title: "Mark Attendance", link: "course/batches/studentAttendance/", icon: "user-check" },
        viewAttendance: { title: "View Attendance", link: "course/batches/studentViewAttendance/", icon: "list-alt" },
        batchShift: {title: "Batch Changed Students", link:"batchShiftedList", icon: "exchange-alt"},
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={BatchId} />;
    } else {
      const navObj = {
        student: {
          title: "Students",
          link: "course/batches/students/",
          icon: "users",
        },
        Overview: {
          title: "Overview",
          link: "course/batches/overview/",
          icon: "eye",
        },
        video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
        liveclasses: {
          title: "Live Classes",
          link: "course/batches/liveclasses/",
          icon: "chalkboard-teacher",
        },
        markAttendance: {
          title: "Mark Attendance",
          link: "course/batches/studentAttendance/",
          icon: "user-check",
        },
        viewAttendance: {
          title: "View Attendance",
          link: "course/batches/studentViewAttendance/",
          icon: "list-alt",
        },
        bulkAdd:{
          title: "Bulk Add Students",
          link:"bulkAddStudentsToBatch",
          icon:"fa fa-users",
        },
        batchShift: {
          title: "Batch Changed Students",
          link: "batchShiftedList",
          icon: "exchange-alt",
        },
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={BatchId} />;
    }
  };


  const fetchBreadcrumbs=()=>{
    const itemObj={
      dashBoard: { title: "Courses", link: "" },
      batches: { title: "Batches", link: `course/batches/?id=${Bdata.course}` }, 
      student: {title: "Videos",link: `course/batches/videos/?id=${BatchId}`,
        
      },
    }
    return <Trail itemObj={itemObj} />
    }

    const fetchBatches = async () => {
      setLoading(true);
      const URI = `${endpoints.FETCH_BATCH}${BatchId}/`;
      try {
        const apiResponse = await getStudentsList(URI);
        const { response, error } = apiResponse;
        if (!error && response.data) {
          const { data: apiData } = response;
          setBData(apiData.data);
        } else {
          const { response: errRes } = error;
          toast.error(
            errRes?.data?.message || "Error Occurred. Please contact Admin !!"
          );
        }
      } catch (error) {
        console.error("Error occurred during fetchBatches:", error);
        toast.error("An error occurred. Please try again or contact admin.");
      }
      setLoading(false);
    };
  
  


  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const year = dateObject.getFullYear();

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    if (BatchId) {
      fetchVideos();
      fetchBatches()
    }
  }, [BatchId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedVideo((preVideo) => ({
      ...preVideo,
      [name]: value,
    }));
  };

  const handleAddVideo = async () => {
    setBtnDisabled({...btnDisabled,add:true})
    try {
      const url = `${endpoints.ADD_VIDEO_FOLDER}${BatchId}`;
      const apiResponse = await addVideo(url, newVideo);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Folder added successfully");
        fetchVideos();
        setShow(false);
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened. Please contact admin.");
        }
      }
    } catch (error) {
      console.error("Error occurred during Folder addition:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    } finally{
      setBtnDisabled({...btnDisabled,add:false})

    }
  };

  const handleEditClick = (Video) => {
    setEditedVideo({
      name: Video.name,
    });
    setEditedVideoId(Video.id);
    setShow(true);
  };

  const clearModal = () => {
    setEditedVideoId(null);
    setEditedVideo({
      title: "",
    });
    setNewVideo({
      title: "",
    });
    setShow(false);
  };

  const handleSaveChanges = async () => {
    setBtnDisabled({...btnDisabled,add:true})
    if (!BatchId) {
      console.error("Edit ID is missing.");
      return;
    }

    try {
      let apiResponse;
      const url = `${endpoints.UPDATE_FOLDER}${editedVideoId}/`;
      apiResponse = await editVideo(url, editedVideo);

      const { response, error } = apiResponse;
      if (!error && response.data) {
        toast.success("video updated successfully");

        fetchVideos();
        clearModal();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }

      setShow(false);
    } catch (error) {
      console.error("Error occurred during Demo Video update:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    } finally{
      setBtnDisabled({...btnDisabled,add:false})
    }
  };

  const fetchVideos = async () => {
    if (!BatchId) {
      return; // return early if BatchId is null
    }

    setLoading(true);
    const url = `${endpoints.VIEW_VIDEO_FOLDER}${BatchId}/`;
    const apiResponse = await getVideos(url);
    const { response, error } = apiResponse;

    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }

    setLoading(false);
  };

  const dateConveter = (date) => {
    const originalDate = new Date(date);

    const formattedDate = originalDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  const handleConfirmation = async (confirmed) => {
    setBtnDisabled({...btnDisabled,delete:true})

    setShowDeleteConfirmation(false);

    if (confirmed && VideoToDelete) {
      await handleDelete(VideoToDelete.id);
    }

    setVideoToDelete(null);
  };

  const handleDelete = async (VideoId) => {
    try {
      const uri = `${endpoints.DELETE_FOLDER}${VideoId}/`;
      const apiResponse = await deleteVideo(uri);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Video Folder successfully deleted!");
        await fetchVideos();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    } finally{
      setBtnDisabled({...btnDisabled,delete:false})
    }
  };

  const deleteConfirm = (video) => {
    const CustomConfirmationToast = () => (
      <div className="text-center">
        <div className="mb-3">
          Are you sure you want to delete {video.title}
        </div>
        <Button
          variant="outline-dark logout-btn me-2"
          onClick={() => handleConfirmation(true)}
          disabled={btnDisabled.delete}
        >
          Yes
        </Button>
        <Button
          variant="outline-dark logout-btn"
          onClick={() => handleConfirmation(false)}
        >
          No
        </Button>
      </div>
    );

    const existingToast = toast.isActive("custom-confirmation-toast");

    if (!existingToast) {
      toast.warning(<CustomConfirmationToast />, {
        toastId: "custom-confirmation-toast",
        position: "top-center",
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleDeleteclick = (video) => {
    setVideoToDelete(video);
    setShowDeleteConfirmation(true);
  };

  const ShowVideoPage = (id) => {
    navigate(`videosList/?id=${id}`);
  };

  return (
    <Container fluid className="p-0">

      <div className="m-0 main-grid">
      <div className="p-0  d-none  d-md-block">{fetchNavObj()}</div>{" "}
        {/*For larger screen (>=md) */}
        <div className=" m-0 p-0 ">

          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>
          <Container className="content-margin">
          <div className="">
          {fetchBreadcrumbs()}
        </div> 
            <>
             
              <div  className="text-end">
                <Button
                  className="fw-bold ms-5 mt-2 button"
                  onClick={() => {
                    clearModal();
                    handleShow();
                  }}
                >
                  <FontAwesomeIcon icon={faAdd} /> {" "}
                  Add Folder
                </Button>
              </div>
            </>
            <Row>
              {data.map((card) => (
                <Col key={card.id} xs={6} sm={6} md={3}>
                  <Card className="my-4">
                    <Dropdown className="card-menu bg-info d-flex justify-content-end">
                      <Dropdown.Toggle
                        variant="link"
                        // id={`dropdown-menu-${course.id}`}
                        className="card-3dot fs-5 text-secondary custom-dropdown-toggle"
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          key={1}
                          onClick={() => ShowVideoPage(card.id)}
                        >
                          Add Video
                        </Dropdown.Item>
                        <Dropdown.Item
                          key={2}
                          onClick={() => handleEditClick(card)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          key={3}
                          onClick={() => handleDeleteclick(card)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Card.Body
                      className="text-center"
                      onClick={() => ShowVideoPage(card.id)}
                    >
                      <FontAwesomeIcon
                        icon={faFolder}
                        size="5x"
                        className="mb-1 icons3"
                      // onClick={ShowVideoPage(card.id)}
                      />
                      <Card.Title>{card.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
      {showDeleteConfirmation && (
        <div className="custom-confirmation-modal">
          {deleteConfirm(VideoToDelete)}
        </div>
      )}

      <Modal show={show} onHide={handleClose} backdropClassName="modal-backdrop">
        <Modal.Header closeButton>
          <Modal.Title>
            {editedVideoId ? "Edit Folder" : "Add Folder"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formVideoTitle">
              <Form.Control
                type="text"
                placeholder="Enter Folder Name"
                name="name"
                value={editedVideo.name ? editedVideo.name : newVideo.name}
                onChange={
                  editedVideoId
                    ? handleInputChange
                    : (e) =>
                      setNewVideo({
                        ...newVideo,
                        name: e.target.value,
                      })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary button-clr" onClick={handleClose}>
            Close
          </Button>
          <Button
            className="button"
            onClick={editedVideoId ? handleSaveChanges : handleAddVideo}
            disabled={btnDisabled.add}
          >
            {editedVideoId ? "Save Changes" : "Add Folder"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default BatchVideoFolder;
