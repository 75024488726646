import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  InputGroup,
  FormControl,
  Card,
  Container,
  Row,
  Col,
  Dropdown,
  Modal,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck, faHandBackFist, faHandPointUp, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../Common/Sidebar/SideBar";
import NavBar from "../../Common/Navbar/NavBar";
import logoImage from "../../images/logo1.png";
import { toast } from "react-toastify";
import zoom from "../../images/zoom.png";
import CustomSpinner from "../../Common/Spinner/spinner";
import { useLocation } from "react-router-dom";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import useApi from "../../hooks/useApi";
import { endpoints } from "../../defaults";
import "./BatchLiveClasses.css";
import AuthContext from "../../contexts/authContext";
import Trail from "../../Common/Breadcrumb/Trail";

const BatchLiveClasses = () => {
  const [Bdata, setBData] = useState([]);
  const { request: getStudentsList } = useApi("get");
  const { search } = useLocation();
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const queryParams = new URLSearchParams(search);
  const BatchId = queryParams.get("id");
  const [loading, setLoading] = useState(false);
  const { request: getBatchLiveClass } = useApi("get");
  const { request: deleteLiveClass } = useApi("delete");
  const { request: updateLiveClass } = useApi("put");
  const { request: addLiveClass } = useApi("post");
  const { request: getBatchFacultys } = useApi("get");
  const [fdata, setfData] = useState([]);
  console.log(fdata, "fdata");
  const [data, setData] = useState([]);
  console.log(data, "data");
  const [LiveClassToDelete, setLiveClassToDelete] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const [editedLiveClassId, seteditedLiveClassId] = useState(null);
  const [ActiveStatus, setActiveStatus] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState({ add: false, delete: false });

  const heading = "Luminar Technolab";
  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        student: { title: "Students", link: "course/batches/students/", icon: "users" },
        Overview: { title: "Overview", link: "course/batches/overview/", icon: "eye" },
        video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
        liveclasses: { title: "Live Classes", link: "course/batches/liveclasses/", icon: "chalkboard-teacher" },
        markAttendance: { title: "Mark Attendance", link: "course/batches/studentAttendance/", icon: "user-check" },
        viewAttendance: { title: "View Attendance", link: "course/batches/studentViewAttendance/", icon: "list-alt" },
        batchShift: {title: "Batch Changed Students", link:"batchShiftedList", icon: "exchange-alt"},
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={BatchId} />;
    } else {
      const navObj = {
        student: {
          title: "Students",
          link: "course/batches/students/",
          icon: "users",
        },
        Overview: {
          title: "Overview",
          link: "course/batches/overview/",
          icon: "eye",
        },
        video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
        liveclasses: {
          title: "Live Classes",
          link: "course/batches/liveclasses/",
          icon: "chalkboard-teacher",
        },
        markAttendance: {
          title: "Mark Attendance",
          link: "course/batches/studentAttendance/",
          icon: "user-check",
        },
        viewAttendance: {
          title: "View Attendance",
          link: "course/batches/studentViewAttendance/",
          icon: "list-alt",
        },
        bulkAdd:{
          title: "Bulk Add Students",
          link:"bulkAddStudentsToBatch",
          icon:"fa fa-users",
        },
        batchShift: {
          title: "Batch Changed Students",
          link: "batchShiftedList",
          icon: "exchange-alt",
        },
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={BatchId} />;
    }
  };


  const fetchBreadcrumbs=()=>{
    const itemObj={
      dashBoard: { title: "Courses", link: "" },
      batches: { title: "Batches", link: `course/batches/?id=${Bdata.course}` }, 
      student: {title: "Live Classes",link: `course/batches/liveclasses/?id=${BatchId}`,
        
      },
    }
    return <Trail itemObj={itemObj} />
    }
    console.log(Bdata);


    const fetchBatches = async () => {
      setLoading(true);
      const URI = `${endpoints.FETCH_BATCH}${BatchId}/`;
      try {
        const apiResponse = await getStudentsList(URI);
        const { response, error } = apiResponse;
        if (!error && response.data) {
          const { data: apiData } = response;
          setBData(apiData.data);
        } else {
          const { response: errRes } = error;
          toast.error(
            errRes?.data?.message || "Error Occurred. Please contact Admin !!"
          );
        }
      } catch (error) {
        console.error("Error occurred during fetchBatches:", error);
        toast.error("An error occurred. Please try again or contact admin.");
      }
      setLoading(false);
    };


  useEffect(() => {
    fetchBatches()
    fetchLiveClass();
    fetchFacultys();
  }, [BatchId]);

  const [newLiveClass, setnewLiveClass] = useState({
    class_name: "",
    trainer_name: "",
    status: ActiveStatus,
    time: "",
    url_link: "",
  });

  const handleAddLiveClassClick = () => {
    // setnewLiveClass({
    //   class_name: "",
    //   trainer_name: "",
    //   status: ActiveStatus,
    //   time: "",
    //   url_link: "",
    // });
    // clearDom();
    setShow(true);
  };

  const fetchFacultys = async () => {
    setLoading(true);
    const url = `${endpoints.FETCH_BATCH_FACULTYS}${BatchId}/`;
    const apiResponse = await getBatchFacultys(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setfData(apiData);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const clearDom = () => {
    document.getElementById("formLiveClassName").value = "";
    document.getElementById("formLiveClassTrainerName").value = "";
    // document.getElementById("status").checked = ActiveStatus;
    document.getElementById("formLiveClassTime").value = "";
    document.getElementById("formLiveClassURL").value = "";
  };

  const clearModal = () => {
    clearDom();

    // seteditedLiveClassId({
    //   class_name: "",
    //   trainer_name: "",
    //   status: ActiveStatus,
    //   time: "",
    //   url_link: "",
    // });
    seteditedLiveClassId(null);
    // setnewLiveClass({
    //   class_name: "",
    //   trainer_name: "",
    //   status: ActiveStatus,
    //   time: "",
    //   url_link: "",
    // });

    setShow(false);
  };

  const [editedLiveClass, seteditedLiveClass] = useState({
    class_name: "",
    trainer_name: "",
    status: ActiveStatus,
    time: "",
    url_link: "",
  });

  const handleEditClick = (liveClass) => {
    seteditedLiveClass({
      class_name: liveClass.class_name,
      trainer_name: liveClass.trainer_name,
      status: liveClass.status,
      time: liveClass.time,
      url_link: liveClass.url_link,
    });
    setActiveStatus(liveClass.status);
    seteditedLiveClassId(liveClass.id);
    setShow(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    seteditedLiveClass((prevLiveVideo) => ({
      ...prevLiveVideo,
      [name]: value,
    }));
  };
  const handleClose = () => {
    clearModal();
    // setShow(false);
  };

  const handleSaveChanges = async () => {
    if (!editedLiveClassId) {
      console.error("live class ID is missing.");
      return;
    }

    try {
      console.log(editedLiveClass);
      let apiResponse;
      const url = `${endpoints.UPDATE_LIVE_CLASS}${editedLiveClassId}/`;
      apiResponse = await updateLiveClass(url, editedLiveClass);

      const { response, error } = apiResponse;
      if (!error && response.data) {
        toast.success("live class link updated successfully");

        fetchLiveClass();
        clearModal();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }

      setShow(false);
    } catch (error) {
      console.error("Error occurred during Demo Video update:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
  };

  const handleAddLiveClass = async () => {
    setBtnDisabled({ ...btnDisabled, add: true });
    try {
      const url = `${endpoints.ADD_LIVE_CLASS}${BatchId}/`;
      const apiResponse = await addLiveClass(url, newLiveClass);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("live class link added successfully");
        fetchLiveClass();
        setShow(false);
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened. Please contact admin.");
        }
      }
    } catch (error) {
      console.error("Error occurred during Demo video addition:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    } finally {
      setBtnDisabled({ ...btnDisabled, add: false });
    }
  };

  const handleDelete = async (liveClassId) => {
    try {
      const uri = `${endpoints.DELETE_LIVE_CLASS}${liveClassId}/`;
      const apiResponse = await deleteLiveClass(uri);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("live class link successfully deleted!");
        fetchLiveClass();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    } finally {
      setBtnDisabled({ ...btnDisabled, delete: false });
    }
  };

  const handleConfirmation = async (confirmed) => {
    setBtnDisabled({ ...btnDisabled, delete: true });
    setShowDeleteConfirmation(false);

    if (confirmed && LiveClassToDelete) {
      await handleDelete(LiveClassToDelete.id);
    }

    setLiveClassToDelete(null);
  };

  const handleDeleteclick = (liveClass) => {
    setLiveClassToDelete(liveClass);
    setShowDeleteConfirmation(true);
  };

  const deleteConfirm = (liveClass) => {
    const CustomConfirmationToast = () => (
      <div className="text-center">
        <div className="mb-3">
          Are you sure you want to delete {liveClass.class_name}
        </div>
        <Button
          variant="outline-dark logout-btn me-2"
          onClick={() => handleConfirmation(true)}
          disabled={btnDisabled.delete}
        >
          Yes
        </Button>
        <Button
          variant="outline-dark logout-btn"
          onClick={() => handleConfirmation(false)}
        >
          No
        </Button>
      </div>
    );
    const existingToast = toast.isActive("custom-confirmation-toast");

    if (!existingToast) {
      toast.warning(<CustomConfirmationToast />, {
        toastId: "custom-confirmation-toast",
        position: "top-center",
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const fetchLiveClass = async () => {
    setLoading(true);
    const url = `${endpoints.FETCH_BATCH_LIVE_CLASS}${BatchId}/`;
    const apiResponse = await getBatchLiveClass(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const liveClassCard = (liveClass) => {
    return (
      <Col md={6} className=" p-3">
        <div className="live-class p-2">
              <Dropdown className="card-menu  d-flex justify-content-end">
                <Dropdown.Toggle
                  variant="link"
                  className=" card-3dot fs-5 text-secondary custom-dropdown-toggle"
                >
                  <FontAwesomeIcon icon={faEllipsisV} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="drop-down-menu"
                    key={1}
                    onClick={() => handleEditClick(liveClass)}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="drop-down-menu"
                    key={2}
                    onClick={() => handleDeleteclick(liveClass)}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
          <Row>
      
            <Col lg={6} md={12}>
             <div className=""> <img className="w-100 ms-2" src={zoom} alt="zoomLogo"></img></div>
            </Col>
            <Col lg={6} md={12} className="ps-3 pt-5" >
            
             
              <>
                <h4 className="fw-bold p-0">{liveClass.class_name}</h4>
                <p className="m-0 p-0 mb-1">
                Trainer :{" "}
                <span className="fw-bold fs-5">{liveClass.trainer_name}</span>
              </p>
              <div className="text-end me-5 mt-3">
                <a
                  style={{ textDecoration: "none" }}
                  href={liveClass.url_link}
                  className="p-2 button"
                  // target="_blank"
                >
                  Join Now {" "}

                </a>
              </div>
              </>
            </Col>
          </Row>
        
          <hr />
          <Row>
            <Col md={6} className="text-center">
              Time : <span className="fw-bold">{liveClass.time}</span>
            </Col>
            <Col md={6} className="text-center">
              {" "}
              Status :{" "}
              <span className="fw-bold">
                {liveClass.status ? "active" : "inactive"}
              </span>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  return (
    <Container fluid className="p-0">
      <div className="m-0 main-grid">
      <div className="p-0  d-none  d-md-block">{fetchNavObj()}</div>{" "}
        {/*For larger screen (>=md) */}
        <div className=" m-0 p-0 ">

          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>
          <Container className="content-margin">
          <div className="mt-3">
          {fetchBreadcrumbs()}
        </div> 
            <div className="text-end">
             
              <>
                <Button className="button" onClick={handleAddLiveClassClick}>
                  Add Live Class Link
                </Button>
              </>
            </div>
            <Row>
              {data ? data.map((liveClass) => liveClassCard(liveClass)) : ""}
            </Row>
          </Container>
        </div>
      </div>
      {showDeleteConfirmation && (
        <div className="custom-confirmation-modal">
          {deleteConfirm(LiveClassToDelete)}
        </div>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editedLiveClassId ? "Edit Live Class" : "Add Live Class"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formLiveClassName">
              <Form.Label>Class Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Class Name"
                name="class_name"
                value={
                  editedLiveClassId
                    ? editedLiveClass.class_name
                    : newLiveClass.class_name
                }
                onChange={
                  editedLiveClassId
                    ? handleInputChange
                    : (e) =>
                        setnewLiveClass({
                          ...newLiveClass,
                          class_name: e.target.value,
                        })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLiveClassTrainerName">
              <Form.Label>Trainer Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Trainer Name"
                name="trainer_name"
                value={
                  editedLiveClassId
                    ? editedLiveClass.trainer_name
                    : newLiveClass.trainer_name
                }
                onChange={
                  editedLiveClassId
                    ? handleInputChange
                    : (e) =>
                        setnewLiveClass({
                          ...newLiveClass,
                          trainer_name: e.target.value,
                        })
                }
              />
              {/* <option value="">Select Trainer</option> */}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLiveClassTime">
              <Form.Label>Class Time:</Form.Label>
              <Form.Control
                type="time"
                placeholder="Enter Class Time"
                name="time"
                value={
                  editedLiveClassId ? editedLiveClass.time : newLiveClass.time
                }
                onChange={
                  editedLiveClassId
                    ? handleInputChange
                    : (e) =>
                        setnewLiveClass({
                          ...newLiveClass,
                          time: e.target.value,
                        })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLiveClassStatus">
              <Form.Label>Status:</Form.Label>
              <Form.Check
                type="switch"
                id="statusSwitch"
                className="fs-3"
                onChange={() => {
                  setActiveStatus(!ActiveStatus);
                  editedLiveClassId
                    ? seteditedLiveClass({
                        ...editedLiveClass,
                        status: ActiveStatus,
                      })
                    : setnewLiveClass({
                        ...newLiveClass,
                        status: ActiveStatus,
                      });
                }}
                checked={
                  editedLiveClassId ? editedLiveClass.status : ActiveStatus
                }
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLiveClassURL">
              <Form.Label>Live Class URL:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Live Class URL"
                name="url_link"
                value={
                  editedLiveClassId
                    ? editedLiveClass.url_link
                    : newLiveClass.url_link
                }
                onChange={
                  editedLiveClassId
                    ? handleInputChange
                    : (e) =>
                        setnewLiveClass({
                          ...newLiveClass,
                          url_link: e.target.value,
                        })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary button-clr" onClick={handleClose}>
            Close
          </Button>
          <Button
            className="button"
            disabled={btnDisabled.add}
            onClick={editedLiveClassId ? handleSaveChanges : handleAddLiveClass}
          >
            {editedLiveClassId ? "Save Changes" : "Add Live Class"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default BatchLiveClasses;
