import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import NavBar from "../../Common/Navbar/NavBar";
import Sidebar from "../../Common/Sidebar/SideBar";
import logoImage from "../../images/logo1.png";
import { useLocation } from "react-router-dom";
import "./demoVideo.css";
import { faAdd, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from "../../hooks/useApi";
import { endpoints } from "../../defaults";
import CustomSpinner from "../../Common/Spinner/spinner";
import imgURL from "../../images/imgLink";
import ReactPlayer from "react-player";
import AuthContext from "../../contexts/authContext";
import Trail from "../../Common/Breadcrumb/Trail";


const DemoVideo = () => {
  const { search } = useLocation();
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const queryParams = new URLSearchParams(search);
  const CourseId = queryParams.get("id");
  const { request: getCourse } = useApi("get");
  const { request: getDemovideo } = useApi("get");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [ddata, setdData] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [demoVideoToDelete, setDemoVideoToDelete] = useState(null);
  const { request: deleteDemoVideo } = useApi("delete");
  const { request: updateDemoVideo } = useApi("put");
  const { request: addDemoVideo } = useApi("post");
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const [editedDemovideoId, seteditedDemovideoId] = useState(null);
  const heading = "Course Demo Videos";

  const navObj = {
    batches: { title: "Batches", link: "course/batches" },
    overview: { title: "Overview", link: "course/overview" },
    demoVideo: { title: "DemoVideo", link: "course/demovideo/" },
    addBatch: { title: "Add Batch", link: "course/addBatch/" },
  };

  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        batches: {
          title: "Batches",
          link: "course/batches/",
          icon: "graduation-cap",
        },
        overview: { title: "Overview", link: "course/overview/", icon: "eye" },
        demoVideo: {
          title: "DemoVideo",
          link: "course/demovideo/",
          icon: "video",
        },
      };
      return <Sidebar navObj={navObj} itemObj={CourseId} />;
    } else {
      const navObj = {
        batches: {
          title: "Batches",
          link: "course/batches/",
          icon: "graduation-cap",
        },
        overview: { title: "Overview", link: "course/overview/", icon: "eye" },
        demoVideo: {
          title: "DemoVideo",
          link: "course/demovideo/",
          icon: "video",
        },
        addBatch: {
          title: "Add Batch",
          link: "course/addBatch/",
          icon: "plus",
        },
      };
      return <Sidebar navObj={navObj} itemObj={CourseId} />;
    }
  };

  const fetchBreadcrumbs=()=>{
    const itemObj={
      dashBoard: { title: "Courses", link: "" },
      batches: { title: "Demo video", link: `course/demovideo/?id=${data.id}` }, 
        
      
    }
    return <Trail itemObj={itemObj} />
    }

  useEffect(() => {
    fetchCourse();
    fetchDemoVideo();
  }, [CourseId]);

  const [newDemoVideo, setnewDemoVideo] = useState({
    tittle: "",
    url: "",
    description: "",
  });

  const handleAddDemoVideoClick = () => {
    setnewDemoVideo({
      tittle: "",
      url: "",
      description: "",
    });
    setShow(true);
  };

  const handleAddDemoVideo = async () => {
    try {
      const url = `${endpoints.ADD_DEMO_VIDEO}${CourseId}/`;
      const apiResponse = await addDemoVideo(url, newDemoVideo);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Demo Video added successfully");
        fetchDemoVideo();
        setShow(false);
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened. Please contact admin.");
        }
      }
    } catch (error) {
      console.error("Error occurred during Demo video addition:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
  };

  const clearModal = () => {
    clearDom();
    seteditedDemovideoId({
      tittle: "",
      url: "",
      description: "",
    });
    seteditedDemovideoId(null);
    setnewDemoVideo({
      tittle: "",
      url: "",
      description: "",
    });
    setShow(false);
  };

  const clearDom = () => {
    document.getElementById("formDemoVideoTitle").value = "";
    document.getElementById("formDemoVideoURL").value = "";
    document.getElementById("formDemoVideoDescription").value = "";
  };

  const [editedDemoVideo, setEditedDemoVideo] = useState({
    tittle: "",
    url: "",
    description: "",
  });

  const handleEditClick = (DemoVideo) => {
    setEditedDemoVideo({
      tittle: DemoVideo.tittle,
      url: DemoVideo.url,
      description: DemoVideo.description,
    });
    seteditedDemovideoId(DemoVideo.id);
    setShow(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDemoVideo((prevDemoVideo) => ({
      ...prevDemoVideo,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    if (!editedDemovideoId) {
      console.error("Module ID is missing.");
      return;
    }

    try {
      let apiResponse;
      const url = `${endpoints.UPDATE_DEMO_VIDEO}/${editedDemovideoId}/`;
      apiResponse = await updateDemoVideo(url, editedDemoVideo);

      const { response, error } = apiResponse;
      if (!error && response.data) {
        toast.success("Demo video updated successfully");

        fetchDemoVideo();
        clearModal();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }

      setShow(false);
    } catch (error) {
      console.error("Error occurred during Demo Video update:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
  };

  const handleClose = () => {
    clearModal();
  };

  const handleDeleteclick = (demovideo) => {
    setDemoVideoToDelete(demovideo);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmation = async (confirmed) => {
    setShowDeleteConfirmation(false);

    if (confirmed && demoVideoToDelete) {
      await handleDelete(demoVideoToDelete.id);
    }

    setDemoVideoToDelete(null);
  };

  const deleteConfirm = (demovideo) => {
    const CustomConfirmationToast = () => (
      <div className="text-center">
        <div className="mb-3">
          Are you sure you want to delete {demovideo.tittle}
        </div>
        <Button
          variant="outline-dark logout-btn me-2"
          onClick={() => handleConfirmation(true)}
        >
          Yes
        </Button>
        <Button
          variant="outline-dark logout-btn"
          onClick={() => handleConfirmation(false)}
        >
          No
        </Button>
      </div>
    );

    const existingToast = toast.isActive("custom-confirmation-toast");

    if (!existingToast) {
      toast.warning(<CustomConfirmationToast />, {
        toastId: "custom-confirmation-toast",
        position: "top-center",
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleDelete = async (demoVideoId) => {
    try {
      const uri = `${endpoints.DELETE_DEMO_VIDEO}/${demoVideoId}/`;
      const apiResponse = await deleteDemoVideo(uri);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Demo Video successfully deleted!");
        await fetchDemoVideo();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    }
  };

  const dateConveter = (date) => {
    const originalDate = new Date(date);

    const formattedDate = originalDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  const img = `${imgURL}${data.thumbnail}`;

  const fetchCourse = async () => {
    setLoading(true);
    const url = `${endpoints.VIEW_COURSE_DETAILS}${CourseId}`;
    const apiResponse = await getCourse(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };
  const fetchDemoVideo = async () => {
    setLoading(true);
    const url = `${endpoints.FECH_DEMO_VIDEO_LIST}${CourseId}`;
    const apiResponse = await getDemovideo(url);
    const { response, error } = apiResponse;

    if (!error && response && response.data) {
      const { data: apiData } = response;
      setdData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }

    setLoading(false);
  };

  const demoVideoCard = (demovideo) => {
    return (
      <Col lg={4} md={6} sm={12} xs={12} key={demovideo.id} className="my-2 ">
        <div className="border border-2  rounded-2 shadow">
          <Row>
            <Col md={10}>
             
            </Col>
            <Col md={2}>
              <Dropdown className="card-menu mb-2 m-1   bg-info d-flex justify-content-end">
                <Dropdown.Toggle
                  variant="link"
                  className="card-3dot fs-5 text-secondary custom-dropdown-toggle m-0 p-0"
                >
                  <FontAwesomeIcon icon={faEllipsisV} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    key={1}
                    onClick={() => handleEditClick(demovideo)}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    key={2}
                    onClick={() => handleDeleteclick(demovideo)}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <div className="video-screen mt-2">
            <ReactPlayer
              className="video w-100 mt-4"
              height={"13rem"}
              width={"100%"}
              url={demovideo.url}
            />
          </div>
        <div className="mt-3 ps-3">
                <h4 className="fw-bold clr">{demovideo.tittle}</h4>
        </div>
          <div className="scrollable-content demo-video-content  ">
            <p className="demo-description ps-3 pe-3 ">
              {demovideo.description}
            </p>
          </div>
          <hr />
          
          <div className="d-flex justify-content-between ">
            <div className="d-flex">
              <p className=" mt-2 mx-3">
                <span className="m-0 ">Update Date : </span>
                <b>{dateConveter(demovideo.updated_date)}</b>
              </p>
              <p className=" mt-2 mx-2">
                <span className="m-0 ">Update :</span>
               <b> {demovideo.updated_by}</b>
              </p>
            </div>
          </div>
        </div>
      </Col>
    );
  };

  const renderAddVideoBtn = () => {
    if (user && user.user_type === "faculty") {
      return null;
    } else {
      return (
        <Button className="button border-0 me-4" onClick={handleAddDemoVideoClick}>
          {" "}
          <FontAwesomeIcon icon={faAdd} /> Add Demo Video
        </Button>
      );
    }
  };

  return (
    <Container fluid className="p-0">
      <div className="main-grid m-0">
        <div className="p-0 m-0 d-none  d-md-block">{fetchNavObj()}</div>{" "}
        {/*For larger screen (>=md) */}
        <div className="m-0 p-0">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>
          <Container >
          <div className="mt-4 mb-4">
          {fetchBreadcrumbs()}
        </div> 
            <div >
              {loading ? (
                <CustomSpinner />
              ) : (
                <div className="demo-body mb-3 m-2">
                  <Row className="demo-video-head">
                    <Col lg={2} md={3} className="d-flex justify-content-center align-item-center">
                      <img className=" thumbnail_ w-100" src={data.thumbnail} alt="" />
                    </Col>
                    <Col lg={10} md={9}>
                      <h1 className="mt-4 p-1 fw-bold full-name">{data.full_name}</h1>
                    </Col>
                  </Row>
                  <div className="text-end me-2 mt-3" md={2}>
                    {renderAddVideoBtn()}
                  </div>
                  <Row className=" m-3">
                    {ddata
                      ? ddata.map((demovideo) => demoVideoCard(demovideo))
                      : ""}
                  </Row>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
      {showDeleteConfirmation && (
        <div className="custom-confirmation-modal">
          {deleteConfirm(demoVideoToDelete)}
        </div>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editedDemovideoId ? "Edit Demo Video" : "Add Demo Video"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formDemoVideoTitle">
              <Form.Label>Demo Video Title:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Demo Video Title"
                name="tittle"
                value={
                  editedDemovideoId
                    ? editedDemoVideo.tittle
                    : newDemoVideo.tittle
                }
                onChange={
                  editedDemovideoId
                    ? handleInputChange
                    : (e) =>
                        setnewDemoVideo({
                          ...newDemoVideo,
                          tittle: e.target.value,
                        })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDemoVideoURL">
              <Form.Label>Demo Video URL:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Demo Video URL"
                name="url"
                value={
                  editedDemovideoId ? editedDemoVideo.url : newDemoVideo.url
                }
                onChange={
                  editedDemovideoId
                    ? handleInputChange
                    : (e) =>
                        setnewDemoVideo({
                          ...newDemoVideo,
                          url: e.target.value,
                        })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDemoVideoDescription">
              <Form.Label>Demo Video Description:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Demo Video Description"
                name="description"
                value={
                  editedDemovideoId
                    ? editedDemoVideo.description
                    : newDemoVideo.description
                }
                onChange={
                  editedDemovideoId
                    ? handleInputChange
                    : (e) =>
                        setnewDemoVideo({
                          ...newDemoVideo,
                          description: e.target.value,
                        })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary button-clr" onClick={handleClose}>
            Close
          </Button>
          <Button
            className="button"
            onClick={editedDemovideoId ? handleSaveChanges : handleAddDemoVideo}
          >
            {editedDemovideoId ? "Save Changes" : "Add Demo Video"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DemoVideo;
