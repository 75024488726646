import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCalendarAlt,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../../contexts/authContext";
import { toast } from "react-toastify";
import Sidebar from "../../../Common/Sidebar/SideBar";
import NavBar from "../../../Common/Navbar/NavBar";
import useApi from "../../../hooks/useApi";
import { endpoints } from "../../../defaults";
import logoImage from "../../../images/logo1.png";
import CustomSpinner from "../../../Common/Spinner/spinner";
import "./StudentAttendance.css";
import * as XLSX from "xlsx";
import Trail from "../../../Common/Breadcrumb/Trail";

const StudentsAttendanceView = () => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isDateFPickerOpen, setIsFDatePickerOpen] = useState(false);
  const [Bdata, setBData] = useState([]);
  const [AtData, SetAtdata] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedFromDate, setselectedFromDate] = useState(new Date());
  const { search } = useLocation();
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const queryParams = new URLSearchParams(search);
  const batchId = queryParams.get("id");
  const { request: getStudentsList } = useApi("get");
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState("1");
  const [userdataLoaded, setUserdataLoaded] = useState(false);
  const heading = `${Bdata.batch_name} - Attendance `;

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        student: {
          title: "Students",
          link: "course/batches/students/",
          icon: "users",
        },
        Overview: {
          title: "Overview",
          link: "course/batches/overview/",
          icon: "eye",
        },
        video: {
          title: "Videos",
          link: "course/batches/videos/",
          icon: "video",
        },
        liveclasses: {
          title: "Live Classes",
          link: "course/batches/liveclasses/",
          icon: "chalkboard-teacher",
        },
        markAttendance: {
          title: "Mark Attendance",
          link: "course/batches/studentAttendance/",
          icon: "user-check",
        },
        viewAttendance: {
          title: "View Attendance",
          link: "course/batches/studentViewAttendance/",
          icon: "list-alt",
        },
        batchShift: {
          title: "Batch Changed Students",
          link: "batchShiftedList",
          icon: "exchange-alt",
        },
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={batchId} />;
    } else {
      const navObj = {
        student: {
          title: "Students",
          link: "course/batches/students/",
          icon: "users",
        },
        Overview: {
          title: "Overview",
          link: "course/batches/overview/",
          icon: "eye",
        },
        video: {
          title: "Videos",
          link: "course/batches/videos/",
          icon: "video",
        },
        liveclasses: {
          title: "Live Classes",
          link: "course/batches/liveclasses/",
          icon: "chalkboard-teacher",
        },
        markAttendance: {
          title: "Mark Attendance",
          link: "course/batches/studentAttendance/",
          icon: "user-check",
        },
        viewAttendance: {
          title: "View Attendance",
          link: "course/batches/studentViewAttendance/",
          icon: "list-alt",
        },
        bulkAdd: {
          title: "Bulk Add Students",
          link: "bulkAddStudentsToBatch",
          icon: "fa fa-users",
        },
        batchShift: {
          title: "Batch Changed Students",
          link: "batchShiftedList",
          icon: "exchange-alt",
        },
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={batchId} />;
    }
  };

  const fetchBreadcrumbs = () => {
    const itemObj = {
      dashBoard: { title: "Courses", link: "" },
      batches: { title: "Batches", link: `course/batches/?id=${Bdata.course}` },
      student: {
        title: "View Attendance",
        link: `course/batches/studentViewAttendance/?id=${batchId}`,
      },
    };
    return <Trail itemObj={itemObj} />;
  };

  useEffect(() => {
    fetchBatches();
  }, []);

  // useEffect(() => {
  //   if (selectedDate) {
  //     const formattedDate = formatDate(selectedDate);
  //     fetchStudentsList(formattedDate);
  //   }
  // }, [selectedDate]);

  const fetchBatches = async () => {
    setLoading(true);
    const URI = `${endpoints.FETCH_BATCH}${batchId}/`;
    try {
      const apiResponse = await getStudentsList(URI);
      const { response, error } = apiResponse;
      if (!error && response.data) {
        const { data: apiData } = response;
        setBData(apiData.data);
      } else {
        const { response: errRes } = error;
        toast.error(
          errRes?.data?.message || "Error Occurred. Please contact Admin !!"
        );
      }
    } catch (error) {
      console.error("Error occurred during fetchBatches:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
    setLoading(false);
  };

  const searchClick = () => {
    fetchStudentsList(page);
  };

  const fetchStudentsList = async () => {
    setLoading(true);
    const fDate = formatDate(selectedDate);
    const aDate = formatDate(selectedFromDate);
    const url = `${endpoints.VIEW_SORTED_ATTENDANCE}${batchId}?from_date=${aDate}&to_date=${fDate}&status=${selectedOption}&search=${searchQuery}`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      SetAtdata(apiData.data);
      setUserdataLoaded(true);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const checkDataloaded = () => {
    if (userdataLoaded) {
      return <>{AtData.map((user, index) => renderUserList(user, index))}</>;
    }
  };

  const renderUserList = (item, index) => {
    let userData = null;
    userData = item;

    const renderDetails = () => {
      let statusColor;

      switch (userData.status) {
        case "online":
          statusColor = "text-primary";
          break;
        case "absent":
          statusColor = "text-danger";
          break;
        case "recorded":
          statusColor = "text-warning";
          break;
        default:
          statusColor = "text-success";
      }

      return (
        <>
          <td className={``}>{userData.student_name}</td>
          <td className={`fw-bold ${statusColor}`}>{userData.status}</td>
        </>
      );
    };

    return (
      <tr className=" content " key={userData.id}>
        <th className="ps-5 " scope="row">
          {index + 1}
        </th>
        {renderDetails()}
      </tr>
    );
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleFDateChange = async (date) => {
    setselectedFromDate(date);
    setIsFDatePickerOpen(!isDateFPickerOpen);
  };

  const HandleClickk = async () => {
    await fetchStudentsList();
  };

  const renderFromDatePicker = () => (
    <div>
      <div className="my-1 ms-4 fw-bold">From Date</div>
      <div className=" position-relative">
        <Button
          className=" button"
          variant="common"
          onClick={() => setIsFDatePickerOpen(!isDateFPickerOpen)}
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
          {selectedFromDate.toDateString()}
        </Button>
        <div
          className={` calendar-container ${
            isDateFPickerOpen ? "visible" : "hidden"
          } `}
        >
          <DatePicker
            className=""
            selected={selectedFromDate}
            onChange={handleFDateChange}
            dateFormat="yyyy-MM-dd"
            showPopperArrow={false}
            inline
          />
        </div>
      </div>
    </div>
  );

  const renderDatePicker = () => (
    <div>
      <div className="my-1 ms-4 fw-bold">To Date</div>
      <div className=" position-relative">
        <Button
          className=" button"
          variant="common"
          onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
          {selectedDate.toDateString()}
        </Button>
        <div
          className={` calendar-container ${
            isDatePickerOpen ? "visible" : "hidden"
          } `}
        >
          <DatePicker
            className=""
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            showPopperArrow={false}
            inline
          />
        </div>
      </div>
    </div>
  );

  const renderSelectBox = () => (
    <div>
      <div className="my-1 ms-4 fw-bold">Sort Attendance</div>
      <div className=" position-relative ">
        <select
          className="form-select"
          style={{ border: "1px" }}
          value={selectedOption}
          onChange={handleSelectChange}
        >
          <option value="">All</option>
          <option value="absent">Absent</option>
          <option value="present">Present</option>
          <option value="recorded">Recording</option>
          <option value="online">Online</option>
        </select>
      </div>
    </div>
  );

  const exportToExcel = () => {
    const batchname = Bdata.batch_name;
    const worksheet = XLSX.utils.table_to_sheet(
      document.querySelector(".table")
    );
    for (let r = 1; r <= worksheet["!ref"].split(":")[1].split("$")[2]; r++) {
      for (let c = 1; c <= dates.length + 1; c++) {
        const cellRef = XLSX.utils.encode_cell({ r, c });
        const cell = worksheet[cellRef];
        const statusCell = worksheet[XLSX.utils.encode_cell({ r, c: 3 })];
        if (statusCell && statusCell.v) {
          cell.s = {
            fill: { fgColor: { rgb: getStatusColor(statusCell.v) } },
          };
        }
      }
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Attendance Data");

    const dateStr = `${formatDate(selectedFromDate)}To${formatDate(
      selectedDate
    )}`;
    const fileName = `${batchname}_Attendance_${dateStr}.xlsx`;

    XLSX.writeFile(wb, fileName);
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "present":
        return "00FF00";
      case "absent":
        return "FF0000";
      case "recording":
        return "BFBF04";
      default:
        return "0000FF";
    }
  };

  const dates = [
    ...new Set(
      AtData.flatMap((item) => item.attendance.map((entry) => entry.date))
    ),
  ].sort();

  return (
    <Container fluid className="p-0">
      <div className="m-0 main-grid">
        <div className="p-0  d-none  d-md-block">{fetchNavObj()}</div>{" "}
        {/*For larger screen (>=md) */}
        <div className=" m-0 p-0 ">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>

          <div className="container batch-list ">
            <div className="mt-3">{fetchBreadcrumbs()}</div>

            <Row className="ms-5 me-5">
              <Col
                lg={9}
                className="mt-4 align-item-center justify-content-center d-flex"
              >
                {" "}
                <InputGroup
                  className="search-bar-chil"
                  style={{ height: "30px" }}
                >
                  <FormControl
                    className="search-bar-child"
                    placeholder="Search By Student Name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && searchClick()}
                  />
                  <Button className="btn" variant="common">
                    <FontAwesomeIcon
                      icon={faSearch}
                      title="Search"
                      onClick={() => searchClick()}
                    />
                  </Button>
                </InputGroup>
              </Col>
              <Col
                lg={3}
                className="mt-4 align-item-center justify-content-center d-flex"
              ></Col>
              <Row className="mt-4">
                <Col>{renderFromDatePicker()}</Col>
                <Col>{renderDatePicker()}</Col>
                <Col>{renderSelectBox()}</Col>
                <Col>
                  <button
                    className="button mt-4 ms-4 p-2"
                    onClick={HandleClickk}
                  >
                    {" "}
                    Submit
                  </button>
                </Col>
              </Row>
            </Row>
          </div>
          <Container className=" p-3">
            {loading && (
              <div className="loader-container">
                <CustomSpinner className="inner-round" />
              </div>
            )}

            {userdataLoaded && (
              <Container fluid style={{ overflowY: "auto" }}>
                <Row className=" d-flex align-item-center justify-content-center">
                  <div className="text-end me-5">
                    <Button
                      className="button me-5"
                      variant="common"
                      onClick={exportToExcel}
                    >
                      <FontAwesomeIcon icon={faFileExport} className="" />{" "}
                      Export
                    </Button>
                  </div>
                  <div className="table-responsive table-wrapper border-start border-end">
                    <table className="text-center table table-striped table-hover custom-table table-bordered border-dark">
                      <thead className="table-header">
                        <tr>
                          <th>Name</th>
                          {dates.map((date) => (
                            <th key={date}>{date}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {AtData.map(({ name, attendance }, index) => (
                          <tr key={index}>
                            <td className="fw-bold">{name}</td>
                            {dates.map((date) => {
                              const attendanceRecord = attendance.find(
                                (entry) => entry.date === date
                              );
                              const status = attendanceRecord
                                ? attendanceRecord.status
                                : "";
                              return (
                                <td
                                  key={`${name}-${date}`}
                                  className={status.toLowerCase()}
                                >
                                  {status}
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Row>
              </Container>
            )}
          </Container>
        </div>
      </div>
    </Container>
  );
};

export default StudentsAttendanceView;
