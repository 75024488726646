import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import logoImage from "../../images/logo1.png";
import NavBar from "../../Common/Navbar/NavBar";
import Sidebar from "../../Common/Sidebar/SideBar";
import "./BatchesOnBoarding.css";
import sampleimg from "../../images/sampleimg.png";
import { endpoints } from "../../defaults";
import useApi from "../../hooks/useApi";
import imgURL from "../../images/imgLink";
import CustomSpinner from "../../Common/Spinner/spinner";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import Trail from "../../Common/Breadcrumb/Trail";

const BatchesOnBoarding = () => {
  const navigate = useNavigate();
  const [activeStatus, setActiveStatus] = useState(true);
  const [onlineStatus, setOnlineStatus] = useState(true);
  const [offlineStatus, setOfflineStatus] = useState(true);
  const [showFModal, setShowFModal] = useState(false);
  const [showCModal, setShowCModal] = useState(false);
  const [selectedFacultyNames, setSelectedFacultyNames] = useState([]);
  const [selectedCounsellorsNames, setselectedCounsellorsNames] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCounselors, setSelectedCounselors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [bdata, setBData] = useState([]);
  const { request: createCourses } = useApi("post");
  // const { request: updateCourses } = useApi("put");
  const { request: getFaculty } = useApi("get");
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const Courseid = queryParams.get("id");
  const [newBatchId, SetNewBatchId] = useState("");

  const handleShowFModal = () => {
    console.log(data);
    setShowFModal(true);
  };

  const handleShowCModal = () => {
    console.log(data);
    setShowCModal(true);
  };

  const handleCloseModal = () => {
    setShowCModal(false);
    setShowFModal(false);
  };

  const handleSelectUsers = () => {
    // Handle logic for selected users
    // You can update state or perform any other actions here
    console.log("Selected Users:", selectedUsers);
    handleCloseModal();
  };

  const [formData, setFormData] = useState({
    batch_name: "",
    course: Courseid,
    startdate: "",
    time: "",
    is_active: true,
    location: "",
  });

  const courseClear = () => {
    setFormData({
      batch_name: "",
      course: Courseid,
      startdate: "",
      time: "",
      is_active: true,
      location: "",
    });

    document.getElementById("batchName").value = "";
    document.getElementById("startdate").value = "";
    document.getElementById("time").value = "";
    document.getElementById("Location").value = "";
    document.getElementById("is_active").checked = false;
  };

  useEffect(() => {
    fetchUsersList();
  }, [Courseid]);

  const fetchUsersList = async () => {
    const url = `${endpoints.FECH_USER_LIST}/faculty/`;
    const apiResponse = await getFaculty(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
      //  setUserdataLoaded(true);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const handleSave = async () => {
    SetNewBatchId("");
    // if (
    //   !formData.batch_name ||
    //   !formData.time ||
    //   !formData.location
    // ) {
    //   toast.error("Please fill out all the fields", {});
    //   return;
    // }
    try {
      let apiResponse;

      const payload = {
        batch_name: formData.batch_name,
        course: formData.course,
        startdate: formData.startdate,
        time: formData.time,
        is_active: formData.is_active,
        location: formData.location,
        facultys: selectedUsers,
        counsellors: selectedCounselors,
      };
      const url = `${endpoints.ADD_BATCH}${Courseid}/`;
      apiResponse = await createCourses(url, payload);
      const { response, error } = apiResponse;
      if (!error && response.data) {
        console.log(response.data.data);
        const { data: apiData } = response;
        setBData(apiData.data);
        // SetNewBatchId(response.data.data.id);
        console.log("resp", setData);

        // if (bdata) {
        //   handleFacultyAdd();
        // }
        toast.success("Batch successfully created!");
        navigate(`/course/batches/?id=${Courseid}`);
        courseClear();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    }
  };

  const handleFacultyAdd = async () => {
    // if (
    //   !formData.batch_name ||
    //   !formData.time ||
    //   !formData.location
    // ) {
    //   toast.error("Please fill out all the fields", {});
    //   return;
    // }
    try {
      let apiResponse;
      const payload = {
        faculty_ids: selectedUsers,
      };
      console.log("bdatata", bdata);
      const bId = bdata.data.id;
      const url = `${endpoints.FETCH_BATCH}${bId}/add_facultys/`;
      apiResponse = await createCourses(url, payload);
      const { response, error } = apiResponse;
      if (!error && response.data) {
        console.log(response.data);
        // SetNewBatchId(response.data.data.id);
        // console.log("resp", newBatchId);
        toast.success("Batch successfully created!");

        courseClear();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    }
  };

  const navObj = {
    batches: { title: "Batches", link: "course/batches/", icon: "graduation-cap" },
    overview: { title: "Overview", link: "course/overview/", icon: "eye" },
    demoVideo: { title: "DemoVideo", link: "course/demovideo/", icon: "video" },
    addBatch: { title: "Add Batch", link: "course/addBatch/", icon: "plus" },
  };

  const handleCanle = () => {
    navigate(`/course/batches/?id=${Courseid}`);
  };

  const fetchBreadcrumbs=()=>{
    const itemObj={
      dashBoard: { title: "Courses", link: "" },
      batches: { title: "Add Batch", link: `course/addBatch/?id=${Courseid}` }, 
        
      
    }
    return <Trail itemObj={itemObj} />
    }
  

  const heading = "Luminar Technolab";
  return (
    <Container fluid className="p-0">

      <div className="m-0 main-grid">
        <div className="p-0 m-0 d-none  d-md-block">
          <Sidebar navObj={navObj} itemObj={Courseid} />
          {/*For larger screen (>=md) */}
        </div>

        <div className="m-0 p-0">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none" >
            {/*For smaller screen (<md) */}
            <Sidebar navObj={navObj} itemObj={Courseid} />
          </div>
          <Container className="course-list p-3">
          <div className="">
          {fetchBreadcrumbs()}
        </div> 
            {loading && (
              <div className="loader-container">
                <CustomSpinner className="inner-round" />
              </div>
            )}
            <Row>
              <Col>
                <div className="col-12 form-card-parent mt-2">
                  <div className="row justify-content-center">
                    <Form className="col-11 mt-2" encType="multipart/form-data">
                    <div className="head text-center m-4">  <h3 className="fw-bold">Create Batch</h3></div>
                      <Row className="my-2">
                        <Col md={12}>
                          <Form.Group controlId="batchName">
                            <Form.Label className="fw-bold">
                              Batch Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Batch name"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  batch_name: e.target.value,
                                })
                              }
                              required
                            />
                          </Form.Group>
                        </Col>
                      
                      </Row>

                      <div className="row mt-4">
                        <div className="col-6">
                          <Form.Group controlId="startdate">
                            <Form.Label className="fw-bold">
                              Start Date
                            </Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="Enter starting date "
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  startdate: e.target.value,
                                })
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="col-6">
                          <Form.Group controlId="Location">
                            <Form.Label className="fw-bold">
                              Location
                            </Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  location: e.currentTarget.value,
                                })
                              }
                            >
                              <option>Select Location</option>
                              <option value="cochin">cochin</option>
                              <option value="calicut">calicut</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                      </div>
                      
                    

                          <Row >
                          <Col lg={9} md={9} sm={12} className ="mt-4">
                          <Form.Group controlId="time">
                            <Form.Label className="fw-bold">Time</Form.Label>
                            <Form.Control
                              type="time"
                              re
                              value={formData.Time}
                              placeholder="Time"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  time: e.target.value,
                                });
                              }}
                              required
                            />
                            
                          </Form.Group>
                          </Col>

                          <Col lg={3} md={3} sm={12} className="text-center mt-4">
                          <Form.Group className="" controlId="courseStatus">
                            <Form.Label className="fw-bold">
                              Batch Status
                            </Form.Label>
                            <Form.Check
                              className="fs-3"
                              type="switch"
                              id="batchStatus"
                              onChange={() => {
                                setActiveStatus(!activeStatus);
                                setFormData({
                                  ...formData,
                                  is_active: activeStatus,
                                });
                              }}
                              checked={formData.is_active}
                              required
                            />
                          </Form.Group>
                        </Col>

                          </Row>
                        
                        <Row>
                          
                  
                            {/* Faculty Section */}
                            <Col 
                            lg={6} md={12}
                            className="text-center mt-4"
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {/* <Form.Label className="fw-bold">Faculty</Form.Label> */}
                              <div
             
                                className="mt-3  select-name form-control"
                                onClick={handleShowFModal}
                              >
                                Select Faculty
                              </div>
                            { selectedFacultyNames.length>0 && <div  className="mt-4 m-5 border_ align-item-cenetr justify-content-center">
                                {selectedFacultyNames.map((name, index) => (
                                  <div key={index} className="d-flex faculty-name selected-faculty-container">
                                    {index+1}){" "}{name}
                                  </div>
                                ))}
                              </div>}
                            </Col>

                            {/* Counsellor Section */}
                            <Col lg={6} md={12}
                             className="text-center mt-4"
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div
                                
                                onClick={handleShowCModal}
                                className="mt-3  select-name form-control"
                              >
                                Select Counsellor
                              </div>
                            { selectedCounsellorsNames.length>0 &&  <div className="mt-4 m-5 border_ align-item-cenetr justify-content-center">
                                {selectedCounsellorsNames.map((name, index) => (
                                  <div key={index}  className="d-flex faculty-name selected-faculty-container">
                                    {index+1}){" "} {name}
                                  </div>
                                ))}
                              </div>}
                            </Col>
                        </Row>
                    
                      <div className="row mt-3">
                        <div className="col-12 d-flex justify-content-end">
                          <div>
                            <Button
                             variant="outline-secondary" className="button-clr me-3"
                              
                              onClick={handleCanle}
                            >
                              Cancel
                            </Button>
                            <Button className="button" onClick={handleSave}>
                              Create
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Modal show={showFModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Faculty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data.map((user) => (
            <Form.Check
              key={user.faculty.user.id}
              type="checkbox"
              label={user.faculty.user.full_name}
              onChange={(e) => {
                const facultyId = user.faculty.id;
                const facultyName = user.faculty.user.full_name;

                if (e.target.checked) {
                  setSelectedUsers([...selectedUsers, facultyId]);
                  setSelectedFacultyNames([
                    ...selectedFacultyNames,
                    facultyName,
                  ]);
                } else {
                  setSelectedUsers(
                    selectedUsers.filter((id) => id !== facultyId)
                  );
                  setSelectedFacultyNames(
                    selectedFacultyNames.filter((name) => name !== facultyName)
                  );
                }
              }}
              checked={selectedUsers.includes(user.faculty.id)}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" className="button-clr " onClick={handleCloseModal}>
            Close
          </Button>
          <Button  className="m-3 button" onClick={handleSelectUsers}>
            Select Faculty
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Counsellor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data.map(
            (user) =>
              !selectedUsers.includes(user.faculty.id) && (
                <Form.Check
                  key={user.faculty.user.id}
                  type="checkbox"
                  label={user.faculty.user.full_name}
                  onChange={(e) => {
                    const facultyId = user.faculty.id;
                    const facultyName = user.faculty.user.full_name;
                    if (e.target.checked) {
                      setSelectedCounselors([...selectedCounselors, facultyId]);
                      setselectedCounsellorsNames([
                        ...selectedCounsellorsNames,
                        facultyName,
                      ]);
                    } else {
                      setSelectedCounselors(
                        selectedCounselors.filter((id) => id !== facultyId)
                      );
                      setselectedCounsellorsNames(
                        selectedCounsellorsNames.filter(
                          (name) => name !== facultyName
                        )
                      );
                    }
                  }}
                  checked={selectedCounselors.includes(user.faculty.id)}
                />
              )
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" className="button-clr " onClick={handleCloseModal}>
            Close
          </Button>
          <Button className="button" onClick={handleSelectUsers}>
            Select Counsellor
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default BatchesOnBoarding;
