import React, { useEffect, useState,useContext } from "react";
import { Container, Form, Row } from "react-bootstrap";
import Sidebar from "../../../Common/Sidebar/SideBar";
import NavBar from "../../../Common/Navbar/NavBar";
import logoImage from "../../../images/logo1.png";
import { useLocation } from "react-router-dom";
import { endpoints } from "../../../defaults";
import { toast } from "react-toastify";
import useApi from "../../../hooks/useApi";
import AuthContext from "../../../contexts/authContext";
import { bool } from "yup";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Trail from "../../../Common/Breadcrumb/Trail";


const Batchshift = () => {
  const [Bdata, setBData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const [searchQuery, setSearchQuery] = useState("");
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const batchId = queryParams.get("id");
  const [data, setData] = useState([]);
  const { request: getStudentsList } = useApi("get");
  const { request: videoPChange } = useApi("get");

  const heading = `Batch Shifted Student List`;
  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        student: { title: "Students", link: "course/batches/students/", icon: "users" },
        Overview: { title: "Overview", link: "course/batches/overview/", icon: "eye" },
        video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
        liveclasses: { title: "Live Classes", link: "course/batches/liveclasses/", icon: "chalkboard-teacher" },
        markAttendance: { title: "Mark Attendance", link: "course/batches/studentAttendance/", icon: "user-check" },
        viewAttendance: { title: "View Attendance", link: "course/batches/studentViewAttendance/", icon: "list-alt" },
        batchShift: {title: "Batch Changed Students", link:"batchShiftedList", icon: "exchange-alt"},
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={batchId} />;
    } else {
      const navObj = {
        student: {
          title: "Students",
          link: "course/batches/students/",
          icon: "users",
        },
        Overview: {
          title: "Overview",
          link: "course/batches/overview/",
          icon: "eye",
        },
        video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
        liveclasses: {
          title: "Live Classes",
          link: "course/batches/liveclasses/",
          icon: "chalkboard-teacher",
        },
        markAttendance: {
          title: "Mark Attendance",
          link: "course/batches/studentAttendance/",
          icon: "user-check",
        },
        viewAttendance: {
          title: "View Attendance",
          link: "course/batches/studentViewAttendance/",
          icon: "list-alt",
        },
        bulkAdd:{
          title: "Bulk Add Students",
          link:"bulkAddStudentsToBatch",
          icon:"fa fa-users",
        },
        batchShift: {
          title: "Batch Changed Students",
          link: "batchShiftedList",
          icon: "exchange-alt",
        },
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={batchId} />;
    }
  };

  const fetchBreadcrumbs=()=>{
    const itemObj={
      dashBoard: { title: "Courses", link: "" },
      batches: { title: "Batches", link: `course/batches/?id=${Bdata.course}` }, 
      student: {title: "Batch Changed Students",link: `batchShiftedList/?id=${batchId}`,
        
      },
    }
    return <Trail itemObj={itemObj} />
    }

    console.log(Bdata.course);

    const fetchBatches = async () => {
      setLoading(true);
      const URI = `${endpoints.FETCH_BATCH}${batchId}/`;
      try {
        const apiResponse = await getStudentsList(URI);
        const { response, error } = apiResponse;
        if (!error && response.data) {
          const { data: apiData } = response;
          setBData(apiData.data);
        } else {
          const { response: errRes } = error;
          toast.error(
            errRes?.data?.message || "Error Occurred. Please contact Admin !!"
          );
        }
      } catch (error) {
        console.error("Error occurred during fetchBatches:", error);
        toast.error("An error occurred. Please try again or contact admin.");
      }
      setLoading(false);
    };


  useEffect(() => {
    fetchStudentList();
    fetchBatches()
  }, []);

  const fetchStudentList = async () => {
    setLoading(true);
    const url = `${endpoints.GET_BATCHCHANGED}${batchId}/`;
    const apiResponse = await getStudentsList(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData);
     
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const checkDataloaded = () => {
    if (data) {
      return data.map((user, index) => renderList(user, index));
    } else {
      return <div>Error Occurred...</div>; 
    }
  };

  const handleVideoPChange = async (stuID) => {
    setLoading(true);
    try {
        const idd= stuID.student.id
    
      const url = stuID.access_video
        ? `${endpoints.DENY_VIDEO_P}${idd}/${batchId}/`
        : `${endpoints.GIVE_VIDEO_P}${idd}/${batchId}/`;
      const apiResponse = await videoPChange(url);
      const { response, error } = apiResponse;
      if (!error && response.data) {
        fetchStudentList();
      } else {
        const { response: errRes } = error;
        toast.error(
          errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
        );
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred. Please try again later.");
    }
    setLoading(false);
  };
  
  const renderList = (data, index) => {
    const { student } = data;
    return (
      <tr key={index}>
        <th scope="row">{index + 1}</th>
        <td className="fw-bold">{student.user.full_name}</td>
        <td>{student.user.phone}</td>
        <td>
          <Form.Check
            className="fs-4"
            type="switch"
            id={`stat-${index}`}
            required
            checked={data.access_video}
            onChange={() => handleVideoPChange(data)}
          />
        </td>
      </tr>
    );
  };
  

const [formData,setFormData]=useState({
    access_video:false
  });

  return (
    <Container fluid className="p-0">

    <div className="m-0 main-grid">
    <div className="p-0  d-none  d-md-block">{fetchNavObj()}</div>{" "}
        {/*For larger screen (>=md) */}
        <div className=" m-0 p-0 ">

          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>


        <Container className="  ">
        <div className="mt-3">
          {fetchBreadcrumbs()}
        </div> 
      <Row className="">
      <div className="table-responsive table-wrapper border-start border-end">
          <table className="text-center table  striped  hover">
            <thead>
              <tr>
                <th scope="col">S No.</th>
                <th scope="col">Name</th>
                <th scope="col">Phone</th>
                <th scope="col">Give Video Access</th>
              </tr>
            </thead>
            <tbody>{checkDataloaded()}</tbody>
          </table>
      </div>
      </Row>
      </Container>
      </div></div>
    </Container>
  );
};

export default Batchshift;
