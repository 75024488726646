import React from "react";
import "./ModalSpinner.css"; 

const ModalSpinner = () => {
  return (
    <div className="modal-spinner-container">
      <div className="modal-spinner"></div>
    </div>
  );
};







export default ModalSpinner;


