import React, { useContext } from "react";
import { Navbar, Nav, Button, Container, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./NavBar.css";
import AuthContext from "../../contexts/authContext";
import { ToastContainer, toast } from "react-toastify";
import LuminarLogo from "../../images/logo1.png";

const NavBar = ({ logoImage, heading }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();

  const userName = user?.full_name ?? "|";

  const handleLogout = () => {
    const CustomConfirmationToast = ({ closeToast }) => (
      <div className="text-center">
        <div className="mb-3">Are you sure you want to log out?</div>
        <Button
          variant="outline-dark logout-btn me-2"
          onClick={() => {
            authContext.loggoff(() => navigate("/"));
            closeToast();
          }}
        >
          Yes
        </Button>
        <Button variant="outline-dark logout-btn" onClick={closeToast}>
          No
        </Button>
      </div>
    );

    toast.info(<CustomConfirmationToast />, {
      position: "top-center",
      autoClose: false,
      closeOnClick: false,
      draggable: true,
      progress: undefined,
    });
  };

  const handleRedirectHome = () => {
    navigate('/');
  }
  const handleredirectProfile = () => {
    navigate(`/user/profile?id=${user.id}`)
  }
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}

    >
      {children}
    </div>
  ));

  return (
    <Navbar
      expand
      variant="light"
      bg="light"
      className="p-0 shadow-sm nav-size"
    >
      <div className="navbar-clr py-2 pe-3 ps-md-3 nav-grid w-100">
        <img
          src={LuminarLogo}
          alt="Luminar"
          style={{ width: '40px' }}
          className="mx-2 d-md-none"
        />
        {/* <div className="mx-2 d-none d-md-block"></div> */}
         {/* To adjust the grid */}
         
          <Navbar.Text
            className="fs-3 text-dark text-decoration-none nav-heading"
            title={heading}
          >
            {heading}
          </Navbar.Text>
        <div className="menu">
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="avatar-dropdown">
              <div className="ps-5 pe-md-3 py-1">
                <div className="avatar">
                  {userName?.slice(0, 1)}
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="px-0 nav-dropdown">
              <Dropdown.Item onClick={handleredirectProfile} className="fw-bold">{userName}</Dropdown.Item>
              <Dropdown.Item onClick={handleLogout} className="fw-bold">
                <i className="bi-box-arrow-right"></i> Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

      </div>
      <ToastContainer />
    </Navbar>
  );
};

export default NavBar;
