const API_URL_DEV = process.env.REACT_APP_API_URL_DEV;

export const endpoints = {
  LOGIN: `${API_URL_DEV}/user/login/staff/`,
  CREATE_USER: `${API_URL_DEV}/user/create/`,
  FECH_USER_TYPE_LIST: `${API_URL_DEV}/user/count/`,
  FECH_USER_DETAILS: `${API_URL_DEV}/user/detail/`,
  FECH_USER_LIST: `${API_URL_DEV}/user/list`,
  UPDATE_USER: `${API_URL_DEV}/user/update/`,
  UPDATE_USERPROFILE: `${API_URL_DEV}/user/profile/update/`,

  GET_ALL_STUD_LIST: `${API_URL_DEV}/user/list/student`,

  CHANGE_PSWD: `${API_URL_DEV}/user/change/password/`,
  FORGOT_PSWD: `${API_URL_DEV}/user/password/forgot/`,

  RESEND_OTP: `${API_URL_DEV}/user/resend_otp/`,
  VERIFY_OTP: `${API_URL_DEV}/user/verify_otp/`,

  GET_MOB_VERSION: `${API_URL_DEV}/mob_version/`,
  UPDATE_MOB_VERSION: `${API_URL_DEV}/mob_version/create/`,

  ADD_COURSE: `${API_URL_DEV}/courses/create/`,
  VIEW_COURSE: `${API_URL_DEV}/courses/list/`,
  VIEW_COURSE_DETAILS: `${API_URL_DEV}/courses/`,
  UPDATE_COURSE: `${API_URL_DEV}/courses/update`,
  DELETE_COURSE: `${API_URL_DEV}/courses/delete`,

  ADD_BATCH: `${API_URL_DEV}/batches/create/`,
  UPDATE_BATCH: `${API_URL_DEV}/batches/update/`,
  VIEW_BATCH: `${API_URL_DEV}/batches/list/all/`,
  DELETE_BATCH: `${API_URL_DEV}/batches/delete/`,
  FETCH_BATCH_BYFACULTY: `${API_URL_DEV}/faculty/batches/list/`,
  FETCH_BATCH: `${API_URL_DEV}/batches/`,
  FETCH_BATCH_FACULTYS: `${API_URL_DEV}/batches/faculty/all/`,
  FETCH_BATCH_COUNSELLORS: `${API_URL_DEV}/batches/counsellors/all/`,

  FETCH_BATCH_VIDEOS: `${API_URL_DEV}/classvideos/list/`,
  DELETE_BATCH_VIDEO: `${API_URL_DEV}/classvideos/delete/`,
  UPDATE_BATCH_VIDEO: `${API_URL_DEV}/classvideos/update/`,
  ADD_BATCH_VIDEO: `${API_URL_DEV}/classvideos/create/`,
  FETCH_BATCH_LIVE_CLASS: `${API_URL_DEV}/liveclass/list/`,
  DELETE_LIVE_CLASS: `${API_URL_DEV}/liveclass/delete/`,
  ADD_LIVE_CLASS: `${API_URL_DEV}/liveclass/create/`,
  UPDATE_LIVE_CLASS: `${API_URL_DEV}/liveclass/update/`,

  ADD_VIDEO_FOLDER: `${API_URL_DEV}/video_folder/create/`,
  VIEW_VIDEO_FOLDER: `${API_URL_DEV}/video_folder/list/batch/`,
  UPDATE_FOLDER: `${API_URL_DEV}/video_folder/update/`,
  DELETE_FOLDER: `${API_URL_DEV}/video_folder/delete/`,

  STUDENT_BATCHES: `${API_URL_DEV}/batches/list/`,

  ADD_MODULE: `${API_URL_DEV}/module/create/`,
  DELETE_MODULE: `${API_URL_DEV}/module/delete`,
  UPDATE_MODULE: `${API_URL_DEV}/module/update`,

  ADD_DEMO_VIDEO: `${API_URL_DEV}/democlass/add/`,
  UPDATE_DEMO_VIDEO: `${API_URL_DEV}/democlass/Update`,
  DELETE_DEMO_VIDEO: `${API_URL_DEV}/democlass/delete`,
  FECH_DEMO_VIDEO_LIST: `${API_URL_DEV}/democlass/list/`,

  VIEW_STUDENTS: `${API_URL_DEV}/student/list/batch`,
  W_LIST_COURSE: `${API_URL_DEV}/course/waiting/students/`,
  STUDENT: `${API_URL_DEV}/student`,

  VIEW_BANNERS: `${API_URL_DEV}/banners/all/`,
  CREATE_BANNERS: `${API_URL_DEV}/banner/create/`,
  DELETE_BANNERS: `${API_URL_DEV}/banner/delete/`,

  CREATE_ATTENDANCE: `${API_URL_DEV}/attendance/create/`,
  VIEW_ATTENDANCE: `${API_URL_DEV}/attendance/list/`,
  VIEW_SORTED_ATTENDANCE: `${API_URL_DEV}/attendance/list/ft/`,

  VIEW_LEADS: `${API_URL_DEV}/lead/list/`,
  UPDATE_LEAD: `${API_URL_DEV}/lead/update/`,

  SHIFT_BATCH: `${API_URL_DEV}/student/batch/change/`,
  GET_BATCHCHANGED: `${API_URL_DEV}/batch/changed/student/list/`,

  GIVE_VIDEO_P: `${API_URL_DEV}/batch/changed/student/video/allow/`,
  DENY_VIDEO_P: `${API_URL_DEV}/batch/changed/student/video/deny/`,

  BULK_ADD_STUDENTS: `${API_URL_DEV}/student/create/`,

  ALLOW_RECORDED:`${API_URL_DEV}/student/recorded/allow/`,
  DENY_RECORDED:`${API_URL_DEV}/student/recorded/deny/`
};
