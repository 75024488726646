import React, { useContext, useEffect, useState } from "react";
import { Table, Button, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import useApi from "../../hooks/useApi";
import { endpoints } from "../../defaults";
import NavBar from "../../Common/Navbar/NavBar";
import AuthContext from "../../contexts/authContext";
import Sidebar from "../../Common/Sidebar/SideBar";
import logoImage from "../../images/logo1.png";
import "./EnquiryLeads.css";
import CustomSpinner from "../../Common/Spinner/spinner";
const heading = "Luminar Technolab";

const EnquiryLeads = () => {
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const [loading, setLoading] = useState(false);
  const { request: getLeads } = useApi("get");
  const { request: updateLeads } = useApi("get");
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    setLoading(true);
    const apiResponse = await getLeads(endpoints.VIEW_LEADS);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setDataLoaded(true);
    setLoading(false);
  };

  const updateLead = async (id) => {
    setLoading(true);
    const apiResponse = await getLeads(`${endpoints.UPDATE_LEAD}${id}`);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      console.log(apiData.data);
      fetchLeads();
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "" },
      };
      return <Sidebar navObj={navObj} />;
    } else {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "", icon: "chart-line" },
        users: { title: "Users", link: "users", icon: "users" },
        addCourse: { title: "Add Course", link: "addcourse", icon: "plus" },
        addCarousel: {
          title: "Add Carousel",
          link: "addCarousel",
          icon: "image",
        },
        lead: { title: "Leads", link: "leads", icon: "user-tie" },
      };
      return <Sidebar navObj={navObj} />;
    }
  };

  return (
    <div>
      <Container fluid className="p-0 ">
        <div className="main-grid p-0">
          <div className="p-0 m-0 d-none  d-md-block">{fetchNavObj()}</div>{" "}
          {/*For larger screen (>=md) */}
          <div className="mt-0 p-0">
            <div className="sticky-top">
              <NavBar heading={heading} logoImage={logoImage} />
            </div>
            <div className="d-md-none">
              {fetchNavObj()} {/*For smaller screen (<md) */}
            </div>
            <div className="text-center">
              <h2 className="mt-4 mb-3 head fw-bold">Enquiry Leads</h2>
            </div>
            {loading ? (
              <CustomSpinner />
            ) : (<div>
            {dataLoaded && (
              <div className="m-3">
                <Table hover responsive className="border-end border-start">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((lead, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{lead.name}</td>
                        <td>{lead.phone_number}</td>
                        <td>
                          {lead.wached ? (
                            <div
                              style={{
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Verified
                            </div>
                          ) : (
                            <Button
                              variant="primary"
                              className="button"
                              onClick={() => updateLead(lead.id)}
                            >
                              Verify
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}</div> )}
          </div>{" "}
        </div>
      </Container>
    </div>
  );
};

export default EnquiryLeads;
