import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  InputGroup,
  FormControl,
  Card,
  Container,
  Row,
  Col,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/authContext";
import "./batchPage.css";
import { useLocation } from "react-router-dom";
import NavBar from "../../Common/Navbar/NavBar";
import Sidebar from "../../Common/Sidebar/SideBar";
import imgURL from "../../images/imgLink";
import { endpoints } from "../../defaults";
import useApi from "../../hooks/useApi";
import CustomSpinner from "../../Common/Spinner/spinner";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Trail from "../../Common/Breadcrumb/Trail";


const BatchPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [Tdata,setTdata] = useState("")
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const { request: getBatches } = useApi("get");
  const { request: deleteBatch } = useApi("delete");
  const navigate = useNavigate();
  const [locationString, SetLocationString] = useState("cochin");
  const [clickedLocation, setClickedLocation] = useState("cochin");
  const [isFaculty, setIsfaculty] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const CourseId = queryParams.get("id");
  const [Cdata, setCData] = useState([]);
  const img = `${Cdata.thumbnail}`;
  const heading = `${Cdata.full_name}`;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [batchToDelete, setBatchToDelete] = useState(null);

  useEffect(() => {
    fetchCourse();
    checkFaculty(user);
  }, []);

  const checkFaculty = (user) => {
    if (user && user.user_type === "faculty") {
      setIsfaculty(true);
      fetchBatchesbyFaculty(locationString);
    } else {
      fetchBatches(locationString);
    }
  };

  const fetchBatchesByUser = (loc) => {
    const fetchFunction = isFaculty ? fetchBatchesbyFaculty : fetchBatches;
    const locationParam = loc === "all" ? null : `&location=${loc}`;

    fetchFunction(locationParam)
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  const fetchCourse = async () => {
    setLoading(true);
    const url = `${endpoints.VIEW_COURSE_DETAILS}${CourseId}/`;
    const apiResponse = await getBatches(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setCData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchBatches = async (loc) => {
    setLoading(true);
    const locationParam = loc === null ? " " : `&location=${loc}`;
    const URI = `${endpoints.VIEW_BATCH}${CourseId}/?search=${searchQuery}${locationParam ? locationParam : ""
      }`;
    const apiResponse = await getBatches(URI);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
      setTdata(apiData.total_results)
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchBatchesbyFaculty = async (loc) => {
    setLoading(true);

    const URI = `${endpoints.FETCH_BATCH_BYFACULTY}${CourseId}/?search=${searchQuery}`;
    const apiResponse = await getBatches(URI);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        batches: { title: "Batches", link: "course/batches/" , icon:"graduation-cap" },
        overview: { title: "Overview", link: "course/overview/", icon:"eye"  },
        demoVideo: { title: "DemoVideo", link: "course/demovideo/",icon:"video" },
      };
      return <Sidebar navObj={navObj} itemObj={Cdata.id} />;
    } else {
      const navObj = {
        batches: { title: "Batches", link: "course/batches/", icon:"graduation-cap" },
        overview: { title: "Overview", link: "course/overview/", icon:"eye" },
        demoVideo: { title: "DemoVideo", link: "course/demovideo/",icon:"video" },
        addBatch: { title: "Add Batch", link: "course/addBatch/",icon:"plus" },
      };
      return <Sidebar navObj={navObj} itemObj={Cdata.id} />;
    }
  };

  const fetchBreadcrumbs=()=>{
    const itemObj={
      dashBoard: { title: "Courses", link: "" },
      batches: { title: "Batches", link: `course/batches/?id=${Cdata.id}` }, 
    }
    return <Trail itemObj={itemObj}/>
    }

  const locVal = {
    cochin: "Cochin",
    calicut: "Calicut",
    all: "All",
  };

  const handleCardClick = (Batch) => {
    navigate(`/course/batches/students/?id=${Batch.id}`);
  };

  const handleOverviewClick = (id) => {
    navigate(`/course/batches/overview/?id=${id}`);
  };

  const handleEditClick = (id) => {
    navigate(`/course/batches/overview/editbatch?id=${id}`);
  };

  const handleDeleteClick = (Batch) => {
    setBatchToDelete(Batch);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmation = async (confirmed) => {
    setShowDeleteConfirmation(false);

    if (confirmed && batchToDelete) {
      await handleDelete(batchToDelete.id);
    }

    setBatchToDelete(null);
  };

  const handleDelete = async (batchId) => {
    try {
      const uri = `${endpoints.DELETE_BATCH}${batchId}/`;
      setLoading(true);
      const apiResponse = await deleteBatch(uri);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        fetchBatches();
        toast.success("Batch successfully deleted!");
        handleLocClick("cochin");
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Error occurred during submission:", error);
    }
  };

  const deleteConfirm = (batch) => {
    const CustomConfirmationToast = () => (
      <div className="text-center">
        <div className="mb-3">
          Are you sure you want to delete {batch.batch_name}
        </div>
        <Button
          variant="outline-dark logout-btn me-2"
          onClick={() => handleConfirmation(true)}
        >
          Yes
        </Button>
        <Button
          variant="outline-dark logout-btn"
          onClick={() => handleConfirmation(false)}
        >
          No
        </Button>
      </div>
    );

    const existingToast = toast.isActive("custom-confirmation-toast");

    if (!existingToast) {
      toast.warning(<CustomConfirmationToast />, {
        toastId: "custom-confirmation-toast",
        position: "top-center",
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const renderBatchCard = (Batch) => {
    const totalStudents = Batch.active_students + Batch.inactive_students;
    const returnStatus = () => {
      if (Batch.current_status === "In progress") {
        return (
          <div className="d-flex justify-content-end">
          
            <span className="status_clr fw-normal ">
            In Progress
            </span>
          </div>
        );
      } else if (Batch.current_status === "Completed") {
        return (
          <span className=" px-3 py-1 text-danger rounded-2">
            Completed
          </span>
        );
      }
    };
    return (

      <Col lg={4} md={6} sm={6} xs={12} key={Batch.id} className="">
        <div className="d-flex justify-content-end">
          <Dropdown className="t-btn p-2  d-flex justify-content-end mt-1">
            <Dropdown.Toggle
              variant="link"
              id={`dropdown-menu-${Batch.id}`}
              className="toggle-btn me-0  mt-2  fs-5 text-secondary custom-dropdown-toggle"
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="custom-dropdown-item"
                key={1}
                onClick={() => handleOverviewClick(Batch.id)}
              >
                Overview
              </Dropdown.Item>
              <Dropdown.Item
                className="custom-dropdown-item"
                key={2} onClick={() => handleEditClick(Batch.id)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="custom-dropdown-item"
                key={3} onClick={() => handleDeleteClick(Batch)}>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="clickable-card" onClick={() => handleCardClick(Batch)}>
          <div className="mb-4 border mt-3 border-2 batch_card_1  rounded-2 batch-card p-3">
            <Row>
              <Col md={12} className="batch-head crd-header ">
                <Card.Title className="fw-bold mt-2  clr ">
                  {Batch.batch_name}
                </Card.Title>

              </Col>
            </Row>
            <Card.Header className="fw-bold mt-4">
              Total Students : {totalStudents}
            </Card.Header>
            <hr></hr>
            <Card.Body>
              <p>
                <span className="fw-bold text-dark">Active Students: </span>
                {Batch.active_students}
              </p>

              <p>
                <span className="fw-bold text-dark">Inactive Students: </span>
                {Batch.inactive_students}
              </p>
            </Card.Body>
            <Card.Footer className="fw-bold mt-4">{returnStatus()}</Card.Footer>
          </div>
        </div>
      </Col>
    );
  };

  const handleLocClick = (loc) => {
    setData([]);
    SetLocationString(loc);
    setClickedLocation(loc);
    fetchBatchesByUser(loc);
  };

  return (
    <Container fluid className="p-0">

      <div className="main-grid m-0">
      <div className="p-0 m-0 d-none  d-md-block">
            {fetchNavObj()}
          </div>  {/*For larger screen (>=md) */}
        <div className=" m-0 p-0 ">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={img} />
          </div>
          <div className="d-md-none" >
              {fetchNavObj()} {/*For smaller screen (<md) */}
            </div>
          <div className="container batch-list">
          <div className="">
          {fetchBreadcrumbs()}
        </div>  
            <div className="row mt-5">
              <div >
                <div className="d-flex justify-content-center">
                  <div className="search-bar-main d-flex mt-2 ">
                    <InputGroup className="search-bar-child ">
                      <FormControl
                        className="search-bar-child"
                        placeholder="Search By Batch Name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && fetchBatchesByUser(locationString)}
                      />

                      <Button
                        className="btn "
                        variant="common"
                        onClick={() => fetchBatchesByUser(locationString)}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </Button>
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
            {!isFaculty && (
              <div className="row mt-4">
                <div className="col-12  d-flex justify-content-center">
                  {Object.keys(locVal).map((key) => (
                    <button
                      className="location mx-1"
                      style={{
                        backgroundColor:
                          clickedLocation === key ? "#661B8A" : "",
                        color: clickedLocation === key ? "white" : "black",
                      }}
                      onClick={() => handleLocClick(key)}
                      key={key}
                    >
                      <h5 className="fw-bold m-0 p-1">{locVal[key]}</h5>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <Container className=" p-3">
            {loading && (
              <div className="loader-container">
                <CustomSpinner />
              </div>
            )}
            <Row>
              <Col>
              <h5 className=" ms-1 my-2 fw-bold"> Total Batch : <span className="clr">{Tdata?Tdata:""}</span></h5>
                <Row className="my-2">
                  {data.map((batch) => renderBatchCard(batch))}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {showDeleteConfirmation && (
        <div className="custom-confirmation-modal">
          {deleteConfirm(batchToDelete)}
        </div>
      )}
    </Container>
  );
};

export default BatchPage;
