import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import Sidebar from "../../../Common/Sidebar/SideBar";
import NavBar from "../../../Common/Navbar/NavBar";
import logoImage from "../../../images/logo1.png";
import { useLocation } from "react-router-dom";
import { endpoints } from "../../../defaults";
import { toast } from "react-toastify";
import useApi from "../../../hooks/useApi";
import * as XLSX from "xlsx";
import CustomSpinner from "../../../Common/Spinner/spinner";
import "./BulkAddtoBatch.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import SampleFile from "../../../Files/SampleCsv.csv";

const BulkAddToBatch = () => {
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const batchId = queryParams.get("id");
  const { request: getBatch } = useApi("get");
  const { request: addstudents } = useApi("post");
  const [data, setData] = useState([]);
  const [bData, setBData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [responses, setResponses] = useState([]);
  const [showResponse, setShowResponse] = useState(false);

  const heading = `Add Students to ${bData.batch_name}`;
  const navObj = {
    student: {
      title: "Students",
      link: "course/batches/students/",
      icon: "users",
    },
    Overview: {
      title: "Overview",
      link: "course/batches/overview/",
      icon: "eye",
    },
    video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
    liveclasses: {
      title: "Live Classes",
      link: "course/batches/liveclasses/",
      icon: "chalkboard-teacher",
    },
    markAttendance: {
      title: "Mark Attendance",
      link: "course/batches/studentAttendance/",
      icon: "user-check",
    },
    viewAttendance: {
      title: "View Attendance",
      link: "course/batches/studentViewAttendance/",
      icon: "list-alt",
    },
    bulkAdd: {
      title: "Bulk Add Students",
      link: "bulkAddStudentsToBatch",
      icon: "fa fa-users",
    },
    batchShift: {
      title: "Batch Changed Students",
      link: "batchShiftedList",
      icon: "exchange-alt",
    },
    recordedAccess: {
      title: "Recorded Student List",
      link: "recordedStudent",
      icon: "play-circle",
    },
  };

  useEffect(() => {
    fetchBatch();
  }, []);

  const fetchBatch = async () => {
    setLoading(true);
    const url = `${endpoints.FETCH_BATCH}${batchId}/`;
    const apiResponse = await getBatch(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setBData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Find the maximum number of columns in the data
      const maxColumns = Math.max(...jsonData.map((row) => row.length));

      // Fill empty columns with "No Data" to ensure all rows have the same number of columns
      const sanitizedData = jsonData.map((row) => {
        const missingColumns = maxColumns - row.length;
        const emptyColumns = Array(missingColumns).fill(" ");
        return [...row, ...emptyColumns];
      });

      // Remove the header row
      sanitizedData.shift();

      // Format country codes by adding "+" sign
      const formattedData = sanitizedData.map((row) => {
        // Assuming the country code is in the first column
        const countryCode = `+${row[1]}`;
        // Replace the first element in the row with the formatted country code
        row[1] = countryCode;
        return row;
      });

      setData(formattedData);
    };

    reader.readAsBinaryString(file);
  };

  const handleCellChange = (value, rowIndex, columnIndex) => {
    const newData = [...data];
    newData[rowIndex][columnIndex] = value;
    setData(newData);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true); // Show loader while API calls are made
      const responsesArray = [];
      for (let i = 0; i < data.length; i++) {
        const payload = {
          user_type: "student",
          course: bData.course,
          parent_no: data[i][5],
          full_name: data[i][0],
          email: data[i][3],
          gender: data[i][4],
          is_active: true,
          is_staff: false,
          password: "abc123",
          phone: data[i][2],
          country_code: data[i][1],
        };
        const url = `${endpoints.BULK_ADD_STUDENTS}${batchId}/`;
        const apiResponse = await addstudents(url, payload);
        const { response, error } = apiResponse;
        if (response) {
          responsesArray.push({
            responseData: response.data.message,
            inputData: data[i],
            status: response.request.status,
          });
        }
        if (error) {
          responsesArray.push({
            responseData: error.response.data.message,
            inputData: data[i],
            status: error.request.status,
          });
        }
      }
      setResponses(responsesArray);
      setLoading(false); // Hide loader after API calls are completed
      setShowResponse(true); // Show response div
    } catch (error) {
      console.error("Error occurred during students addition:", error);
      toast.error("An error occurred. Please try again or contact admin.");
      setLoading(false); // Hide loader if error occurs
    }
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  //   const handleSubmit = async () => {
  //     try {
  //       for (let i = 0; i < data.length; i++) {
  //         const payload = {
  //             user_type: "student",
  //             course: bData.course,
  //             parent_no: data[i][4],
  //             user: {
  //               full_name:data[i][0],
  //               email: data[i][2],
  //               gender: data[i][3],
  //               is_active: true,
  //               is_staff: false,
  //               password: "abc123",
  //               phone: data[i][1],
  //             },
  //           };
  //         const url = `${endpoints.BULK_ADD_STUDENTS}${batchId}/`;
  //         const apiResponse = await addstudents(url, payload);
  //         const { response, error } = apiResponse;

  //         if (!error && response.data) {
  //           toast.success(`Student ${i + 1} added successfully`);
  //         } else {
  //           if (error.response.data.detail) {
  //             toast.error(`Error adding student ${i + 1}: ${error.response.data.detail}`);
  //           } else {
  //             toast.error(`Error adding student ${i + 1}: Something happened. Please contact admin.`);
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error occurred during students addition:", error);
  //       toast.error("An error occurred. Please try again or contact admin.");
  //     }
  //   };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = SampleFile;
    link.download = "SampleCsv.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container fluid className="p-0">
      <div className="m-0 main-grid">
        <div className="p-0 m-0 d-none  d-md-block">
          <Sidebar navObj={navObj} itemObj={batchId} />
        </div>
        <div className=" m-0 mt-2 p-0 ">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            <Sidebar navObj={navObj} itemObj={batchId} />
          </div>

          <Container className=" p-3 ">
            {loading ? (
              <CustomSpinner />
            ) : (
              <Row className="mt-3">
                <div>
                  <input
                    type="file"
                    id="fileInput"
                    accept=".csv,.xlsx,.xls"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                  <button onClick={handleButtonClick} className="button p-2">
                    <FontAwesomeIcon icon={faUpload}></FontAwesomeIcon>
                    {"  "}
                    Upload File
                  </button>

                  <button onClick={handleDownload} className="button p-2 ms-4">
                    {" "}
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                    {"  "}
                    Download Sample
                  </button>
                  {data.length > 0 && (
                    <>
                      <div className="table-responsive table m-0 w-100   table-wrapper ">
                        <Table className="editable-table table striped bordered hover  mt-4 border">
                          <thead>
                            <tr className="w-100 boarder">
                              <th>Full Name</th>
                              <th>Country Code</th>
                              <th>Phone</th>
                              <th>Email</th>
                              <th>Gender</th>
                              <th>Parent No</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {data.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                {row.map((cell, columnIndex) => (
                                  <td
                                    className="boarder"
                                    key={columnIndex}
                                    style={{
                                      backgroundColor: errors.find(
                                        (error) =>
                                          error.rowIndex === rowIndex &&
                                          error.columnIndex === columnIndex
                                      )
                                        ? "red"
                                        : "white",
                                    }}
                                  >
                                    <div className="w-100 p-2 ">
                                      <input
                                        className="border-0"
                                        type="text"
                                        value={cell}
                                        style={{ width: "100%" }}
                                        onChange={(e) =>
                                          handleCellChange(
                                            e.target.value,
                                            rowIndex,
                                            columnIndex
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <div className="text-end me-5">
                        <button
                          onClick={handleSubmit}
                          className="mb-5 p-2 button"
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Row>
            )}{" "}
            {showResponse && (
              <div>
                {responses.map((response, index) => (
                  <div
                    key={index}
                    style={{
                      border:
                        response.status === 200
                          ? "1px solid green"
                          : "1px solid red",
                      padding: "10px",
                      marginBottom: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <p
                      style={{
                        color: response.status === 200 ? "green" : "red",
                        fontWeight: "bold",
                      }}
                    >
                      {response.responseData}
                    </p>
                    <p>
                      {index + 1}, Student:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {response.inputData[0]}
                      </span>
                      , {response.inputData.slice(1).join(", ")}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </Container>
        </div>
      </div>
    </Container>
  );
};

export default BulkAddToBatch;
