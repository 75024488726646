import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./loginForm.css";
import { useFormik } from "formik";
import * as yup from "yup";
import useApi from "../../hooks/useApi";
import { endpoints } from "../../defaults";
import AuthContext from "../../contexts/authContext";
import groupImage from "../../images/Group1.png";
import logoImage from "../../images/logo1.png";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [phoneNum, setPhoneNum] = useState("");
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [showNewPassForm, setshowNewPassForm] = useState(false);
  const [showAddPassForm, setshowAddPassForm] = useState(false);
  const [udata, setuData] = useState("");

  const navigate = useNavigate();
  const { request: login } = useApi("post");
  const { setCurrentUser, loggoff } = useContext(AuthContext);

  useEffect(() => {
    loggoff(() => {
      console.log("logged off");
    });
  }, [loggoff]);

  const validationSchema = yup.object({
    phoneNumber: yup
      .string("Enter your Phone Number")
      .min(10, "Enter a valid phone number")
      .required("Phone number is required"),
    password: yup
      .string("Enter your password")
      .min(4, "Password should be of minimum 4 characters length")
      .required("Password is required"),
  });

  const validationSchema2 = yup.object({
    phoneNumber: yup
      .string("Enter your Phone Number")
      .min(10, "Enter a valid phone number")
      .required("Phone number is required"),
  });

  const validationSchema4 = yup.object({
    password: yup
      .string("Enter your Password")
      .min(4, "Should have minimum 4 length")
      .required("Password is required"),
  });

  const validationSchema3 = yup.object({
    otp1: yup
      .string("Enter OTP")
      .min(1, "Enter OTP")
      .max(1, "Enter OTP")
      .required("OTP required"),
    otp2: yup
      .string("Enter OTP")
      .min(1, "Enter OTP")
      .max(1, "Enter OTP")
      .required("OTP required"),
    otp3: yup
      .string("Enter OTP")
      .min(1, "Enter OTP")
      .max(1, "Enter OTP")
      .required("OTP required"),
    otp4: yup
      .string("Enter OTP")
      .min(1, "Enter OTP")
      .max(1, "Enter OTP")
      .required("OTP required"),
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (values.phoneNumber && values.password) {
        const payload = {
          phone: values.phoneNumber,
          password: values.password,
        };
        setLoading(true);
        const apiResponse = await login(endpoints.LOGIN, payload);
        const { response, error } = apiResponse;
        if (response && response.data) {
          const { data: apiData } = response;
          setCurrentUser(apiData.data);
          navigate("/");
        } else {
          const { response: errRes } = error;
          const errorMessage =
            errRes?.data?.message || "Error Occurred. Please contact Admin !!";
          toast.error(errorMessage);
        }
        setLoading(false);
      }
    },
  });

  const formik2 = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: validationSchema2,
    onSubmit: async (values) => {
      if (values.phoneNumber) {
        const payload = {
          phone: values.phoneNumber,
        };
        setLoading(true);
        const apiResponse = await login(endpoints.FORGOT_PSWD, payload);
        const { response, error } = apiResponse;
        if (response && response.data.status === 1) {
          const { data: apiData } = response;
          setPhoneNum(values.phoneNumber);
          setShowLoginForm(false);
          setShowOTPForm(false);
          setshowAddPassForm(false);
          setshowNewPassForm(true);
        } else {
          const { response: errRes } = error;
          const errorMessage =
            errRes?.data?.message || "Error Occurred. Please contact Admin !!";
          toast.error(errorMessage);
        }
        setLoading(false);
      }
    },
  });

  const handleInputChange = (fieldName, e) => {
    const { value } = e.target;
    if (value.length <= 1 && /^\d*$/.test(value)) {
      formik3.setFieldValue(fieldName, value);
      if (value !== "") {
        const nextField = e.target.nextElementSibling;
        if (nextField) {
          nextField.focus();
        }
      }
    }
  };

  const formik3 = useFormik({
    initialValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    },
    validationSchema: validationSchema3,
    onSubmit: async (values) => {
      const otp = `${values.otp1}${values.otp2}${values.otp3}${values.otp4}`;
      console.log(otp);
      if (otp) {
        const payload = {
          phone: phoneNum,
          otp: otp,
        };
        setLoading(true);
        const apiResponse = await login(endpoints.VERIFY_OTP, payload);
        const { response, error } = apiResponse;
        console.log(response);
        if (response && response.data.status === 1) {
          const { data: apiData } = response.data;
          const userId = apiData.user.id;
          setuData(userId);
          setShowLoginForm(false);
          setshowNewPassForm(false);
          setShowOTPForm(false);
          setshowAddPassForm(true);
        } else {
          const { data: apiData } = response;
          console.log(apiData)
          const errorMessage =
          apiData?.message || "Error Occurred. Please contact Admin !!";
          toast.error(errorMessage);
        }
        setLoading(false);
      }
    },
  });

  const formik4 = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema4,
    onSubmit: async (values) => {
      if (values.password) {
        const payload = {
          password: values.password,
        };
        setLoading(true);
        const id = udata;
        const apiResponse = await login(
          `${endpoints.CHANGE_PSWD}${id}/`,
          payload
        );
        const { response, error } = apiResponse;
        if (response && response.data.status === 1) {
          const { data: apiData } = response;
          const errorMessage = "Successfully Changed the Password";
          toast.success(errorMessage);
          setShowOTPForm(false);
          setshowAddPassForm(false);
          setshowNewPassForm(false);
          setShowLoginForm(true);
        } else {
          const { response: errRes } = error;
          const errorMessage =
            errRes?.data?.message || "Error Occurred. Please contact Admin !!";
          toast.error(errorMessage);
        }
        setLoading(false);
      }
    },
  });

  const handleForgotPasswordClick = () => {
    setShowLoginForm(false);
    setShowOTPForm(true);
  };

  const handleToLoginPage = () => {
    setShowOTPForm(false);
    setshowNewPassForm(false);
    setShowLoginForm(true);
  };

  return (
    <Container className="login-main" fluid>
      <Row>
        <Col md={7} className="hide">
          <Image
            className="side-img "
            src={groupImage}
            alt="Luminar Technolab"
          />
        </Col>
        <Col md={5} className="login-container">
          <div className="form-container">
            <Row>
              <Col
                md={4}
                className="title-container-logo  d-flex justify-content-end"
              >
                <Image
                  className="logo"
                  src={logoImage}
                  alt="Luminar Technolab"
                />
              </Col>
              <Col md={6} className="title-container d-flex align-items-end">
                <h1 className="ttile">Luminar Technolab</h1>
              </Col>
            </Row>
            <div className="data-container">
              {showLoginForm && (
                <div className="mt-5">
                  <Form onSubmit={formik.handleSubmit}>
                    <Form.Group controlId="phoneNumberInput">
                      <Form.Label className="main-colour mb-1 fw-bold">
                        Phone Number
                      </Form.Label>
                      <Form.Control
                        className="inputbox"
                        type="text"
                        placeholder="Enter your Phone Number"
                        name="phoneNumber"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber ? (
                        <div className="error_msg">
                          {formik.errors.phoneNumber}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId="passwordInput" className="mt-4">
                      <Form.Label className="main-colour mb-1 fw-bold">
                        Password
                      </Form.Label>
                      <Form.Control
                        className="inputbox"
                        type="password"
                        placeholder="Enter your Password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div className="error_msg">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </Form.Group>
                    <div className="d-flex justify-content-center">
                      <Button
                        className="btn login-btn mt-4 fw-bold"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Logging in..." : "Login"}
                      </Button>
                    </div>
                  </Form>
                  <div className="d-flex justify-content-center mt-4">
                    <p
                      className="fw-bold fs-6 forgot-link"
                      onClick={handleForgotPasswordClick}
                    >
                      Forgot password?{" "}
                      <span>
                        <a className="text-decoration-none" href="/#">
                          Click here
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              )}
              {showOTPForm && (
                <div className="mt-5">
                  <Form onSubmit={formik2.handleSubmit}>
                    <Form.Group controlId="phoneNumberInput">
                      <Form.Label className="main-colour mb-1 fw-bold">
                        Phone Number
                      </Form.Label>
                      <Form.Control
                        className="inputbox"
                        type="text"
                        placeholder="Enter your Phone Number"
                        name="phoneNumber"
                        value={formik2.values.phoneNumber}
                        onChange={formik2.handleChange}
                        onBlur={formik2.handleBlur}
                      />
                      {formik2.touched.phoneNumber &&
                      formik2.errors.phoneNumber ? (
                        <div className="error_msg">
                          {formik2.errors.phoneNumber}
                        </div>
                      ) : null}
                    </Form.Group>
                    <div className="d-flex justify-content-center">
                      <Button
                        className="btn login-btn mt-4 fw-bold"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Sending OTP..." : "Send OTP"}
                      </Button>
                    </div>
                  </Form>
                  <div className="d-flex justify-content-center mt-4">
                    <p
                      className="fw-bold fs-6 forgot-link"
                      onClick={handleToLoginPage}
                    >
                      Back to{" "}
                      <span>
                        <a className="text-decoration-none" href="/#">
                          Login Page
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              )}
              {showNewPassForm && (
                <div className="mt-5">
                  <Form onSubmit={formik3.handleSubmit}>
                    <Form.Group controlId="otpInputGroup">
                      <Form.Label className="main-colour mb-1 fw-bold">
                        Enter OTP
                      </Form.Label>
                      <div className="d-flex justify-content-between">
                        {[1, 2, 3, 4].map((i) => (
                          <Form.Control
                            key={i}
                            className="otpbox"
                            type="text"
                            maxLength={1}
                            name={`otp${i}`}
                            value={formik3.values[`otp${i}`]}
                            onChange={(e) => handleInputChange(`otp${i}`, e)}
                            onBlur={formik.handleBlur}
                          />
                        ))}
                      </div>
                      {formik.touched.otp && formik.errors.otp ? (
                        <div className="error_msg">{formik.errors.otp}</div>
                      ) : null}
                    </Form.Group>
                    <div className="d-flex justify-content-center">
                      <Button
                        className="btn login-btn mt-4 fw-bold"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Updating..." : "Confirm OTP"}
                      </Button>
                    </div>
                  </Form>
                  <div className="d-flex justify-content-center mt-4">
                    <p
                      className="fw-bold fs-6 forgot-link"
                      onClick={handleToLoginPage}
                    >
                      Back to{" "}
                      <span>
                        <a className="text-decoration-none" href="/#">
                          Login Page
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              )}
              {showAddPassForm && (
                <div className="mt-5">
                  <Form onSubmit={formik4.handleSubmit}>
                    <Form.Group controlId="passwordInput">
                      <Form.Label className="main-colour mb-1 fw-bold">
                        Enter New Password
                      </Form.Label>
                      <Form.Control
                        className="inputbox"
                        type="password"
                        name="password"
                        value={formik4.values.phoneNumber}
                        onChange={formik4.handleChange}
                        onBlur={formik4.handleBlur}
                      />
                      {formik4.touched.password && formik4.errors.password ? (
                        <div className="error_msg">
                          {formik4.errors.password}
                        </div>
                      ) : null}
                    </Form.Group>
                    <div className="d-flex justify-content-center">
                      <Button
                        className="btn login-btn mt-4 fw-bold"
                        type="submit"
                        // disabled={loading}
                      >
                        update
                        {/* {loading ? "Updating..." : "Change Password"} */}
                      </Button>
                    </div>
                  </Form>
                  <div className="d-flex justify-content-center mt-4">
                    <p
                      className="fw-bold fs-6 forgot-link"
                      onClick={handleToLoginPage}
                    >
                      Back to{" "}
                      <span>
                        <a className="text-decoration-none" href="/#">
                          Login Page
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default LoginForm;
