import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Trail.css";

function Trail({ itemObj }) {
  return (
    <div>
      <Breadcrumb className="breadcrumb-item">
        {Object.keys(itemObj).map((key, index) => (
          <Link
            key={index}
            style={{ textDecoration: "none" }}
            to={`/${itemObj[key].link}`}
          >
            <Breadcrumb.Item
              active
              className="ms-4 breadcrumb-item"
              style={{
                color:
                  index === Object.keys(itemObj).length - 1 ? "#661B8A" : "secondary",
              }}
            >
              <b className="me-2">{itemObj[key].title}</b> /
            </Breadcrumb.Item>
          </Link>
        ))}
      </Breadcrumb>
    </div>
  );
}

export default Trail;
