import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import NavBar from "../../Common/Navbar/NavBar";
import Sidebar from "../../Common/Sidebar/SideBar";
import logoImage from "../../images/logo1.png";
import useApi from "../../hooks/useApi";
import { useLocation } from "react-router-dom";
import { endpoints } from "../../defaults";
import imgURL from "../../images/imgLink";
import CustomSpinner from "../../Common/Spinner/spinner";
import { faAdd, faEdit, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthContext from "../../contexts/authContext";
import "./CourseOverview.css";
import Trail from "../../Common/Breadcrumb/Trail";


const CourseOverview = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const queryParams = new URLSearchParams(search);
  const CourseId = queryParams.get("id");
  const { request: getCourse } = useApi("get");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [moduleToDelete, setModuleToDelete] = useState(null);
  const { request: deleteModule } = useApi("delete");
  const { request: updateModule } = useApi("put");
  const { request: addModule } = useApi("post");
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const [editedModuleId, setEditedModuleId] = useState(null);

  const heading = "Course Overview";

  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        batches: {
          title: "Batches",
          link: "course/batches/",
          icon: "graduation-cap",
        },
        overview: { title: "Overview", link: "course/overview/", icon: "eye" },
        demoVideo: {
          title: "DemoVideo",
          link: "course/demovideo/",
          icon: "video",
        },
      };
      return <Sidebar navObj={navObj} itemObj={data.id} />;
    } else {
      const navObj = {
        batches: {
          title: "Batches",
          link: "course/batches/",
          icon: "graduation-cap",
        },
        overview: { title: "Overview", link: "course/overview/", icon: "eye" },
        demoVideo: {
          title: "DemoVideo",
          link: "course/demovideo/",
          icon: "video",
        },
        addBatch: {
          title: "Add Batch",
          link: "course/addBatch/",
          icon: "plus",
        },
      };
      return <Sidebar navObj={navObj} itemObj={data.id} />;
    }
  };

  const fetchBreadcrumbs=()=>{
    const itemObj={
      dashBoard: { title: "Courses", link: "" },
      batches: { title: "Overview", link: `course/overview/?id=${data.id}` }, 
        
      
    }
    return <Trail itemObj={itemObj} />
    }

  useEffect(() => {
    fetchCourse();
  }, [CourseId]);

  const [newModule, setNewModule] = useState({
    mod_no: "",
    mod_heading: "",
    mod_description: "",
  });

  const handleAddModuleClick = () => {
    setNewModule({
      mod_no: "",
      mod_heading: "",
      mod_description: "",
    });
    setShow(true);
  };

  const handleClose = () => {
    clearModal();
  };

  const handleAddModule = async () => {
    try {
      const url = `${endpoints.ADD_MODULE}${CourseId}/`;
      const apiResponse = await addModule(url, newModule);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Module added successfully");
        fetchCourse();
        setShow(false);
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened. Please contact admin.");
        }
      }
    } catch (error) {
      console.error("Error occurred during module addition:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
  };

  const clearModal = () => {
    clearDom();
    setEditedModule({
      mod_no: "",
      mod_heading: "",
      mod_description: "",
    });
    setEditedModuleId(null);
    setNewModule({
      mod_no: "",
      mod_heading: "",
      mod_description: "",
    });
    setShow(false);
  };

  const clearDom = () => {
    document.getElementById("formModuleNo").value = "";
    document.getElementById("formModuleHeading").value = "";
    document.getElementById("formModuleDescription").value = "";
  };

  const [editedModule, setEditedModule] = useState({
    mod_no: "",
    mod_heading: "",
    mod_description: "",
  });

  const fetchCourse = async () => {
    setLoading(true);
    const url = `${endpoints.VIEW_COURSE_DETAILS}${CourseId}/`;
    const apiResponse = await getCourse(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setLoading(false);
  };

  const handleDeleteclick = (module) => {
    setModuleToDelete(module);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmation = async (confirmed) => {
    setShowDeleteConfirmation(false);

    if (confirmed && moduleToDelete) {
      await handleDelete(moduleToDelete.id);
    }

    setModuleToDelete(null);
  };

  const deleteConfirm = (module) => {
    const CustomConfirmationToast = ({ closeToast }) => (
      <div className="text-center">
        <div className="mb-3">
          Are you sure you want to delete {module.mod_heading}
        </div>
        <Button
          variant="outline-dark logout-btn me-2"
          onClick={() => {
            handleConfirmation(true);
            closeToast();
          }}
        >
          Yes
        </Button>
        <Button
          variant="outline-dark logout-btn"
          onClick={() => {
            handleConfirmation(false);
            closeToast();
          }}
        >
          No
        </Button>
      </div>
    );

    const existingToast = toast.isActive("custom-confirmation-toast");

    if (!existingToast) {
      toast.warning(<CustomConfirmationToast />, {
        toastId: "custom-confirmation-toast",
        position: "top-center",
        autoClose: false,
        closeOnClick: true,
        // closeButton: (
        //   <CloseButton
        //     onClick={() => {
        //       // Add your on close logic here
        //       // For example, setdisplay to false
        //       setdisplay(false);
        //     }}
        //   />
        // ),
        draggable: true,
        progress: undefined,
        onClose: () => {
          setShowDeleteConfirmation(false);
          // Additional onClose logic, if needed
        },
      });
    }
  };

  const handleDelete = async (moduleId) => {
    try {
      const uri = `${endpoints.DELETE_MODULE}/${moduleId}/`;
      const apiResponse = await deleteModule(uri);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Module successfully deleted!");
        fetchCourse();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    }
  };

  const img = `${imgURL}${data.thumbnail}`;

  const dateConveter = (date) => {
    const originalDate = new Date(date);

    const formattedDate = originalDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  const handleEditClick = (module) => {
    setEditedModule({
      mod_no: module.mod_no,
      mod_heading: module.mod_heading,
      mod_description: module.mod_description,
    });
    setEditedModuleId(module.id);
    setShow(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedModule((prevModule) => ({
      ...prevModule,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    if (!editedModuleId) {
      console.error("Module ID is missing.");
      return;
    }

    try {
      let apiResponse;
      const url = `${endpoints.UPDATE_MODULE}/${editedModuleId}/`;
      apiResponse = await updateModule(url, editedModule);

      const { response, error } = apiResponse;
      if (!error && response.data) {
        toast.success("Module updated successfully");

        fetchCourse();
        clearModal();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }

      setShow(false);
    } catch (error) {
      console.error("Error occurred during module update:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
  };

  const renderModuleCard = (module) => {
    return (
      <div className=" mb-3">
        <Row key={module.id}>
          <Col md={11}></Col>
          <Col md={1}>
            <Dropdown className="card-menu bg-info d-flex justify-content-end">
              <Dropdown.Toggle
                variant="link"
                className="card-3dot fs-5 text-secondary custom-dropdown-toggle"
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="drop-down-menu" key={1} onClick={() => handleEditClick(module)}>
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                className="drop-down-menu"
                  key={2}
                  onClick={() => handleDeleteclick(module)}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        <h5 className="fw-bold text-center clr mt-4 mb-3">
          {module.mod_no}. {module.mod_heading}
        </h5>
        <p className=" mt-4 ms-5 me-5 text mod_description">
          {module.mod_description}
        </p>
        <Row className="d-flex text-center ms-5 me-5  mt-4">
          <Col lg={6} md={12} className="p-1">
            <p className=" mt-3 mx-2">
              <span className="m-0  ">Created Date </span> :
              <b> {dateConveter(module.created_date)}</b>
            </p>

            <p className=" mt-3 mx-2 ">
              <span className="m-0  ">Created By : </span>
              <b> {module.created_by}</b>
            </p>
          </Col>
          <Col lg={6} md={12} className="p-1">
            <p className=" mt-3 mx-2">
              <span className="m-0 ">Update Date : </span>
              <b> {dateConveter(module.updated_date)}</b>
            </p>

            <p className=" mt-3 mx-2 ">
              <span className="m-0  ">Update By :</span>
              <b> {module.updated_by}</b>
            </p>
          </Col>
        </Row>
        <hr />
      </div>
    );
  };

  const renderAddModuleBtn = () => {
    if (user && user.user_type === "faculty") {
      return null;
    } else {
      return (
        <Button
          className="fw-bold ms-5 button border-0"
          onClick={handleAddModuleClick}
        >
          <FontAwesomeIcon
            icon={faAdd}
            title="Add Module"
          />{" "}
          Add Module
        </Button>
      );
    }
  };

  const handleCourseEditClick = () => {
    navigate(`/addcourse?id=${CourseId}`);
  };

  const CourseCard = () => {
    return (
      <>
        <div className="course-head">
          <Row className="p-2">
            <Col
            xl={2}
              lg={2}
              md={12}
              sm={12}
              className="  d-flex align-item-center justify-content-center"
            >
              <img
                className=" thumbnail w-100 border-0 "
                src={data.thumbnail}
                alt=""
              />
            </Col>

            <Col xl={10} lg={10} md={12} sm={12} className="course-head_">
              
             <div className="mt-4 mb-1 d-flex flex-column align-items-center justify-content-center">
                <h1 className="m-0 fw-bold  pe-3">{data.full_name}</h1>
                <h6 className="mt-3   pe-3 fw-bold text-light">
                  {data.description}
                </h6>
             </div>
            </Col>
          </Row>
        </div>
        <div className="text-end mt-3 me-5">
          <Button className="button border-0"  onClick={() => handleCourseEditClick()}>
            <FontAwesomeIcon
              className=" editIcon"
              icon={faEdit}
              title="Edit Course"
            />{"  "}
            Edit 
          </Button>
        </div>

        <Row className="my-3 ms-5 me-5 pb-2  mt-3 text-center">
          <Col md={4} sm={12}>
            <div className="mt-3 shadow boarder-left course-details  p-2">
              <p className="m-0  ">
                <b> Duration :</b>{" "}
                <span className="fw-bold  clr">{data.duration} Months</span>{" "}
              </p>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div className="mt-3 shadow boarder-left course-details p-2 ">
              <p className="m-0 fw-bold ">
                One Time Fee :{" "}
                <span className="fw-bold clr">{data.one_time_fees}</span>
              </p>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div className="mt-3 shadow boarder-left course-details p-2">
              <p className="m-0 fw-bold ">
                Emi Fee : <span className="fw-bold clr">{data.emi_fees}</span>
              </p>
            </div>
          </Col>
        </Row>

        <Row className="my-3 text-center  ms-5 me-5 pb-2 ">
          <Col md={6}>
            <div className="mt-3 shadow boarder-left p-2">
              <p className="m-0 fw-bold ">
                Cochin Next Batch Start Date :{" "}
                <span className="fw-bold clr">{data.cochin_date}</span>
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="mt-3 shadow boarder-left p-2">
              <p className="m-0 fw-bold ">
                Calicut Next Batch Start Date :{" "}
                <span className="fw-bold clr">{data.calicut_date}</span>
              </p>
            </div>
          </Col>
        </Row>

        <Row className="my-3 text-center ms-5 me-5 pb-2 ">
          <Col md={4}>
            <div className="mt-3 shadow boarder-left course-details p-2">
              <p className="m-0 fw-bold ">
                Active Status :
                <span className="fw-bold clr">
                  {data.is_active ? "Active" : "InActive"}
                </span>
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className="mt-3 shadow boarder-left course-details p-2">
              <p className="m-0 fw-bold ">
                Online Status :{" "}
                <span className="fw-bold clr">{data.online_active}</span>
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className="mt-3 shadow boarder-left course-details p-2">
              <p className="m-0 fw-bold  ">
                Offline Status :{" "}
                <span className="fw-bold clr">{data.offline_active}</span>
              </p>
            </div>
          </Col>
        </Row>

        <div className="d-flex ms-5 me-5 row   justify-content-between text-center">
          <div className="  custom-border justify-content-center align-item-center  m-1 col-lg-6 md-col-6 sm-col-12">
         <Row>
           <Col>
                <p className=" mt-5 mx-2">
                  <span className="m-0  ">Created Date : </span> 
                  <b> {dateConveter(data.created_date)}</b>
                </p>
           </Col>
            <Col>
                <p className=" mt-5 mx-2">
                  <span className="m-0  ">Created By: </span>
                  <b> {data.created_by}</b>
                </p>
            </Col>
         </Row>
          </div>
          <div className=" justify-content-center align-item-center  custom-border m-1 col-lg-6 md-col-6 sm-col-12">
          <Row>
             <Col>
                <p className=" mt-5 mx-2">
                  <span className="m-0  ">Update Date : </span>
                  <b>{dateConveter(data.created_date)}</b>
                </p>
             </Col>
             <Col>
                <p className=" mt-5 mx-2">
                  <span className="m-0  ">Update By : </span>
                  <b>{data.updated_by}</b>
                </p>
             </Col>
          </Row>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="p-0">
      <div className="m-0 main-grid">
        <div className="p-0 m-0 d-none  d-md-block">
          {fetchNavObj()}{/*For larger screen (>=md) */}
        </div>

        <div className="m-0 p-0">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none" >
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>
          <div className="mt-3">
          {fetchBreadcrumbs()}
        </div> 
          <div className="content-margin course-body  p-0 mb-3">
          
            <div className=" pb-2  ">
              {loading ? <CustomSpinner /> : <CourseCard />}
            </div>
            <div className="mt-5 border m-3">
              <div className="me-5 mt-4 text-end">
                <>{renderAddModuleBtn()}</>
              </div>

              <h2 className="fw-bold mt-4 text-center">
                <ins className="clr">Modules</ins>
              </h2>
              <div className="p-3 module-body ">
                {loading ? (
                  <CustomSpinner />
                ) : data.modules ? (
                  data.modules.map((module) => renderModuleCard(module))
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeleteConfirmation && (
        <div className="custom-confirmation-modal">
          {deleteConfirm(moduleToDelete)}
        </div>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editedModuleId ? "Edit Module" : "Add Module"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formModuleNo">
              <Form.Label>Module No:</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Module No"
                name="mod_no"
                value={editedModuleId ? editedModule.mod_no : newModule.mod_no}
                onChange={
                  editedModuleId
                    ? handleInputChange
                    : (e) =>
                      setNewModule({ ...newModule, mod_no: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formModuleHeading">
              <Form.Label>Module Heading:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Module Heading"
                name="mod_heading"
                value={
                  editedModuleId
                    ? editedModule.mod_heading
                    : newModule.mod_heading
                }
                onChange={
                  editedModuleId
                    ? handleInputChange
                    : (e) =>
                      setNewModule({
                        ...newModule,
                        mod_heading: e.target.value,
                      })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formModuleDescription">
              <Form.Label>Module Description:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Module Description"
                name="mod_description"
                value={
                  editedModuleId
                    ? editedModule.mod_description
                    : newModule.mod_description
                }
                onChange={
                  editedModuleId
                    ? handleInputChange
                    : (e) =>
                      setNewModule({
                        ...newModule,
                        mod_description: e.target.value,
                      })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary button-clr" onClick={handleClose}>
            Close
          </Button>
          <Button
            className="button"
            onClick={editedModuleId ? handleSaveChanges : handleAddModule}
          >
            {editedModuleId ? "Save Changes" : "Add Module"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CourseOverview;
