import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  ToggleButton,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import logoImage from "../../images/logo1.png";
import NavBar from "../../Common/Navbar/NavBar";
import Sidebar from "../../Common/Sidebar/SideBar";
import "./CoursesOnBoarding.css";
import sampleimg from "../../images/sampleimg.png";
import { endpoints } from "../../defaults";
import useApi from "../../hooks/useApi";
import imgURL from "../../images/imgLink";
import CustomSpinner from "../../Common/Spinner/spinner";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../../contexts/authContext";

const CoursesOnBoarding = () => {
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const navigate = useNavigate();
  const [activeStatus, setActiveStatus] = useState(true);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [offlineStatus, setOfflineStatus] = useState(false);
  const [changeImage, setchangeImage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [image, setImage] = useState(null);
  const { request: createCourses } = useApi("mPost");
  const { request: updateCourses } = useApi("mPut");
  const { request: getCourse } = useApi("get");
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const Courseid = queryParams.get("id");

  const [isEdit, setisEdit] = useState(false);

  useEffect(() => {
    courseClear();
    if (Courseid) {
      setisEdit(true);
      fetchCourse();
    }
  }, [Courseid]);

  const fetchCourse = async () => {
    setLoading(true);
    const url = `${endpoints.VIEW_COURSE_DETAILS}${Courseid}`;
    const apiResponse = await getCourse(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);

      const {
        full_name,
        title,
        description,
        duration,
        one_time_fees,
        emi_fees,
        thumbnail,
        cochin_date,
        calicut_date,
        offline_active,
        online_active,
        is_active,
      } = apiData.data;
      setFormData({
        full_name,
        title,
        description,
        duration,
        one_time_fees,
        emi_fees,
        thumbnail,
        cochin_date,
        calicut_date,
        offline_active: "True",
        online_active: "True",
        is_active,
      });
      setOnlineStatus("False");
      setOfflineStatus("False");
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }
    setDataLoaded(true);
    setLoading(false);
  };

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    duration: "",
    one_time_fees: "",
    emi_fees: "",
    thumbnail: image,
    full_name: "",
    cochin_date: "",
    calicut_date: "",
    offline_active: "True",
    online_active: "True",
    is_active: true,
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setchangeImage(false);
    setFormData({
      ...formData,
      thumbnail: file,
    });
  };

  const CheckOnlineStatus = () => {
    if (onlineStatus) {
      setFormData({
        ...formData,
        online_active: "True",
      });
    } else {
      setFormData({
        ...formData,
        online_active: "False",
      });
    }
  };
  const CheckofflineStatus = () => {
    if (offlineStatus) {
      setFormData({
        ...formData,
        offline_active: "True",
      });
    } else {
      setFormData({
        ...formData,
        offline_active: "False",
      });
    }
  };
  const courseClear = () => {
    setFormData({
      title: "",
      description: "",
      duration: "",
      one_time_fees: "",
      emi_fees: "",
      thumbnail: null,
      full_name: "",
      cochin_date: "",
      calicut_date: "",
      offline_active: "True",
      online_active: "True",
      is_active: true,
    });

    setActiveStatus(true);
    setOnlineStatus(true);
    setOfflineStatus(true);

    document.getElementById("courseName").value = "";
    document.getElementById("courseTitle").value = "";
    document.getElementById("courseStatus").checked = false;
    document.getElementById("courseDescription").value = "";
    document.getElementById("cochinStartDate").value = "";
    document.getElementById("calicutStartDate").value = "";
    document.getElementById("duration").value = "";
    document.getElementById("oneTimeFee").value = "";
    document.getElementById("partialFee").value = "";
  };
  const handleSave = async () => {
    const header = {
      "Content-Type": "multipart/form-data",
    };

    if (
      !formData.full_name ||
      !formData.title ||
      !formData.description ||
      !formData.duration ||
      !formData.one_time_fees ||
      !formData.emi_fees ||
      !formData.cochin_date ||
      !formData.calicut_date
    ) {
      toast.error("Please fill out all the fields", {});
      return;
    }

    try {
      let apiResponse;

      const payload = {
        ...formData,
        duration: parseInt(formData.duration), // Convert duration to integer
      };

      if (isEdit) {
        const staticPayload = {
          title: payload.title,
          description: payload.description,
          duration: payload.duration,
          one_time_fees: payload.one_time_fees,
          emi_fees: payload.emi_fees,
          full_name: payload.full_name,
          cochin_date: payload.cochin_date,
          calicut_date: payload.calicut_date,
          offline_active: "True",
          online_active: "True",
          is_active: true,
        };

        if (changeImage) {
          const id = Courseid;
          const uri = `${endpoints.UPDATE_COURSE}/${id}/`;
          apiResponse = await updateCourses(uri, staticPayload, header);
        } else {
          const id = Courseid;
          const uri = `${endpoints.UPDATE_COURSE}/${id}/`;
          apiResponse = await updateCourses(uri, payload, header);
        }
      } else {
        apiResponse = await createCourses(
          endpoints.ADD_COURSE,
          payload,
          header
        );
      }

      const { response, error } = apiResponse;
      if (!error && response.data) {
        if (isEdit) {
          toast.success("Course Updated created!");
        } else {
          toast.success("Course successfully created!");
        }
        // courseClear();
        navigate("/");
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened, Please contact admin");
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    }
  };

  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "" },
      };
      return <Sidebar navObj={navObj} />;
    } else {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "", icon: "chart-line" },
        users: { title: "Users", link: "users", icon: "users" },
        addCourse: { title: "Add Course", link: "addcourse", icon: "plus" },
        addCarousel: { title: "Add Carousel", link: "addCarousel", icon: "image" },
        lead: { title: "Leads", link: "leads", icon: "user-tie" },
      };
      return <Sidebar navObj={navObj} />;
    }
  };

  const heading = "Luminar Technolab";
  const img = `${imgURL}${formData.thumbnail}`;
  const cancelClick = () => {
    navigate("/");
  };
  return (
    <Container fluid className="p-0">
      <div className="m-0 main-grid">
        <div className="p-0 m-0 d-none  d-md-block">
          {fetchNavObj()}
        </div>  {/*For larger screen (>=md) */}

        <div className="m-0 p-0">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none" >
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>
          <Container className="course-list ">
            {loading && (
              <div className="loader-container">
                <CustomSpinner className="inner-round" />
              </div>
            )}
            <Row>
              <Col>
                <div className="col-12 form-card-parent">
                  <div className="row justify-content-center">
                    <Form className="col-11 mt-4 " encType="multipart/form-data">
                     <div className="text-center">
                        <h1 className="fw-bold mb-5 head">
                          {isEdit ? "Update Course" : "Create Course"}
                        </h1>
                     </div>
                      <div className="row">
                        <div className="d-flex align-item-cenetr justify-content-center col-lg-3 col-md-12">
                          {/* Image Upload Section - No validation for file type */}
                          <div className="img-container border border-2 p-1 ">
                            <Image
                              src={
                                isEdit
                                  ? img
                                  : image
                                    ? URL.createObjectURL(image)
                                    : sampleimg
                              }
                              className="w-100 course_img "
                              alt=""
                            />
                            <div className="image-edit-btn mt-2">
                              <Form.Control
                                type="file"
                                onChange={handleImageChange}
                                required
                                placeholder="Upload Image"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-12 mt-3">
                          {/* Course Full Name */}
                          <Form.Group controlId="courseName">
                            <Form.Label className="fw-bold">
                              Course Full Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={formData.full_name}
                              placeholder="Enter Course full name"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  full_name: e.target.value,
                                })
                              }
                              required
                            />
                          </Form.Group>

                          {/* Course Title and Status */}
                          <div className="row mt-3">
                            <div className="col-lg-9 col-md-12 mt-2">
                              <Form.Group controlId="courseTitle">
                                <Form.Label className="fw-bold">
                                  Course Title
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={formData.title}
                                  placeholder="Enter Course Title"
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      title: e.target.value,
                                    })
                                  }
                                  required
                                />
                              </Form.Group>
                            </div>
                            <div className="col-lg-3 col-md-12 mt-3 d-flex align-item-center justify-content-center">
                              <Form.Group
                                className=""
                                controlId="courseStatus"
                              >
                                <Form.Label className="fw-bold">
                                  Course Status
                                </Form.Label>
                                <Form.Check
                                  className="fs-3"
                                  type="switch"
                                  id="courseStatus"
                                  onChange={() => {
                                    setActiveStatus(!activeStatus);
                                    setFormData({
                                      ...formData,
                                      is_active: activeStatus,
                                    });
                                  }}
                                  checked={formData.is_active}
                                  required
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Course Description */}
                      <div className="row mt-3">
                        <div className="mb-3">
                          <Form.Group controlId="courseDescription">
                            <Form.Label className="fw-bold">
                              Course Description
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData.description}
                              rows={3}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  description: e.target.value,
                                })
                              }
                              required
                            />
                          </Form.Group>
                        </div>
                      </div>

                      {/* Start Dates for Cochin and Calicut */}
                      <div className="row">
                        <div className="col-6">
                          <Form.Group controlId="cochinStartDate">
                            <Form.Label className="fw-bold">
                              Cochin Start Date
                            </Form.Label>
                            <Form.Control
                              type="date"
                              value={formData.cochin_date}
                              placeholder="Enter starting date @cochin"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  cochin_date: e.target.value,
                                })
                              }
                              onKeyDown={(e) => e.preventDefault()}
                              required
                            />
                          </Form.Group>
                        </div>
                        <div className="col-6">
                          <Form.Group controlId="calicutStartDate">
                            <Form.Label className="fw-bold">
                              Calicut Start Date
                            </Form.Label>
                            <Form.Control
                              type="date"
                              value={formData.calicut_date}
                              placeholder="Enter starting date @calicut"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  calicut_date: e.target.value,
                                })
                              }
                              onKeyDown={(e) => e.preventDefault()}
                              required
                            />
                          </Form.Group>
                        </div>
                      </div>

                      {/* Duration and Fees */}
                      <div className="row mt-4">
                        <div className="col-lg-4 col-md-12 mt-3">
                          <Form.Group controlId="duration">
                            <Form.Label className="fw-bold">
                              Duration (in months)
                            </Form.Label>
                            <Form.Control
                              type="number"
                              value={formData.duration}
                              placeholder="Duration in months"
                              onChange={(e) => {
                                const integerValue = parseInt(e.target.value);
                                setFormData({
                                  ...formData,
                                  duration: isNaN(integerValue)
                                    ? ""
                                    : integerValue,
                                });
                              }}
                              required
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-3">
                          <Form.Group controlId="oneTimeFee">
                            <Form.Label className="fw-bold">
                              Fee (One-time payment in Rs)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={formData.one_time_fees}
                              placeholder="Fee in Rs"
                              onChange={(e) => {
                                const inputText = e.target.value;
                                const numericOnly = inputText.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                setFormData({
                                  ...formData,
                                  one_time_fees: numericOnly,
                                });
                              }}
                              required
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-3">
                          <Form.Group controlId="partialFee">
                            <Form.Label className="fw-bold">
                              Fee (Partial Payment in Rs)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={formData.emi_fees}
                              placeholder="Fee in Rs"
                              onChange={(e) => {
                                const inputText = e.target.value;
                                const numericOnly = inputText.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                setFormData({
                                  ...formData,
                                  emi_fees: numericOnly,
                                });
                              }}
                              required
                            />
                          </Form.Group>
                        </div>
                      </div>



                      {/* Buttons */}
                      <div className="row mt-3">
                        <div className="col-12 d-flex justify-content-end">
                          <div>
                            <Button
                              variant="outline-secondary button-clr"
                              className="me-3"
                              onClick={cancelClick}
                            >
                              Cancel
                            </Button>
                            {isEdit ? (
                              <Button variant="primary" onClick={handleSave}>
                                Update
                              </Button>
                            ) : (
                              <Button className="button" onClick={handleSave}>
                                Create
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Container>
  );
};

export default CoursesOnBoarding;
