import { React, useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import NavBar from "../../Common/Navbar/NavBar";
import Sidebar from "../../Common/Sidebar/SideBar";
import logoImage from "../../images/logo1.png";
import { useLocation } from "react-router-dom";
import proImage from "../../images/profile.png";
import { toast } from "react-toastify";
import { endpoints } from "../../defaults";
import AuthContext from "../../contexts/authContext";
import useApi from "../../hooks/useApi";
import CustomSpinner from "../../Common/Spinner/spinner";
import { faEllipsisVertical, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
const UserProfile = () => {
  const [loading, setLoading] = useState(false);
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const UserId = queryParams.get("id");
  const [data, setData] = useState([]);
  const { request: getUserDetail } = useApi("get");
  const { request: updatePswd } = useApi("post");
  const { request: verifyOtpP } = useApi("post");
  const { request: verifyPhone } = useApi("post");
  const { request: updateVersion } = useApi("post");
  const { request: updateProfile } = useApi("patch");
  const [dataLoaded, setDataLoaded] = useState(false);

  const [changedFullName, setChangedFullName] = useState(false);
  const [changedPhone, setChangedPhone] = useState(false);
  const [changedDOB, setChangedDOB] = useState(false);
  const [changedEmail, setChangedEmail] = useState(false);
  const [changedGender, setChangedGender] = useState(false);

  // const handleShowPasswordModal = () => setShowPasswordModal(true);
  const handleClosePasswordModal = () => {
    // setNewPassword("");
    // document.getElementById("newPassword").value = "";
    setShowPasswordModal(false);
  };
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);

  const [otpString, setotpString] = useState(["", "", "", ""]);
  const [phone, setPhone] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const [showMobileVersionModal, setShowMobileVersionModal] = useState(false);

  const [buildNumber, setBuildNumber] = useState("");
  const [newVersion, setNewVersion] = useState("");

  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [editedProfile, setEditedProfile] = useState({
    full_name: "",
    phone: "",
    email: "",
    dob: "",
    gender: "",
  });
  const handleFullNameChange = (e) => {
    setChangedFullName(true);
    setEditedProfile({
      ...editedProfile,
      full_name: e.target.value,
    });
  };
  const handlePhoneChange = (e) => {
    setChangedPhone(true);
    setEditedProfile({
      ...editedProfile,
      phone: e.target.value,
    });
  };

  const handledobChange = (e) => {
    setChangedDOB(true);
    setEditedProfile({
      ...editedProfile,
      dob: e.target.value,
    });
  };

  const handleEmailChange = (e) => {
    setChangedEmail(true);
    setEditedProfile({
      ...editedProfile,
      email: e.target.value,
    });
  };

  const handleGenderChange = (e) => {
    setChangedGender(true);
    setEditedProfile({
      ...editedProfile,
      gender: e.target.value,
    });
  };

  const handleShowEditProfileModal = () => {
    setEditedProfile({
      full_name: data.user?.full_name,
      phone: data.user?.phone,
      email: data.user?.email,
      dob: data.user?.dob,
      gender: data.user?.gender,
    });
    setShowEditProfileModal(true);
  };

  const handleCloseEditProfileModal = () => {
    setShowEditProfileModal(false);

    fetchUserDetails(UserId);
  }

  const handleSaveEditProfile = async () => {
    try {
      setLoading(true);
      const url = `${endpoints.UPDATE_USER}${UserId}/`;
      const Editpayload = {
        user: {
          ...(changedFullName ? { full_name: editedProfile.full_name } : {}),
          ...(changedDOB ? { dob: editedProfile.dob } : {}),
          ...(changedEmail ? { email: editedProfile.email } : {}),
          ...(changedGender ? { gender: editedProfile.gender } : {}),
          ...(changedPhone ? { phone: editedProfile.phone } : {}),
        },
      };
      const apiResponse = await updateProfile(url, Editpayload);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("profile updated successfully");
        setLoading(false);
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened. Please contact admin.");
        }
      }

    } catch (error) {
      console.error("Error occurred during profile updation", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
    handleCloseEditProfileModal();
  };

  const handleShowMobileVersionModal = () => setShowMobileVersionModal(true);
  const handleCloseMobileVersionModal = () => setShowMobileVersionModal(false);

  const handleUpdateMobileVersion = async () => {
    try {
      setLoading(true);
      const url = `${endpoints.UPDATE_MOB_VERSION}`;
      const payload = {
        new_version: newVersion,
        build_num: buildNumber,
      };
      const apiResponse = await updateVersion(url, payload);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Version updated successfully");
        setLoading(false);
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened. Please contact admin.");
        }
      }
    } catch (error) {
      console.error("Error occurred during Version updation", error);
      toast.error("An error occurred. Please try again or contact admin.");
    }
    handleCloseMobileVersionModal();
  };

  const heading = "Luminar Technolab";
  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "" },
      };
      return <Sidebar navObj={navObj} />;
    } else {
      const navObj = {
        dashBoard: { title: "Dashboard", link: "", icon: "chart-line" },
        users: { title: "Users", link: "users", icon: "users" },
        addCourse: { title: "Add Course", link: "addcourse", icon: "plus" },
        addCarousel: { title: "Add Carousel", link: "addCarousel", icon: "image" },
        lead: { title: "Leads", link: "leads", icon: "user-tie" },
      };
      return <Sidebar navObj={navObj} />;
    }
  };

  useEffect(() => {
    fetchUserDetails(UserId);
    fetchMobileVersion();
  }, [UserId]);

  const formatDateTime = (dateTimeString) => {
    const formattedDate = new Date(dateTimeString).toLocaleString("en-IN", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      // second: 'numeric',
      // timeZoneName: 'short',
    });

    return formattedDate;
  };

  const fetchMobileVersion = async () => {
    setLoading(true);
    const url = `${endpoints.GET_MOB_VERSION}`;
    const apiResponse = await getUserDetail(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setBuildNumber(apiData.data.build_num);
      setNewVersion(apiData.data.new_version);
      console.log(apiData);
      setDataLoaded(true);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  const fetchUserDetails = async (UserId) => {
    setLoading(true);
    const url = `${endpoints.FECH_USER_DETAILS}${UserId}/`;
    const apiResponse = await getUserDetail(url);
    const { response, error } = apiResponse;
    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData);
      setDataLoaded(true);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please Contact Admin !!"
      );
    }
    setLoading(false);
  };

  // const handleSave = async () => {
  //   try {
  //     let apiResponse;

  //     const payload = {
  //       password: newPassword,
  //     };
  //     const url = `${endpoints.CHANGE_PSWD}${UserId}/`;
  //     apiResponse = await updatePswd(url, payload);
  //     const { response, error } = apiResponse;
  //     if (!error && response.data) {
  //       console.log(response.data);
  //       const { data: apiData } = response;
  //       console.log("resp", apiData);

  //       toast.success("Password successfully updated!");
  //       handleClosePasswordModal();
  //     } else {
  //       if (error.response.data.detail) {
  //         toast.error(error.response.data.detail);
  //       } else {
  //         toast.error("Something happened,Please contact admin");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error occurred during submission:", error);
  //   }
  // };
  const handleSave = async () => {
    if (!isOtpVerified) {
      const verificationResponse = await verifyOtp();

      if (verificationResponse.response.data.status === 1) {
        toast.success("OTP verified successfully!");
        setIsOtpVerified(true);
      } else {
        toast.error("Failed to verify OTP. Please try again.");
      }
    } else
      try {
        if (isOtpVerified) {
          // OTP verified, proceed with password update
          const passwordUpdateResponse = await updatePassword();

          if (passwordUpdateResponse.response.data.status === 1) {
            // Password updated successfully
            toast.success("Password successfully updated!");
            handleClosePasswordModal();
          } else {
            toast.error("Failed to update password. Please try again.");
          }
        } else {
          toast.error("Please verify OTP first.");
        }
      } catch (error) {
        console.error("Error occurred during submission:", error);
      }
  };
  const sendOtp = async () => {
    // API call to send OTP to the provided phone number
    const payload = {
      phone: data.user?.phone,
    };
    const url = `${endpoints.RESEND_OTP}`;
    const otpResponse = await verifyOtpP(url, payload);
    return otpResponse;
  };

  const verifyOtp = async () => {
    // API call to verify OTP with the provided phone number and OTP
    const payload = {
      phone: data.user?.phone,
      otp: otpString,
    };
    const url = `${endpoints.VERIFY_OTP}`;
    const verifyOtpResponse = await verifyOtpP(url, payload);

    if (verifyOtpResponse.response.data.status === 1) {
      setIsOtpVerified(true);
    } else {
      setIsOtpVerified(false);
      toast.error("Failed to verify OTP. Please try again.");
    }

    return verifyOtpResponse;
  };

  const updatePassword = async () => {
    // API call to update the password
    const payload = { password: newPassword }; // Modify this according to your API
    const url = `${endpoints.CHANGE_PSWD}${UserId}/`;
    const passwordUpdateResponse = await updatePswd(url, payload);
    return passwordUpdateResponse;
  };

  const handleShowPasswordModal = async () => {
    // Ask the user whether they want to change their password
    const userWantsToChangePassword = window.confirm("Do you want to change your password?");

    // If the user confirms, proceed to verify OTP
    if (userWantsToChangePassword) {
      await handleVerifyOtp(); // Assuming handleVerifyOtp is defined elsewhere
      setShowPasswordModal(true); // Assuming setShowPasswordModal is defined elsewhere
    }

    // Return null or an empty fragment if you don't want to render anything here
    return null;
  };


  const handleVerifyOtp = async () => {
    try {
      // Send OTP using the user's phone number
      const otpResponse = await sendOtp(phone);
      console.log("otpResponse.status:", otpResponse);
      if (otpResponse.response.data.status === 1) {
        toast.success("OTP sent successfully!");
        // Verify OTP after sending
      } else {
        toast.error("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error occurred during OTP verification:", error);
    }
  };

  // const handleOtpChange = (index, value) => {
  //   const newOtp = [...otp];
  //   newOtp[index] = value;

  //   // Handle moving focus to the next input box
  //   if (index < otp.length - 1 && value !== "") {
  //     document.getElementById(`otp-input-${index + 1}`).focus();
  //   }

  //   setOtp(newOtp);
  // };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    // Handle moving focus to the next input box
    if (index < otp.length - 1 && value !== "") {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }

    setOtp(newOtp);

    // Convert the OTP array to a string
    const otpPString = newOtp.join("");
    setotpString(otpPString);
    console.log(otpString); // You can use the otpString as needed
  };

  function formattedDate(inputDate) {
    if (!inputDate) return ""; // Handle case where inputDate is undefined or null

    // Assuming inputDate is in the format "12-3-2000"
    const [day, month, year] = inputDate
      .split("-")
      .map((part) => (part || "").trim());

    if (!day || !month || !year) return ""; // Handle invalid input

    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }

  return (
    <Container fluid className="p-0">

      <div className="m-0 main-grid">
        <div className="p-0 m-0 d-none  d-md-block">
          {fetchNavObj()}{/*For larger screen (>=md) */}
        </div>

        <div className=" m-0 p-0">
          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none" >
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>
          {loading ? (
            <CustomSpinner />
          ) : (
            <div className="px-md-5 px-2">
              <Container className="content-margin user-dashboard-profile p-5 ps-2 pb-4" >
                <div className="d-flex justify-content-evenly">
                  <h1 className="fw-bold text-center">
                    Hi,{" "}
                    <span className="fw-bold">{data.user?.full_name}</span>
                  </h1>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" className="button" id="dropdown-basic">
                      <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleShowEditProfileModal}>Edit Profile</Dropdown.Item>
                      <Dropdown.Item onClick={handleShowPasswordModal}>Change Password</Dropdown.Item>
                      {data.user?.user_type === "superadmin" ? (
                        <>
                          <Dropdown.Item
                            onClick={handleShowMobileVersionModal}
                          >
                            Update Mobile Version
                          </Dropdown.Item>
                          <Dropdown.Item className="logs text-light">Delete Logs</Dropdown.Item>

                        </>
                      ) : (
                        ""
                      )}
                    </Dropdown.Menu>
                  </Dropdown>

                </div>
                <Row key={data.id}>

                  <Col lg={6} className="d-flex justify-content-center align-items-center">
                    <img src={proImage} className="profile-pic img-fluid"></img>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-center">
                    <div style={{ width: 'fitContent' }}>
                      <div className="my-2 mt-4">

                        <h5 className="my-2">
                          Phone :<span className="fw-bold">{data.user?.phone}</span>
                        </h5>
                        <h5 className="my-2">
                          Email :<span className="fw-bold">{data.user?.email}</span>
                        </h5>
                      </div>

                      <div>
                        <div>
                          <h5>
                            Date of Birth :{" "}
                            <span className="fw-bold">{formattedDate(data.user?.dob)}</span>
                          </h5>
                        </div>
                        <h5>
                          Gender :{" "}
                          <span className="fw-bold">{data.user?.gender}</span>
                        </h5>
                      </div>
                      <div>
                        <h5>
                          Role :{" "}
                          <span className="fw-bold">
                            {data.user?.user_type}
                          </span>
                        </h5>
                      </div>
                      <div>
                        <h5>
                          Status:{" "}
                          <span className="fw-bold">
                            {data.user?.is_active ? "Active" : "Inactive"}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="text-center mt-3">
                  Joined At: <span>{formatDateTime(data.created_date)}</span>
                </div>
              </Container>
            </div>
          )}
        </div>
        {/* <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="newPassword" className="form-label">
              New Password
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"} Password
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosePasswordModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal> */}
        <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
          {/* ... (other modal content) */}
          <Modal.Body>
            {isOtpVerified ? (
              <>
                <label htmlFor="newPassword" className="form-label">
                  New Password
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="newPassword"
                    // Add state and onChange handler for the new password input
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? "Hide" : "Show"} Password
                  </button>
                </div>
                {/* Add additional input fields or form elements as needed */}
              </>
            ) : (
              <>
                <Form>
                  <Form.Label htmlFor="otp" className="form-label">
                    OTP
                  </Form.Label>
                  <div className="d-flex justify-content-between">
                    {otp.map((digit, index) => (
                      <Form.Control
                        key={index}
                        type="text"
                        className="form-control"
                        id={`otp-input-${index}`}
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                      />
                    ))}
                  </div>
                </Form>
              </>
            )}
          </Modal.Body>
          {/* ... (other modal content) */}
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={handleClosePasswordModal} className="button">
              Close
            </Button>
            <Button variant="primary" onClick={handleSave} className="button">
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showMobileVersionModal}
          onHide={handleCloseMobileVersionModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Mobile Version</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBuildNumber">
                <Form.Label>Build Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter build number"
                  value={buildNumber}
                  onChange={(e) => setBuildNumber(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formNewVersion">
                <Form.Label>New Version</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter new version"
                  value={newVersion}
                  onChange={(e) => setNewVersion(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={handleCloseMobileVersionModal} className="button-clr">
              Close
            </Button>
            <Button variant="primary" onClick={handleUpdateMobileVersion} className="button">
              Update Version
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showEditProfileModal} onHide={handleCloseEditProfileModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formFullName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter full name"
                  value={editedProfile.full_name}
                  onChange={handleFullNameChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  value={editedProfile.phone}
                  onChange={handlePhoneChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={editedProfile.email}
                  onChange={handleEmailChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formDob">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  value={(editedProfile.dob)}
                  onChange={handledobChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGender">
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  value={editedProfile.gender}
                  onChange={handleGenderChange}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  {/* Add more gender options if needed */}
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditProfileModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveEditProfile}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
};

export default UserProfile;
