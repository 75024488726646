import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  InputGroup,
  FormControl,
  Card,
  Container,
  Dropdown,
  Row,
  Modal,
  Col,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../Common/Sidebar/SideBar";
import { toast } from "react-toastify";
import NavBar from "../../Common/Navbar/NavBar";
import useApi from "../../hooks/useApi";
import { endpoints } from "../../defaults";
import logoImage from "../../images/logo1.png";
import CustomSpinner from "../../Common/Spinner/spinner";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import "./BatchVideos.css";
import AuthContext from "../../contexts/authContext";
import Trail from "../../Common/Breadcrumb/Trail";

const BatchVideos = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const BatchId = queryParams.get("id");
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  const { request: getVideos } = useApi("get");
  const { request: deleteVideo } = useApi("delete");
  const { request: addVideo } = useApi("post");
  const { request: editVideo } = useApi("put");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [VideoToDelete, setVideoToDelete] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [show, setShow] = useState(false);
  const [Bid, setBid] = useState("");
  const [editedVideoId, setEditedVideoId] = useState(null);
  const [btnDisabled,setBtnDisabled]=useState({add:false,delete:false})
  const { request: getStudentsList } = useApi("get");
  const [Bdata, setBData] = useState([]);


  const [editedVideo, setEditedVideo] = useState({
    title: "",
    link: "",
    description: "",
    date: "",
  });
  const [newVideo, setNewVideo] = useState({
    title: "",
    link: "",
    description: "",
    date: "",
  });

  const heading = "Luminar Technolab";
  const fetchNavObj = () => {
    if (user && user.user_type === "faculty") {
      const navObj = {
        student: { title: "Students", link: "course/batches/students/", icon: "users" },
        Overview: { title: "Overview", link: "course/batches/overview/", icon: "eye" },
        video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
        liveclasses: { title: "Live Classes", link: "course/batches/liveclasses/", icon: "chalkboard-teacher" },
        markAttendance: { title: "Mark Attendance", link: "course/batches/studentAttendance/", icon: "user-check" },
        viewAttendance: { title: "View Attendance", link: "course/batches/studentViewAttendance/", icon: "list-alt" },
        batchShift: {title: "Batch Changed Students", link:"batchShiftedList", icon: "exchange-alt"},
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={Bid} />;
    } else {
      const navObj = {
        student: {
          title: "Students",
          link: "course/batches/students/",
          icon: "users",
        },
        Overview: {
          title: "Overview",
          link: "course/batches/overview/",
          icon: "eye",
        },
        video: { title: "Videos", link: "course/batches/videos/", icon: "video" },
        liveclasses: {
          title: "Live Classes",
          link: "course/batches/liveclasses/",
          icon: "chalkboard-teacher",
        },
        markAttendance: {
          title: "Mark Attendance",
          link: "course/batches/studentAttendance/",
          icon: "user-check",
        },
        viewAttendance: {
          title: "View Attendance",
          link: "course/batches/studentViewAttendance/",
          icon: "list-alt",
        },
        bulkAdd:{
          title: "Bulk Add Students",
          link:"bulkAddStudentsToBatch",
          icon:"fa fa-users",
        },
        batchShift: {
          title: "Batch Changed Students",
          link: "batchShiftedList",
          icon: "exchange-alt",
        },
        recordedAccess: {
          title: "Recorded Student List",
          link: "recordedStudent",
          icon: "play-circle",
        },
      };
      return <Sidebar navObj={navObj} itemObj={Bid} />;
    }
  };

  
  const fetchBreadcrumbs=()=>{
    const itemObj={
      dashBoard: { title: "Courses", link: "" },
      batches: { title: "Batches", link: `course/batches/?id=${Bdata.course}` }, 
      video: {title: "Videos",link: `course/batches/videos/?id=${Bid}`},
      videoList:{title:"Video List",link:`course/batches/videos/videosList/?id=${BatchId}`}
    }
    return <Trail itemObj={itemObj} />
    }
    console.log(Bid);

    const fetchBatches = async (Biid) => {
      setLoading(true);
      const URI = `${endpoints.FETCH_BATCH}${Biid}/`;
      try {
        const apiResponse = await getStudentsList(URI);
        const { response, error } = apiResponse;
        if (!error && response.data) {
          const { data: apiData } = response;
          setBData(apiData.data);
        } else {
          const { response: errRes } = error;
          toast.error(
            errRes?.data?.message || "Error Occurred. Please contact Admin !!"
          );
        }
      } catch (error) {
        console.error("Error occurred during fetchBatches:", error);
        toast.error("An error occurred. Please try again or contact admin.");
      }
      setLoading(false);
    };
  

  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const year = dateObject.getFullYear();

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    if (BatchId) {
      fetchVideos();
    }
  }, [BatchId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedVideo((preVideo) => ({
      ...preVideo,
      [name]: value,
    }));
  };

  const handleAddVideo = async () => {
    setBtnDisabled({...btnDisabled,add:true})
    try {
      const url = `${endpoints.ADD_BATCH_VIDEO}${BatchId}/`;
      const apiResponse = await addVideo(url, newVideo);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Video added successfully");
        fetchVideos();
        setShow(false);
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened. Please contact admin.");
        }
      }
    } catch (error) {
      console.error("Error occurred during Demo video addition:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    } finally{
      setBtnDisabled({...btnDisabled,add:false})
    }
  };

  const handleEditClick = (Video) => {
    setEditedVideo({
      title: Video.title,
      link: Video.link,
      description: Video.description,
      date: Video.date,
    });
    setEditedVideoId(Video.id);
    setShow(true);
  };

  const clearModal = () => {
    setEditedVideoId(null);
    setEditedVideo({
      title: "",
      link: "",
      description: "",
      date: "",
    });
    setNewVideo({
      title: "",
      link: "",
      description: "",
      date: "",
    });
    setShow(false);
  };

  const handleSaveChanges = async () => {
    
    if (!editedVideoId) {
      console.error("Module ID is missing.");
      return;
    }
    setBtnDisabled({...btnDisabled,add:true})
    try {
      let apiResponse;
      const url = `${endpoints.UPDATE_BATCH_VIDEO}${editedVideoId}/`;
      apiResponse = await editVideo(url, editedVideo);

      const { response, error } = apiResponse;
      if (!error && response.data) {
        toast.success("video updated successfully");

        fetchVideos();
        clearModal();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }

      setShow(false);
    } catch (error) {
      console.error("Error occurred during Demo Video update:", error);
      toast.error("An error occurred. Please try again or contact admin.");
    } finally{
      setBtnDisabled({...btnDisabled,add:false})
    }
  };

  const fetchVideos = async () => {
    if (!BatchId) {
      return; // return early if BatchId is null
    }

    setLoading(true);
    const url = `${endpoints.FETCH_BATCH_VIDEOS}${BatchId}/`;
    const apiResponse = await getVideos(url);
    const { response, error } = apiResponse;

    if (!error && response.data) {
      const { data: apiData } = response;
      setData(apiData.data);
      setBid(apiData.batch);
      fetchBatches(apiData.batch)
      //  console.log(response);
    } else {
      const { response: errRes } = error;
      toast.error(
        errRes?.data?.message || "Error Occurred. Please contact Admin !!"
      );
    }

    setLoading(false);
  };

  const dateConveter = (date) => {
    const originalDate = new Date(date);

    const formattedDate = originalDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  const handleConfirmation = async (confirmed) => {
    setBtnDisabled({...btnDisabled,delete:true})
    setShowDeleteConfirmation(false);

    if (confirmed && VideoToDelete) {
      await handleDelete(VideoToDelete.id);
    }

    setVideoToDelete(null);
  };

  const handleDelete = async (VideoId) => {
    try {
      const uri = `${endpoints.DELETE_BATCH_VIDEO}${VideoId}/`;
      const apiResponse = await deleteVideo(uri);
      const { response, error } = apiResponse;

      if (!error && response.data) {
        toast.success("Video successfully deleted!");
        await fetchVideos();
      } else {
        if (error.response.data.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Something happened,Please contact admin");
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    } finally{
      setBtnDisabled({...btnDisabled,delete:false})
    }
  };

  const deleteConfirm = (video) => {
    const CustomConfirmationToast = () => (
      <div className="text-center">
        <div className="mb-3">
          Are you sure you want to delete {video.title}
        </div>
        <Button
          variant="outline-dark logout-btn me-2"
          onClick={() => handleConfirmation(true)}
          disabled={btnDisabled.delete}
        >
          Yes
        </Button>
        <Button
          variant="outline-dark logout-btn"
          onClick={() => handleConfirmation(false)}
        >
          No
        </Button>
      </div>
    );

    const existingToast = toast.isActive("custom-confirmation-toast");

    if (!existingToast) {
      toast.warning(<CustomConfirmationToast />, {
        toastId: "custom-confirmation-toast",
        position: "top-center",
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleDeleteclick = (video) => {
    setVideoToDelete(video);
    console.log(video.id, "====");
    setShowDeleteConfirmation(true);
  };

  const VideoCard = (video, index) => {
    return (
      <Col lg={4} md={6} className="mb-3 p-1   " key={video.id}>
        <Card>
      
          
             <div className="">
                <Dropdown className="card-menu mb-2 m- justify-content-end" >
                  <Dropdown.Toggle
                    key={index}
                    variant="link"
                    className="video-toggle card-3dot fs-5  custom-dropdown-toggle"
                  >
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item key={1} onClick={() => handleEditClick(video)}>
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item key={2} onClick={() => handleDeleteclick(video)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
             </div>
            
     
        
            <Card.Body className="video m-2 mt-4 p-0">
              <ReactPlayer
                className="video "
                height={"13rem"}
                width={"100%"}          
                url={video.link}
              />
           
          
          
            <h4 className="mt-3"><b>{video.title}</b></h4>
          
        
          <div className="scrollable-content mt-3">
          <h6 className="">
            <span className="text-secondary">Description : </span>
            {video.description}</h6>
          </div>
      <hr />
          <div className="mt-3">
            <h6 className="">{formatDate(video.date)}</h6>
          </div>
          </Card.Body>
        
    
       {/* <Row>
      <Col className="d-flex" md={12}><div><p>created Date :{dateConveter(video.created_at)}</p></div>
      <div className="ms-3"><p>created :{dateConveter(video.created_by)}</p></div></Col>
    </Row>  */}
        </Card>
      </Col>
    );
  };

  return (
    <Container fluid className="p-0">
      <div className="m-0 main-grid">
      <div className="p-0  d-none  d-md-block">{fetchNavObj()}</div>{" "}
        {/*For larger screen (>=md) */}
        <div className=" m-0 p-0 ">

          <div className="sticky-top">
            <NavBar heading={heading} logoImage={logoImage} />
          </div>
          <div className="d-md-none">
            {fetchNavObj()} {/*For smaller screen (<md) */}
          </div>
          <Container className="content-margin">
          <div className="mt-4">
          {fetchBreadcrumbs()}
        </div> 
            <Row>
              <Col lg={10} md={12}>
               
                <h2 className="fw-bold head">Class Session Videos</h2>
              </Col>
              <Col lg={2} md={12} className="text-end">
                <Button
                  className="fw-bold button ms-5 mt-2"
                  onClick={() => {
                    clearModal();
                    handleShow();
                  }}
                >
                  <FontAwesomeIcon icon={faAdd}></FontAwesomeIcon> Add Videos
                </Button>
              </Col>
            </Row>
            <Row className="video-parent-container  ">
           
                {data.map((video, index) => VideoCard(video, index))}
            
            </Row>
          </Container>
        </div>
      </div>
      {showDeleteConfirmation && (
        <div className="custom-confirmation-modal">
          {deleteConfirm(VideoToDelete)}
        </div>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editedVideoId ? "Edit Video" : "Add Video"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formVideoTitle">
              <Form.Label>Video Title:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Video Title"
                name="title"
                value={editedVideo.title ? editedVideo.title : newVideo.title}
                onChange={
                  editedVideoId
                    ? handleInputChange
                    : (e) =>
                        setNewVideo({
                          ...newVideo,
                          title: e.target.value,
                        })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formVideoLink">
              <Form.Label>Video Link:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Video Link"
                name="link"
                value={editedVideo.link ? editedVideo.link : newVideo.link}
                onChange={
                  editedVideoId
                    ? handleInputChange
                    : (e) =>
                        setNewVideo({
                          ...newVideo,
                          link: e.target.value,
                        })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formVideoDescription">
              <Form.Label>Video Description:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Video Description"
                name="description"
                value={
                  editedVideo.description
                    ? editedVideo.description
                    : newVideo.description
                }
                onChange={
                  editedVideoId
                    ? handleInputChange
                    : (e) =>
                        setNewVideo({
                          ...newVideo,
                          description: e.target.value,
                        })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formVideoDate">
              <Form.Label>Video Date:</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter Video Date"
                name="date"
                value={editedVideo.date ? editedVideo.date : newVideo.date}
                onChange={
                  editedVideoId
                    ? handleInputChange
                    : (e) =>
                        setNewVideo({
                          ...newVideo,
                          date: e.target.value,
                        })
                }
                max={new Date().toISOString().split("T")[0]}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" className="button-clr" onClick={handleClose}>
            Close
          </Button>
          <Button
           className="button"
            onClick={editedVideoId ? handleSaveChanges : handleAddVideo}
            disabled={btnDisabled.add}
          >
            {editedVideoId ? "Save Changes" : "Add Video"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default BatchVideos;
